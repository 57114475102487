<template>
    <div 
        class="keep-flow_block" 
        :order="order" 
        :style="{ 'order':order }"
    >
        <!-- 基本信息 -->
        <div class="keep-flow_wrapTitBox">
            <span class="h3">流程说明</span>
            <div class="upDownBtn">
                <van-icon size="20" color="#999999" @click="showWrapFun" v-if="showWrapStatus" name="arrow-up" />
                <van-icon size="20" color="#999999" @click="showWrapFun" v-else name="arrow-down" />
            </div>
        </div>
        <div class="keep-flow_container" v-show="showWrapStatus">
            <div class="contentBox">
                <div class="content" v-html="remark"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        // 基本信息
        formConfig: {
            type: Object,
            default: () => { },
        },
        remark: {
            type: String,
            default: ''
        },
        order: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            showWrapStatus:true
        }
    },
    methods:{
        showWrapFun () {
            this.showWrapStatus = !this.showWrapStatus
        }
    }
}
</script>
<style lang="less" scoped>
.keep-flow_block{
    width: 100%;
    margin-bottom: 25px;
    order: 5;
    .keep-flow_wrapTitBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.42667rem;
        height: 92px;
        
        background: #fff;
        .h3 {
            color: #333333;
            font-size: 32px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            margin: 0 !important;
            text-align: left;
            font-style: normal;
        } 
        .upDownBtn{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.keep-flow_container{
   
    width: 100%;
    background: #fff;

    .contentBox{
        padding: 0 0.42667rem;
        
    }
    .content{
        text-align: justify;
        font-size: 28px;
        color: #374d58;
        border-top: 1px solid #eee;
        padding: 20px 0;
    }
}
</style>