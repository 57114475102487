<template>
    <div 
        class="keep-flow_block" 
        :order="order" 
        :style="{ 'order':order }"
    >
        <!-- 基本信息 -->
        <div class="keep-flow_wrapTitBox">
            <span class="h3">链接信息</span>
            <div class="upDownBtn">
                <van-icon size="20" color="#999999" @click="showWrapFun" v-if="showWrapStatus" name="arrow-up" />
                <van-icon size="20" color="#999999" @click="showWrapFun" v-else name="arrow-down" />
            </div>
        </div>
        <div class="keep-flow_container" v-show="showWrapStatus">
            <div class="contentBox">
                <ul class="el-upload-list el-upload-list--text">
                    <li 
                        
                        class="el-upload-list__item is-success" 
                        v-for="(value, key) in data"
                        :key="key"
                    >
                        <a 
                            @click="visitList(value, key)" 
                            class="el-upload-list__item-name"
                            style="font-size: 14px;"
                        >
                            {{ value.linkname }} 
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { getCurNodeLinkList, clickLinkUrl } from "@api/workFlow";
export default {
    props: {
        // 基本信息
        formConfig: {
            type: Object,
            default: () => { },
        },
        // 页面参数
        queryObj: {
            type: Object,
            default: null,
        },
        // 表单字段对象
        form: {
            type: Object,
            default: () => {
                loopData: [];
            },
        },
        order: {
            type: Number,
            default: 3
        },
    },
    data() {
        const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
        return {
            userInfo,
            showWrapStatus: true,
            data: []
        }
    },
    created () {
        this.getCurNodeLinkList();
    },
    computed: {
        formname () {
            return this.$route.query.formname
        },
        formautoid () {
            return this.$route.query.formautoid
        },
        mautoid () {
            return this.$route.query.mautoid
        },
        nodeid () {
            return this.$route.query.nodeid
        },
        saveormodify () {
            return this.$route.query.saveormodify
        },
    },
    methods:{
        showWrapFun () {
            this.showWrapStatus = !this.showWrapStatus
        },
        getCurNodeLinkList () {
            getCurNodeLinkList({
                formautoid: this.formautoid,
                mautoid: this.mautoid,
                username: this.userInfo.username,
                nodeid: this.nodeid
            }).then((res) => {
                this.data = res.data;
                if (this.data && this.data.length > 0 && this.saveormodify != 'save'){
                    this.$emit("hasLinkInfo", true)
                } else        {
                    this.$emit("hasLinkInfo", false)
                }
            });
        },
        visitList (value, key) {
            const link = value.linkurl;
            let newlink = link;
            for (let i = 0; i < this.form.loopData.length; i++){
                let element = this.form.loopData[i];
                for (let k = 0; k < element.length; k++){
                    let obj = element[k];
                    if (link.indexOf(obj.fieldname) != -1){
                        const fieldname = obj.fieldname
                        const formdata = obj.formdata
                        newlink = newlink.replace("@" + fieldname, formdata)

                    }
                }
            }
            clickLinkUrl({
                formautoid: this.formautoid,
                mautoid: this.mautoid,
                username: this.userInfo.username,
                nodeid: this.nodeid,
                link_autoid: value.autoid
            }).then(res => {
                var u = navigator.userAgent;
                var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                if (isAndroid){
                  //android终端
                  window.open(newlink, "_blank");
                } else if (isiOS){
                  //ios终端
                  window.location.href = newlink;
                } else{
                  window.open(newlink, "_blank");
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.keep-flow_block{
    width: 100%;
    margin-bottom: 25px;
    order: 5;
    .keep-flow_wrapTitBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.42667rem;
        height: 92px;
        
        background: #fff;
        .h3 {
            color: #333333;
            font-size: 32px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            margin: 0 !important;
            text-align: left;
            font-style: normal;
        } 
        .upDownBtn{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.keep-flow_container{
   
    width: 100%;
    background: #fff;

    .contentBox{
        padding: 0 0.42667rem;
        
    }
    .el-upload-list{
        text-align: justify;
        font-size: 28px;
        color: #374d58;
        border-top: 1px solid #eee;
        padding: 10px 0;
        .el-upload-list__item{
            margin: 20px 0;
            .el-upload-list__item-name{
                text-decoration: underline;
                color: #4747ff;
            }
        }
    }
}
</style>