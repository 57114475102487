<template>
  <div class="keep-flow_box_container">
    <div
      class="keep-flow_block"
      v-for="(item, index) in form.loopData"
      :key="index"
    >
      <!-- 基本信息 -->
      <div class="keep-flow_wrapTitBox" v-show="item.app_groupname != 'UID333'">
        <span class="h3">{{ item.app_groupname }}</span>
        <div class="upDownBtn">
          <van-icon
            size="20"
            color="#999999"
            @click="showWrapFun(item)"
            v-if="item.uptools"
            name="arrow-up"
          />
          <van-icon
            size="20"
            color="#999999"
            @click="showWrapFun(item)"
            v-else
            name="arrow-down"
          />
        </div>
      </div>
      <div class="keep-flow_container" v-show="item.uptools">
        <div class="contentBox">
          <div class="content">
            <van-form class="from-dom-container">
              <div
                class="from-dom-list"
                v-for="(list, idx) in item.children"
                :key="idx"
                v-show="list.app_formshow"
                :key-controltype="list.controltype"
                :style="
                  idx == item.children.length - 1
                    ? 'border-bottom:0;'
                    : 'border-bottom: 1px solid #f6f6f6;'
                "
              >
                <!-- 密码框 -->
                <van-field
                  v-if="list.app_formshow && !list.isread"
                  disabled
                  v-model="list.formdata"
                  :fieldname="list.fieldname"
                  type="text"
                  :required="list.mustinput"
                  :label="list.chnname"
                  :class="{
                    fieldDisabled: true,
                    mustinput: list.mustinput,
                    inputType1: true
                  }"
                />
                <!-- 只读 -->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  v-else-if="list.app_formshow && ischeck"
                  disabled
                  v-model="list.formdata"
                  :type="list.controltype === 'memo' ? 'textarea' : 'text'"
                  :autosize="list.controltype === 'memo' ? true : false"
                  :label="list.chnname"
                  :class="{
                    fieldDisabled: true,
                    mustinput: list.mustinput,
                    inputType2: true
                  }"
                />
                <!-- 文本框 暂测 -->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  v-else-if="
                    list.app_formshow &&
                      list.controltype == 'edit' &&
                      !list.ispoptree &&
                      list.poptreename != 'emptree'
                  "
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType3: true
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  :placeholder="list.control_hint_txt"
                  v-model="list.formdata"
                  :label="list.chnname"
                  @change="
                    val => {
                      valuechage(list.formdata, list)
                    }
                  "
                />
                <!-- 自定义弹出窗 -->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  v-else-if="
                    list.app_formshow &&
                      list.controltype === 'combobox' &&
                      list.showpopquery &&
                      list.listsql != '@selectmonth' &&
                      list.listsql != '@selectyear'
                  "
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType4: true
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  v-model="list.formdata"
                  :placeholder="list.control_hint_txt"
                  readonly
                  clickable
                  :label="list.chnname"
                  @click="showCuQuery(list)"
                >
                  <template #right-icon>
                    <!-- 右边下拉图标 -->
                    <van-icon name="arrow-down" />
                  </template>
                </van-field>
                <!-- 普通下拉框 暂测 -->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  clickable
                  v-else-if="
                    list.app_formshow &&
                      list.controltype === 'combobox' &&
                      !list.ispoptree &&
                      list.listsql != '@selectmonth' &&
                      list.listsql != '@selectyear'
                  "
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType5: true
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  v-model="list.formdata"
                  :placeholder="list.control_hint_txt"
                  readonly
                  :label="list.chnname"
                  @click="
                    comboboxNoispoptreefocus(
                      list,
                      list.listsql,
                      list.controltype
                    )
                  "
                >
                  <template #right-icon>
                    <!-- 右边下拉图标 -->
                    <van-icon name="arrow-down" />
                  </template>
                </van-field>
                <!-- 表格下拉框 暂测 -->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  v-else-if="
                    list.app_formshow && list.controltype === 'lookupcombobox'
                  "
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType6: true
                  }"
                  v-model="list.formdata"
                  :placeholder="list.control_hint_txt"
                  readonly
                  clickable
                  @click="
                    comboboxNoispoptreefocus(
                      list,
                      list.listsql,
                      list.controltype
                    )
                  "
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  :label="list.chnname"
                >
                  <template #right-icon>
                    <!-- 右边下拉图标 -->
                    <van-icon name="arrow-down" />
                  </template>
                </van-field>
                <!-- 下拉框弹出树(非员工树) 暂测-->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  v-else-if="
                    list.app_formshow &&
                      list.controltype === 'combobox' &&
                      list.ispoptree &&
                      list.poptreename != 'emptree' &&
                      list.listsql != '@selectmonth' &&
                      list.listsql != '@selectyear'
                  "
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType7: true
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  v-model="list.formdata"
                  :placeholder="list.control_hint_txt"
                  readonly
                  :label="list.chnname"
                  clickable
                  @click="showTree(list)"
                >
                  <template #right-icon>
                    <!-- 右边下拉图标 -->
                    <van-icon name="arrow-down" />
                  </template>
                </van-field>
                <!-- 下拉框弹出树(员工树)-->
                <van-field
                  :required="list.mustinput"
                  v-else-if="
                    list.app_formshow &&
                      (list.controltype == 'combobox' ||
                        list.controltype == 'edit') &&
                      list.ispoptree &&
                      list.poptreename === 'emptree' &&
                      list.listsql != '@selectmonth' &&
                      list.listsql != '@selectyear'
                  "
                  :fieldname="list.fieldname"
                  :class="{
                    fieldDisabled: list.fieldname=='empcode'?(can_change_empcode_field?(!list.iswrite || list.isreadonly || ischeck || status == 'readonly'):true):(!list.iswrite || list.isreadonly || ischeck || status == 'readonly'),
                    mustinput: list.mustinput,
                    inputType8: true
                  }"
                  :disabled="list.fieldname=='empcode'?(can_change_empcode_field?(!list.iswrite || list.isreadonly || ischeck || status == 'readonly'):true):(!list.iswrite || list.isreadonly || ischeck || status == 'readonly')"
                  v-model="list.formdata"
                  :placeholder="list.control_hint_txt"
                  :label="list.chnname"
                  @change="(val) => {
                    changeEmpname(val, list);
                  }"
                >
                  <template #right-icon>
                    <!-- 右边下拉图标 -->
                    <van-icon @click="showSelectJobNum(list)" name="arrow-down" />
                  </template>
                </van-field>
                <!-- 复选框 暂测 -->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  v-else-if="
                    list.app_formshow && list.controltype === 'checkbox'
                  "
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType9: true
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  :placeholder="list.control_hint_txt"
                  :label="list.chnname"
                >
                  <template #input>
                    <!-- 开关 -->
                    <van-switch
                      v-model="list.formdata"
                      :disabled="
                        !list.iswrite ||
                          list.isreadonly ||
                          ischeck ||
                          status == 'readonly'
                      "
                      @change="
                        val => {
                          valuechage(list.formdata, list)
                        }
                      "
                      size="20"
                    />
                  </template>
                </van-field>
                <!-- 年份 暂测 -->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  v-else-if="
                    list.app_formshow &&
                      list.controltype === 'combobox' &&
                      list.listsql == '@selectyear'
                  "
                  v-model="list.formdata"
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType10: true
                  }"
                  :placeholder="list.control_hint_txt"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  readonly
                  clickable
                  :label="list.chnname"
                  @click="settingYear(list)"
                >
                  <template #right-icon>
                    <div style="display: flex;align-items: center;">
                      <van-icon v-if="list.formdata" @click.stop="()=>{list.formdata = ''}" style="margin-right: 5px;" name="close" />
                      <svg-icon icon-class="calendarIcon"></svg-icon>
                    </div>
                  </template>

                </van-field>
                <!-- 月份 暂测 -->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  v-else-if="
                    list.app_formshow &&
                      list.controltype === 'combobox' &&
                      list.listsql == '@selectmonth'
                  "
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType11: true
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  v-model="list.formdata"
                  :placeholder="list.control_hint_txt"
                  readonly
                  clickable
                  :label="list.chnname"
                  @click="settingYearMonth(list)"
                >
                  <template #right-icon>
                    <div style="display: flex;align-items: center;">
                      <van-icon v-if="list.formdata" @click.stop="()=>{list.formdata = ''}" style="margin-right: 5px;" name="close" />
                      <svg-icon icon-class="calendarIcon"></svg-icon>
                    </div>
                  </template>
                </van-field>
                <!-- 日期框 暂测 -->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  v-else-if="
                    list.app_formshow && list.controltype === 'datetime'
                  "
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType12: true
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  v-model="list.formdata"
                  :placeholder="list.control_hint_txt"
                  readonly
                  clickable
                  :label="list.chnname"
                  @click="settingDatetime(list)"
                >
                  <template #right-icon>
                    <div style="display: flex;align-items: center;">
                      <van-icon v-if="list.formdata" @click.stop="()=>{list.formdata = ''}" style="margin-right: 5px;" name="close" />
                      <svg-icon icon-class="calendarIcon"></svg-icon>
                    </div>
                  </template>
                </van-field>
                <!-- 日期时间框 暂测-->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  v-else-if="
                    list.app_formshow && list.controltype === 'longdatetime'
                  "
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType13: true
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  v-model="list.formdata"
                  :placeholder="list.control_hint_txt"
                  readonly
                  clickable
                  @click="settingLongDatetime(list)"
                  :label="list.chnname"
                >
                  <template #right-icon>
                    <div style="display: flex;align-items: center;">
                      <van-icon v-if="list.formdata" @click.stop="()=>{list.formdata = ''}" style="margin-right: 5px;" name="close" />
                      <svg-icon icon-class="calendarIcon"></svg-icon>
                    </div>
                  </template>
                </van-field>
                <!-- 时间框 暂测 -->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  v-else-if="
                    list.app_formshow && 
                    list.controltype === 'maskedit' && 
                    (list.maskeditstr == 'Time' || list.maskeditstr === 'SelectTime' || list.maskeditstr === '!90:00;1;_')
                  "
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType14: true
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  v-model="list.formdata"
                  :placeholder="list.control_hint_txt"
                  readonly
                  clickable
                  :label="list.chnname"
                  @click="settingTime(list)"
                >
                  <template #right-icon>
                    <div style="display: flex;align-items: center;">
                      <van-icon v-if="list.formdata" @click.stop="()=>{list.formdata = ''}" style="margin-right: 5px;" name="close" />
                      <van-icon name="clock-o" />
                    </div>
                  </template>
                </van-field>
                <!-- 数值 -->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  v-else-if="
                    list.app_formshow && 
                    list.controltype === 'maskedit' && 
                    (
                      list.maskeditstr == 'Email' || 
                      list.maskeditstr == 'Telephone' || 
                      list.maskeditstr == 'IdCardNum' || 
                      list.maskeditstr == 'Int' || 
                      list.maskeditstr == 'PositiveInt' || 
                      list.maskeditstr == 'NegtiveInt' || 
                      list.maskeditstr == 'Float' || 
                      list.maskeditstr == 'PositiveFloat' || 
                      list.maskeditstr == 'NegtiveFloat'
                    )
                  "
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType14: true
                  }"
                  @change="
                    val => {
                      valuechage(list.formdata, list)
                    }
                  "
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  v-model="list.formdata"
                  :placeholder="list.control_hint_txt"
                  :label="list.chnname"
                  name="pattern"
                  :rules="[
                    list.maskeditstr === 'Email'
                      ? {
                          pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                          message: '请输入正确的邮箱地址'
                        }
                      :
                    list.maskeditstr === 'Telephone'
                      ? {
                          pattern: /^1\d{10}$/,
                          message: '请输入正确的手机号'
                        }
                      :
                    list.maskeditstr === 'IdCardNum'
                      ? {
                          pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                          message: '请输入正确的身份证号码'
                        }
                      :
                    list.maskeditstr === 'Int'
                      ? {
                          pattern: /^-?[1-9]\d*$/,
                          message: '请输入整数'
                        }
                      :
                    list.maskeditstr === 'PositiveInt'
                      ? {
                          pattern: /^[1-9]\d*$/,
                          message: '请输入正整数'
                        }
                      :
                    list.maskeditstr === 'NegtiveInt'
                      ? {
                          pattern: /^-[1-9]\d*$/,
                          message: '请输入负整数'
                        }
                      :
                    list.maskeditstr === 'Float' &&
                    list.maskeditstrNum === '0'
                      ? {
                          pattern: /^(-?\d+)(\.\d+)?$/,
                          message: '请输入数字'
                        }
                      :
                    list.maskeditstr === 'Float' &&
                    list.maskeditstrNum != '0'
                      ? {
                          pattern: list.maskeditstrpattern,
                          message: '请输入'+list.maskeditstrNum+'位小数'
                        }
                      :
                    list.maskeditstr === 'PositiveFloat' &&
                    list.maskeditstrNum === '0'
                      ? {
                          pattern: /^\d+(\.\d+)?$/,
                          message: '请输入非负小数'
                        }
                      :
                    list.maskeditstr === 'PositiveFloat' &&
                    list.maskeditstrNum != '0'
                      ? {
                          pattern: list.maskeditstrpattern,
                          message: '请输入非负'+list.maskeditstrNum+'位小数'
                        }
                      :
                    list.maskeditstr === 'NegtiveFloat' &&
                    list.maskeditstrNum === '0'
                      ? {
                          pattern: /^((-\d+(\.\d+)?)|(0+(\.0+)?))$/,
                          message: '请输入非正小数'
                        }
                      :
                    list.maskeditstr === 'NegtiveFloat' &&
                    list.maskeditstrNum != '0'
                      ? {
                          pattern: list.maskeditstrpattern,
                          message: '请输入非正'+list.maskeditstrNum+'位小数'
                        }
                      : {}
                  ]"
                >
                </van-field>
                <!-- 密码 -->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  type="password"
                  v-else-if="
                    list.app_formshow && 
                    list.controltype === 'maskedit' && 
                    (list.maskeditstr == 'Password')
                  "
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType14: true
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  @change="
                    val => {
                      valuechage(list.formdata, list)
                    }
                  "
                  v-model="list.formdata"
                  :placeholder="list.control_hint_txt"
                  :label="list.chnname"
                >
                </van-field>
                <!-- 钱或百分比，暂时不支持 -->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  @change="
                    val => {
                      valuechage(list.formdata, list)
                    }
                  "
                  v-else-if="
                    list.app_formshow && 
                    list.controltype === 'maskedit' && 
                    (list.maskeditstr == 'Money' || list.maskeditstr == 'Percent')
                  "
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType14: true
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  v-model="list.formdata"
                  :placeholder="list.control_hint_txt"
                  :label="list.chnname"
                >
                </van-field>
                <!-- 银行卡识别，暂时不支持 -->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  v-else-if="
                    list.app_formshow && 
                    list.controltype === 'maskedit' && 
                    (list.maskeditstr == 'bankaccount_ocr')
                  "
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType14: true
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  v-model="list.formdata"
                  :placeholder="list.control_hint_txt"
                  :label="list.chnname"
                  @change="
                    val => {
                      valuechage(list.formdata, list)
                    }
                  "
                >
                  <template #right-icon>
                    <van-icon name="balance-pay" />
                  </template>
                </van-field>
                <!-- 多行文本框 暂测-->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  v-else-if="list.app_formshow && list.controltype === 'memo'"
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType15: true
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  v-model="list.formdata"
                  :placeholder="list.control_hint_txt"
                  type="textarea"
                  autosize
                  :label="list.chnname"
                  @change="
                    val => {
                      valuechage(list.formdata, list)
                    }
                  "
                >
                </van-field>
                <!-- 颜色选项卡 -->
                <van-field
                  :required="list.mustinput"
                  :fieldname="list.fieldname"
                  v-else-if="
                    list.app_formshow && list.controltype === 'colorcombobox'
                  "
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType16: true
                  }"
                  :label="list.chnname"
                >
                  <template #input>
                    <input
                      :disabled="
                        !list.iswrite ||
                          list.isreadonly ||
                          ischeck ||
                          status == 'readonly'
                      "
                      @change="
                        val => {
                          valuechage(list.formdata, list)
                        }
                      "
                      type="color"
                      v-model="list.formdata"
                      id="colorPicker"
                    />
                  </template>
                </van-field>
                <!-- 复选组 测试 -->
                <van-field  
                  v-else-if="
                    list.app_formshow &&
                    list.controltype === 'checkgroup'
                  " 
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  class="vanfield_checkgroup_box" 
                  :label="list.chnname"
                  :fieldname="list.fieldname"
                >
                  <template #input>
                    <van-checkbox-group v-model="list.formdata" @change="(val) => {
                      valuechage(list.formdata, list)
                    }">
                      <van-checkbox shape="square" :name="value" v-for="(value, key) in list.checkgroupData" :key="key">
                        {{ value }}
                      </van-checkbox>
                    </van-checkbox-group>
                  </template>
                </van-field>
                <!-- 单选组 测 -->
                <van-field 
                  v-else-if="
                    list.app_formshow &&
                    list.controltype === 'radiogroup'
                  " 
                  :label="list.chnname" 
                  class="vanfield_radiogroup_box"
                  :fieldname="list.fieldname"
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                >
                  <template #input>
                    <van-radio-group v-model="list.formdata" @change="(val) => {
                      valuechage(list.formdata, list)
                    }">
                      <van-radio :name="value" v-for="(value, key) in list.checkgroupData" :key="key">
                        {{ value }}
                      </van-radio>
                    </van-radio-group>
                  </template>
                </van-field>
                <!-- 多选下拉 -->
                <van-field 
                  placeholder="请输入" 
                  :fieldname="list.fieldname"
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  v-else-if="
                    list.app_formshow &&
                    list.controltype === 'selectcombobox'
                  " 
                  :label="list.chnname"
                >
                  <template #input>
                    <selectMultiple v-if="list.checkgroupData.length > 0" :columns="list.checkgroupData" v-model="list.formdata">
                    </selectMultiple>
                  </template>
                </van-field>
                <van-field
                  :required="list.mustinput"
                  v-else-if="list.app_formshow"
                  :fieldname="list.fieldname"
                  :class="{
                    fieldDisabled:
                      !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly',
                    mustinput: list.mustinput,
                    inputType14: true
                  }"
                  :disabled="
                    !list.iswrite ||
                      list.isreadonly ||
                      ischeck ||
                      status == 'readonly'
                  "
                  v-model="list.formdata"
                  :placeholder="list.control_hint_txt"
                  :label="list.chnname"
                >
                </van-field>
              </div>
            </van-form>
          </div>
        </div>
      </div>
    </div>
    <!-- dateTime控件 -->
    <van-popup v-model="showDatetimePicker" position="bottom">
      <van-datetime-picker
        :title="selectFormdata.chnname"
        v-model="currentDatetime"
        type="date"
        @confirm="onDatetimeConfirm"
        @cancel="showDatetimePicker = false"
      />
    </van-popup>
    <!-- longdatetime控件 -->
    <van-popup v-model="showLongDatetimePicker" position="bottom">
      <van-datetime-picker
        :title="selectFormdata.chnname"
        v-model="currentLongDatetime"
        type="datetime"
        @confirm="onLongDatetimeConfirm"
        :filter="filterTime"
        @cancel="showLongDatetimePicker = false"
      />
    </van-popup>
    <!-- maskedit控件 -->
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker
        :title="selectFormdata.chnname"
        v-model="currentTime"
        type="time"
        @confirm="onTimeConfirm"
        @cancel="showTimePicker = false"
        :filter="filterTime"
      />
    </van-popup>
    <!-- 月份控件 -->
    <van-popup v-model="showYearMonthPicker" position="bottom">
      <van-datetime-picker
        :title="selectFormdata.chnname"
        v-model="currentYearMonth"
        type="year-month"
        @confirm="onYearMonthConfirm"
        @cancel="showYearMonthPicker = false"
        :formatter="formatter"
      />
    </van-popup>
    <!-- 年份控件 -->
    <van-popup v-model="showYearPicker" position="bottom" class="hideNfLLL">
      <van-datetime-picker
        :title="selectFormdata.chnname"
        v-model="currentYear"
        type="year-month"
        @confirm="onYearConfirm"
        @cancel="showYearPicker = false"
        :formatter="formatter2"
      />
    </van-popup>
    <!-- combobox控件 -->
    <van-popup v-model="showComboboxPicker" position="bottom">
      <van-picker
        :title="selectFormdata.chnname"
        show-toolbar
        value-key="dicvalue"
        :columns="comboboxChoices"
        @cancel="showComboboxPicker = false"
        @confirm="onComboboxConfirm"
        :default-index="comboboxDefaultIndex"
      />
    </van-popup>
    <!-- lookupCombobox控件 -->
    <van-popup v-model="showLookupComboboxPicker" position="bottom">
      <div style="width: 100%;overflow: hidden;padding: 10px;">
        <div class="table_box" style="overflow: auto;background: #fff;">
          <table ref="table" class="table">
            <tr
              class="top"
              v-for="(choice, i) in lookupcomboboxChoices.head"
              :key="i + '1'"
            >
              <th v-for="(key, j) in choice.name" :key="j">
                <div
                  style="margin: 0 auto;"
                  class="title"
                  :style="
                    choice.width[j]
                      ? { width: choice.width[j] * 10 + 'px' }
                      : 'min-width:90px'
                  "
                  :c="choice.width[j]"
                >
                  {{ key }}
                </div>
              </th>
            </tr>
            <tr
              @click="onLookupComboboxConfirm(choice, i)"
              class="cont"
              v-for="(choice, i) in lookupcomboboxChoices.data"
              :key="i"
              :class="
                choice.indexOf('isLookupcomboboxActive') != -1
                  ? 'isLookupcomboboxActiveBg'
                  : ''
              "
            >
              <td
                v-for="(k, idx) in choice"
                :key="idx"
                v-show="k != 'isLookupcomboboxActive'"
              >
                {{ k }}
              </td>
            </tr>
          </table>
        </div>
        <div class="bottomPage" style="margin-top: 3px;">
          <van-pagination
            class="empPage"
            v-model="currentPage"
            :total-items="lookupcomboboxTotal"
            :show-page-size="3"
            force-ellipses
            @change="lookupcomboboxPageChange"
          />
        </div>
      </div>
    </van-popup>
    <!-- 选择人员树弹窗 -->
    <PopTree
      ref="popTreeNode"
      :whichTree="whichTreeName"
      :module="formConfig.moduleno || formautoid"
      :multiseltree="isMultiselTree"
      @treeSelectValEvent="treeSelectValEvent"
    ></PopTree>
    <!-- 多选员工 -->
    <empDataTable :singleSel="true" ref="refEmpDataTable" @choiceSelect="choiceSelect"></empDataTable>
  </div>
</template>
<script>
import { Toast, Dialog, Notify } from 'vant'
import PopTree from '@components/PopTree/index.vue'
import empDataTable from '@components/empDataTable/index.vue'
import selectMultiple from '@/components/selectMultiple/index.vue'
// 审批流主表数据带出
// 工作流表单数据退出提醒接口
// 获取工作流单据主表新增默认值接口
// 获取某个动作策略前的过程列表接口
// 执行某个动作策略前过程接口
// 保存工作流单据主表记录内容接口
import {
  getWFFormFieldExitValue,
  getWFFormNewDefaultValue,
  getBeforeActionList,
  processBeforeAction,
  saveWFRecord,
  getListSource,
  GetCustomQueryInfo,
  getEmpInfo,
  getQueryEmpModuleNo,
  beforeCheckDetailData
} from '@api/workFlow'
import { getOpenId } from '@/utils/auth'
import { parseTime, hex2int, int2hex, paging } from '@/utils'
export default {
  components: {
    PopTree,
    selectMultiple,
    empDataTable
  },
  props: {
    // 基本信息
    formConfig: {
      type: Object,
      default: () => {}
    },
    // 表单字段对象
    form: {
      type: Object,
      default: () => {
        loopData: []
      }
    },
    // 模块号
    formautoid: {
      type: [String, Number],
      default: ''
    },
    // 表单状态（save:新增,modify:修改,readonly:只读），此值可能被更新
    status: {
      type: String,
      default: 'save'
    },
    // 记录状态（save:新增,modify:修改,readonly:只读 ），此值不可能被更新
    saveormodify: {
      type: String,
      default: 'save'
    },
    // 当前记录autoid
    acceptid: {
      type: [String, Number],
      default: 0
    },
    ischeck: {
      type: [String, Boolean],
      default: false
    },
    can_change_empcode_field: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : this.$route.query.formoutSize == '1'
      ? { userid: 1, username: 'admin' } // 外部人员打开单据时用到
      : {}
    return {
      userInfo,
      showWrapStatus: true,
      showDatetimePicker: false, // 日期
      new_acceptid: this.acceptid,
      new_saveormodify: this.saveormodify,
      new_status: this.status,
      formstr: {}, // 保存表单填写数据
      intarry: ['int', 'smallint'], // sql整数类型
      floatarry: ['money', 'float', 'numeric'], // sql小数类型
      logdetail: '', // 有修改的字段
      hasEXfieldlist: [], // 当前数据带出链已经带出过的字段
      currentDatetime: new Date(),
      selectFormdata: {}, // 选中的字段（用于接收弹窗返回信息）
      showComboboxPicker: false,
      comboboxChoices: [],
      showLookupComboboxPicker: false,
      lookupcomboboxChoices: {
        head: [],
        data: []
      },
      lookupcomboboxTotal: 0, // 进度条 总数
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页多少条数据
      searchVal: '', // lookupcombobox模糊搜索值
      lookupcomboboxList: [],
      bigArray: [],
      showLongDatetimePicker: false,
      currentLongDatetime: new Date(),
      isMultiselTree: false,
      whichTreeName: '',
      comboboxDefaultIndex: 0, // 选择控件的默认值
      showTimePicker: false,
      currentTime: new Date(),
      showYearMonthPicker: false,
      currentYearMonth: new Date(),
      showYearPicker: false,
      currentYear: new Date(),
      customQueryForminfo: {}, //自定义窗体-构造查询窗体信息
      customQueryQueryparaminfo: [], //自定义窗体-构造顶部查询条件
      singleSel: true, // 多选是false，目前该版本只有单选
      fileLen: 0
    }
  },
  watch: {
    acceptid(n) {
      this.new_acceptid = n
    },
    saveormodify(n) {
      this.new_saveormodify = n
    }
  },
  mounted() {
    this.new_acceptid = this.acceptid
    this.new_saveormodify = this.saveormodify
  },
  methods: {
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      }
      return val
    },
    formatter2(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return ``
      }
      return ''
    },
    showWrapFun(item) {
      item.uptools = !item.uptools
    },
    // 首先执行这个函数 设置表单新建默认值
    async setcdefaultvalue(formautoid) {
      this.updateformstr()
      //获取新建默认值
      await getWFFormNewDefaultValue({
        formautoid: formautoid,
        username: this.userInfo.username,
        formstr: JSON.stringify(this.formstr)
      }).then(async res => {
        // 如果是组织架构或者岗位管理，额外添加treeNode的部门节点
        let data = res.data
        for(let item of data){
          this.setValue(item.fieldname, item.cdefaultvalue)
          for(let it of this.form.loopData){
            for(let i of it.children){
              if (i.isexitvalue && i.fieldname === item.fieldname) {
                //启用数据带出
                await this.bulidExitValue(
                  item.fieldname,
                  item.cdefaultvalue,
                  this.ExitValue
                )
              }
            }
          }
        }
        this.setRouteQueryObj();
        // console.log(this.$route.query,'this.$routethis.$route')
      })
    },
    // 根据地址参数，设置主表默认值
    setRouteQueryObj () {
      if(this.$route.query.saveormodify == 'save'){
        const obj = this.$route.query;
        for(let key in obj){
          if(typeof key == 'string'){
            if(key.indexOf("_def_value_") != -1){
              const trueKey = key.slice(11);
              const trueValue = obj[key];
              if(trueValue){
                this.setValue(trueKey, trueValue)
              }
            }
          }
        }
      }
    },
    // 构造数据带出结构体，发起数据打出请求
    async bulidExitValue(n, v, fun) {
      this.updateformstr()
      const totalformstr = JSON.stringify(this.formstr)
      const editstate =
        this.new_acceptid === '' ||
        this.new_acceptid === null ||
        this.new_acceptid === '0' ||
        this.new_acceptid === 0
          ? 1
          : 2
      const data = {
        editstate: editstate,
        formautoid: this.formautoid,
        fieldname: n,
        fieldvalue: v,
        username: this.userInfo.username,
        formstr: totalformstr
      }
      if (typeof fun === "function")
      {
        if(
          typeof data.fieldvalue == 'object' && 
          typeof data.fieldvalue.isTrusted != 'undefined'
        ){
          console.log(typeof data.fieldvalue, typeof data.fieldvalue.isTrusted,'类型')
        }else{
          await fun(data);
        }
      }
    },
    // 数据带出
    async ExitValue(data) {
      if (this.hasEXfieldlist.indexOf(data.fieldname) == -1) {
        this.hasEXfieldlist.push(data.fieldname)
        await getWFFormFieldExitValue(data).then(res => {
          const data = res.data
          data.forEach(item => {
            if ('isreadonly' in item) {
              // 如果带出过程携带isreadonly，则修改对应字段的isreadonly值
              if (item.isreadonly === '') item.isreadonly = null
              if (item.isreadonly != null)
                this.setReadOnly(item.fieldname, item.isreadonly, 'isreadonly')
            }
            if ('mustinput' in item) {
              // 如果带出过程携带mustinput，则修改对应字段的mustinput值
              if (item.mustinput === '') item.mustinput = null
              if (item.mustinput != null)
                this.setReadOnly(item.fieldname, item.mustinput, 'mustinput')
            }
            if ('formshow' in item) {
              // 如果带出过程携带mustinput，则修改对应字段的mustinput值
              if (typeof item.formshow == 'boolean') {
                this.setReadOnly(item.fieldname, item.formshow, 'app_formshow')
              }
            }
            if (item.fieldvalue != '<OrgFieldValue>') {
              this.setValue(item.fieldname, item.fieldvalue)
            }
          })
        })
      }
    },
    // 数据带出修改只读状态
    setReadOnly(n, isreadonly, key) {
      this.form.loopData.forEach(it => {
        it.children.forEach(i => {
          if (i.fieldname === n) {
            i[key] = isreadonly
          }
        })
      })
    },
    // 设置字段值（不触发数据带出）
    setValue(n, v, tar = 'new') {
      this.form.loopData.forEach(it => {
        it.children.forEach(i => {
          if (i.fieldname === n) {
            let vv = v
            if (i.fielddatatype === 'bit') {
              const truearr = [1, '1', 'true', 'True', 'TRUE', true]
              if (!this.ischeck) {
                vv = truearr.indexOf(v) != -1 ? true : false
              } else {
                vv = truearr.indexOf(v) != -1 ? '是' : '否'
              }
            } else if (
              (i.controltype === 'longdatetime' ||
                i.controltype === 'datetime') &&
              vv !== null &&
              !this.ischeck
            ) {
              if (i.controltype === 'datetime') {
                vv = parseTime(vv, '{y}-{m}-{d}')
              }
              if (i.datetime === 'longdatetime') {
                vv = parseTime(vv, '{y}-{m}-{d} {h}:{i}:{s}')
              }
            } else if (i.controltype === 'colorcombobox' && vv !== null) {
              vv = '#' + int2hex(vv)
            } else if (i.controltype === 'memo' && vv !== null) {
              vv = vv
                .toString()
                .replace(/\\n/g, '\n')
                .replace(/\\r/g, '\r')
            } else if (i.controltype === 'combobox' && vv !== null) {
              if (i.listsql == '@selectyear') {
                // vv = parseTime(vv, "{y}")
              } else if (i.listsql == '@selectmonth') {
                // vv = parseTime(vv, "{y}-{m}")
              }
            } else if (i.controltype === 'checkgroup' && vv) {
              vv = vv.split(',')
            } else if (i.controltype === 'checkgroup' && !vv) {
              vv = []
            } else if (i.controltype === 'selectcombobox' && vv) {
              vv = vv.split(',')
            } else if (i.controltype === 'selectcombobox' && !vv) {
              vv = []
            }
            i.formdata = vv
            if (tar === 'old') {
              i.oldvalue = vv
              i.haschange = false
              i.newvalue = ''
            } else if (tar === 'new') {
              i.haschange = true
              i.newvalue = vv
            }
          }
        })
      })
      this.$forceUpdate()
    },
    upMkeyIdFun(mainId) {},
    filterTime (type, options) {
      if (type === 'minute') {
        let canshu = 1
        if(this.selectFormdata.stepmin <= 0 || !this.selectFormdata.stepmin){
          canshu = 1
        }else{
          canshu = parseInt(this.selectFormdata.stepmin)
        }
        return options.filter((option) => option % canshu === 0);
      }
      return options;
    },
    updateformDataFiled(filed, value) {
      this.form.loopData.forEach(item => {
        item.children.forEach(it => {
          if (filed == it.fieldname) {
            it.formdata = value
          }
        })
      })
    },
    // 更新formstr
    updateformstr() {
      this.logdetail = ''
      let logdetailarr = []
      this.form.loopData.forEach(item => {
        item.children.forEach(it => {
          if (it.fieldname && it.fieldname != 'undefined') {
            this.formstr[it.fieldname] =
              it.formdata === null || it.formdata === undefined
                ? 'null'
                : it.formdata === '' &&
                  (it.controltype === 'datetime' ||
                    this.floatarry.indexOf(it.fielddatatype) != -1 ||
                    this.intarry.indexOf(it.fielddatatype) != -1)
                ? 'null'
                : it.formdata === ''
                ? it.formdata
                : it.controltype === 'datetime'
                ? parseTime(it.formdata, '{y}-{m}-{d}')
                : it.controltype === 'checkbox'
                ? it.formdata === '是'
                  ? 'true'
                  : it.formdata === '否'
                  ? 'false'
                  : it.formdata.toString()
                : it.controltype === 'longdatetime'
                ? parseTime(it.formdata, '{y}-{m}-{d} {h}:{i}:{s}')
                : it.formdata && it.controltype === 'colorcombobox'
                ? hex2int(it.formdata)
                : it.formdata.toString()
            if (
              it.maskeditstr === 'Time' ||
              it.maskeditstr === 'SelectTime' ||
              it.maskeditstr === '!90:00;1;_'
            ) {
              it.formdata =
                it.formdata && typeof it.formdata == 'string'
                  ? it.formdata.substr(0, 5)
                  : it.formdata
              this.formstr[it.fieldname] =
                this.formstr[it.fieldname] &&
                typeof this.formstr[it.fieldname] == 'string'
                  ? this.formstr[it.fieldname].substr(0, 5)
                  : this.formstr[it.fieldname]
            }
            if (it.controltype === 'checkgroup') {
              if (
                it.formdata &&
                typeof it.formdata == 'object' &&
                it.formdata.length > 0
              ) {
                this.formstr[it.fieldname] = it.formdata.join(',')
              } else {
                this.formstr[it.fieldname] = ''
              }
            }
            if (it.controltype === 'selectcombobox') {
              if (
                it.formdata &&
                typeof it.formdata == 'object' &&
                it.formdata.length > 0
              ) {
                this.formstr[it.fieldname] = it.formdata.join(',')
              } else {
                this.formstr[it.fieldname] = ''
              }
            }
            if (it.haschange && this.status === 'modify') {
              logdetailarr.push(
                '{' +
                  it.fieldname +
                  ',' +
                  it.chnname +
                  ',' +
                  it.oldvalue +
                  ',' +
                  it.newvalue +
                  '}'
              )
            }
          }
        })
      })
      this.logdetail = logdetailarr.join(',')
      if (this.formstr.hasOwnProperty('undefined')) {
        delete this.formstr['undefined']
      }
      if (
        this.formstr.hasOwnProperty('openid') &&
        this.$route.query.formoutSize == '1'
      ) {
        // 外部人员填写单据时如果有openid字段则自动保存
        let hasOpenId = getOpenId()
        if (hasOpenId) {
          this.formstr['openid'] = hasOpenId
        }
      }
      return this.formstr
    },
    // 表单change事件（判断是否触发数据带出）
    valuechage(val, list) {
      this.$emit('setCanSave', true)
      this.hasEXfieldlist = []
      list.haschange = true
      list.newvalue = val
      if (list.isexitvalue) {
        //启用数据带出
        let fun = this.ExitValue
        this.bulidExitValue(list.fieldname, val, fun)
      }
    },
    // Datetime控件
    settingDatetime(list) {
      this.selectFormdata = list
      this.currentDatetime = list.formdata
        ? new Date(list.formdata)
        : new Date()
      this.showDatetimePicker = true
    },
    // Datetime控件
    onDatetimeConfirm(time) {
      const v = parseTime(time, '{y}-{m}-{d}')
      this.setValue(this.selectFormdata.fieldname, v)
      if (this.selectFormdata.isexitvalue) {
        this.valuechage(v, this.selectFormdata)
      }
      this.showDatetimePicker = false
    },
    // longDatetime控件
    settingLongDatetime(list) {
      const yyyy = new Date().getFullYear();
      const mmmm = new Date().getMonth()+1;
      const dddd = this.padWithZeros(new Date().getDate(), 2)
      const hours = this.padWithZeros(new Date().getHours(), 2)
      const minute = this.padWithZeros(new Date().getMinutes(), 2)
      list.formdata?new Date(list.formdata):yyyy+'-'+mmmm+'-'+dddd+' '+hours+':00'
      this.selectFormdata = list
      this.currentLongDatetime = list.formdata
        ? new Date(list.formdata)
        : new Date(yyyy+'-'+mmmm+'-'+dddd+' '+hours+':00:00')
      this.showLongDatetimePicker = true
    },
    // longDatetime控件
    onLongDatetimeConfirm(time) {
      const v = parseTime(time, '{y}-{m}-{d} {h}:{i}:{s}')
      this.setValue(this.selectFormdata.fieldname, v)
      if (this.selectFormdata.isexitvalue) {
        this.valuechage(v, this.selectFormdata)
      }
      this.showLongDatetimePicker = false
    },
    // time控件
    settingTime(list) {
      // 如果客户说，读取的不是当前时间，不要叫我改回去！
      this.selectFormdata = list
      const hours = this.padWithZeros(new Date().getHours(), 2)
      const minute = this.padWithZeros(new Date().getMinutes(), 2)
      // list.formdata?list.formdata:hours+":"+minute
      this.currentTime = list.formdata?list.formdata:hours+":00"
      this.showTimePicker = true
      this.$forceUpdate();
    },
    padWithZeros(number, length) {
      var str = '' + number
      while (str.length < length) {
        str = '0' + str
      }
      return str
    },
    onTimeConfirm(time) {
      this.setValue(this.selectFormdata.fieldname, time)
      if (this.selectFormdata.isexitvalue) {
        this.valuechage(time, this.selectFormdata)
      }
      this.showTimePicker = false
    },
    // 月份控件
    settingYearMonth(list) {
      this.selectFormdata = list
      if(list.formdata){
        let yyyy = list.formdata.slice(0,4);
        let mmmm = list.formdata.slice(4);
        this.currentYearMonth = new Date(yyyy+'-'+mmmm+'-01')
      }else{
        this.currentYearMonth = new Date()
      }
      this.showYearMonthPicker = true
    },
    onYearMonthConfirm(time) {
      const v = parseTime(time, '{y}{m}')
      this.setValue(this.selectFormdata.fieldname, v)
      if (this.selectFormdata.isexitvalue) {
        this.valuechage(v, this.selectFormdata)
      }
      this.showYearMonthPicker = false
    },
    // 年份控件
    settingYear(list) {
      this.selectFormdata = list
      if(list.formdata){
        this.currentYear = new Date(list.formdata+'-'+'01'+'-01')
      }else{
        this.currentYear = new Date()
      }
      this.showYearPicker = true
    },
    onYearConfirm(time) {
      console.log(this.selectFormdata.fieldname, 'time')
      const v = new Date(time).getFullYear()
      this.setValue(this.selectFormdata.fieldname, v)
      if (this.selectFormdata.isexitvalue) {
        this.valuechage(v, this.selectFormdata)
      }
      this.showYearPicker = false
    },
    // select相关
    comboboxNoispoptreefocus(list, data, controltype) {
      const selectListFormData = list.formdata
      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '请求中...'
      })
      this.updateformstr()
      this.selectFormdata = list
      getListSource({
        iswf: true,
        moduleno: this.formautoid,
        fieldname: list.fieldname,
        username: this.userInfo.username,
        listsql: data,
        formstr: JSON.stringify(this.formstr),
        list_source_type: list.list_source_type,
        list_source_name: list.list_source_name,
        list_api_type: list.list_api_type,
        list_api_url: list.list_api_url,
        list_api_mode: list.list_api_mode,
        list_api_headerlist: list.list_api_headerlist
      }).then(res => {
        Toast.clear()
        if (controltype === 'combobox') {
          this.comboboxDefaultIndex = 0
          this.showComboboxPicker = true
          let tempdata = []
          //普通下拉框只取第一个属性值，并且明明为dicvalue
          res.data.forEach(item => {
            tempdata.push({ dicvalue: item[Object.keys(item)[0]] })
          })
          this.comboboxChoices = tempdata
          if (selectListFormData) {
            for (let i = 0; i < tempdata.length; i++) {
              if (tempdata[i].dicvalue == selectListFormData) {
                this.comboboxDefaultIndex = i
                break
              }
            }
          }
          // comboboxDefaultIndex
        } else if (controltype === 'lookupcombobox') {
          this.lookupcomboboxList = []
          let tempdata2 = { head: [{ name: [], width: [] }], data: [] },
            li = []
          if (res.data.length > 0) {
            Object.keys(res.data[0]).forEach(item => {
              tempdata2.head[0].name.push(item)
              tempdata2.head[0].width.push(item.toString().length)
            })
          }
          //表格下拉框需要新增表头数据
          for (let i = 0; i < res.data.length; i++) {
            var tp = []
            for (let j = 0; j < tempdata2.head[0].name.length; j++) {
              let v = res.data[i][tempdata2.head[0].name[j]]
              v =
                v === null
                  ? ''
                  : v.toString().toLowerCase() === 'true'
                  ? '是'
                  : v.toString().toLowerCase() === 'false'
                  ? '否'
                  : v
              tp.push(v)
              if (v != null) {
                let vl = v.toString().length
                tempdata2.head[0].width[j] =
                  tempdata2.head[0].width[j] >= vl
                    ? tempdata2.head[0].width[j]
                    : vl
              }
            }
            li.push(tp)
          }
          for (let j = 0; j < tempdata2.head[0].name.length; j++) {
            let name = tempdata2.head[0].name[j]

            tempdata2.head[0].name[j] =
              name.indexOf('_') > -1 ? name.split('_')[0] : name
          }
          this.lookupcomboboxList = li
          let isLookupcomboboxActiveIndex = 0
          this.currentPage = 1
          if (selectListFormData) {
            for (let i = 0; i < this.lookupcomboboxList.length; i++) {
              if (selectListFormData == this.lookupcomboboxList[i][0]) {
                this.lookupcomboboxList[i].push('isLookupcomboboxActive')
                isLookupcomboboxActiveIndex = i
              }
            }
            this.currentPage = Math.ceil(
              (isLookupcomboboxActiveIndex + 1) / this.pageSize
            )
          }
          // console.log(isLookupcomboboxActiveIndex,this.lookupcomboboxList,'listlistlist')
          this.lookupcomboboxTotal = this.lookupcomboboxList.length
          this.bigArray = paging(this.pageSize, this.lookupcomboboxList)

          tempdata2.data = this.bigArray[this.currentPage - 1]
          this.lookupcomboboxChoices = tempdata2
          this.showLookupComboboxPicker = true
        }
      })
    },
    lookupcomboboxPageChange() {
      this.lookupcomboboxChoices.data = this.bigArray[this.currentPage - 1]
    },
    // combobox控件
    onComboboxConfirm(obj) {
      this.setValue(this.selectFormdata.fieldname, obj.dicvalue)
      if (this.selectFormdata.isexitvalue) {
        this.valuechage(obj.dicvalue, this.selectFormdata)
      }
      this.showComboboxPicker = false
    },
    // lookupcombobox控件
    onLookupComboboxConfirm(obj, index) {
      this.setValue(this.selectFormdata.fieldname, obj[0])
      // 下拉字段带出过程
      if (this.selectFormdata.isexitvalue) {
        this.valuechage(obj[0], this.selectFormdata)
      }
      this.showLookupComboboxPicker = false
    },
    // 非员工树
    showTree(list) {
      this.whichTreeName = "";
      this.updateformstr()
      this.selectFormdata = list
      this.isMultiselTree = list.multiseltree
      this.$refs.popTreeNode.module =
        this.formConfig.moduleno || this.formautoid
      this.$refs.popTreeNode.treename = list.poptreename
      this.$refs.popTreeNode.fieldname = this.selectFormdata.fieldname
      this.$refs.popTreeNode.editstate = 2
      this.$refs.popTreeNode.showEmpTreePop('数据')
    },
    showSelectJobNum (list) {
      this.whichTreeName = "employee";
      this.updateformstr();
      // getQueryEmpModuleNo
      getQueryEmpModuleNo({
        moduleno: this.formautoid,
        username:this.userInfo.username,
        iswf: true,
        fieldname: list.fieldname,
      }).then((res) => {
        this.selectFormdata = list;
        this.quemoduleno = res.data[0].moduleno;
        this.$refs.popTreeNode.module = this.quemoduleno
        this.$refs.popTreeNode.treename = list.poptreename
        this.isMultiselTree = list.multiseltree
        this.$refs.popTreeNode.fieldname = this.selectFormdata.fieldname
        this.$refs.popTreeNode.editstate = 2
        this.$refs.popTreeNode.showEmpTreePop('数据')
      });
    },
    // 确定树的选择
    treeSelectValEvent(data) {
      this.$emit('setCanSave', true)
      // this.hasEXfieldlist = [];
      if (this.selectFormdata.multiseltree) {
        //多选树
        const namelist = data.map(it =>
          it.keyname.indexOf(' ') != -1 ? it.keyname.split(' ')[0] : it.keyname
        )
        const names = namelist.join(',')
        this.setValue(this.selectFormdata.fieldname, String(names))
        // 下拉字段带出过程
        if (this.selectFormdata.isexitvalue) {
          this.valuechage(names, this.selectFormdata) //判断数据带出
        }
      } else {
        //单选树
        this.setValue(
          this.selectFormdata.fieldname,
          data.keyname.indexOf(' ') != -1
            ? data.keyname.split(' ')[0]
            : data.keyname
        )
        this.setValue(
          this.selectFormdata.popupdatefield,
          data[this.selectFormdata.popbackkeyid]
        )
        // 下拉字段带出过程
        if (this.selectFormdata.isexitvalue) {
          this.valuechage(
            data.keyname.indexOf(' ') != -1
              ? data.keyname.split(' ')[0]
              : data.keyname,
            this.selectFormdata
          )
        }
        //转化字段带出过程
        this.form.loopData.forEach(item => {
          item.children.forEach(it => {
            if (
              it.isexitvalue &&
              it.fieldname === this.selectFormdata.popupdatefield
            ) {
              this.valuechage(data[this.selectFormdata.popbackkeyid], it) //判断数据带出
            }
          })
        })
      }
    },
    // 保存前动作
    beforeAction(from) {
      // console.log(from,'fromfromfrom');
      let promptdata1 = []
      /**
       *  resolve
       *  resolve.status，success.代表主表保存成功 | fail.代表失败 | continue.代表继续执行
       *  resolve.returnData = {},  返回给前一个页面的对象
       */
      return new Promise(async (resolve, reject) => {
        this.updateformstr()
        try {
          const res1 = await getBeforeActionList({
            // 先请求保存接口1，获得acprocname
            actype: 0,
            formautoid: this.formautoid,
            username: this.userInfo.username
          })
          const arr = res1.data // acprocname数组
          for (const item of arr) {
            const editstate =
              this.new_acceptid === '' ||
              this.new_acceptid === null ||
              this.new_acceptid === '0' ||
              this.new_acceptid === 0
                ? 1
                : 2
            let savefstr = ''
            if (typeof this.fileLen == 'number') {
              let obj = {
                '#masterfilecount_total': this.fileLen + ''
              }
              let formobj = Object.assign(this.formstr, obj)
              savefstr = JSON.stringify(formobj)
            } else if (typeof this.fileLen == 'object') {
              let formobj = Object.assign(this.formstr, this.fileLen)
              savefstr = JSON.stringify(formobj)
            } else {
              savefstr = JSON.stringify(this.formstr)
            }
            const result = await processBeforeAction({
              acproname: item.acprocname,
              editstate: editstate,
              formautoid: this.formautoid,
              username: this.userInfo.username,
              autoid: this.new_acceptid,
              formstr: savefstr, // 表格填写信息
              isapp:1
            })
            if (result.data.length > 0) {
              promptdata1.push(result.data[0])
            } else {
              promptdata1.push({
                isinfo: true,
                info: result.errormsg,
                showbox: false,
                cansave: false
              })
            }
          }
        } catch (e) {
          promptdata1.push({
            isinfo: true,
            info: e.errormsg,
            showbox: false,
            cansave: false
          })
        }
        let a = await this.postAction(promptdata1, from)
        resolve(a)
      })
    },
    //保存前返回值处理+保存动作
    postAction(n, from) {
      return new Promise(async (resolve, reject) => {
        const arr = n
        const saveornot = []
        // 处理formstr数字转换成字符串
        for (const key in this.formstr) {
          if (!isNaN(this.formstr[key])) {
            this.formstr[key] = String(this.formstr[key])
          }
        }
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].isinfo && !arr[i].cansave) {
            if (this.singleSel) {
              if (arr[i].showbox) {
                Dialog.alert({
                  message: arr[i].info
                }).then(() => {})
              } else {
                Notify(arr[i].info)
              }
              saveornot.push(1)
            } else {
              if (arr[i].showbox) {
                Dialog.alert({
                  message: arr[i].info
                }).then(() => {})
              } else {
                Notify(arr[i].info)
              }
            }
            this.$emit('cancelLoading')
            resolve({
              status: 'fail',
              returnData: null,
              message:arr[i].info 
            })
            return
          } else if (arr[i].isinfo && arr[i].cansave) {
            if (this.singleSel) {
              const a = await this.ask(arr[i].info)
              if (!a) {
                saveornot.push(0)
                resolve({
                  status: 'fail',
                  returnData: null,
                  message:arr[i].info 
                })
                return
              }
            } else {
              return true
            }
          }
        }
        if (saveornot.length > 0) {
          if (saveornot.some(i => i == 1)) {
            if (this.singleSel) {
              Dialog.alert({
                message: '保存失败'
              }).then(() => {})
            } else {
              Notify('保存失败')
            }
            this.$emit('cancelLoading')
          }
          resolve({
            status: 'fail',
            returnData: null,
            message:JSON.stringify(saveornot)
          })
          return
        } else {
          const editstate =
            this.new_acceptid === '' ||
            this.new_acceptid === null ||
            this.new_acceptid === '0' ||
            this.new_acceptid === 0
              ? 1
              : 2
          this.formstr[this.formConfig.mkeyname] = this.new_acceptid //更新主键
          for (const key in this.formstr) {
            if (!isNaN(this.formstr[key])) {
              this.formstr[key] = String(this.formstr[key])
            }
          }
          let savefstr = ''
          if (typeof this.fileLen == 'number') {
            let obj = {
              '#masterfilecount_total': this.fileLen + ''
            }
            let formobj = Object.assign(this.formstr, obj)
            savefstr = JSON.stringify(formobj)
          } else if (typeof this.fileLen == 'string') {
            let formobj = Object.assign(this.formstr, this.fileLen)
            savefstr = JSON.stringify(formobj)
          } else {
            savefstr = JSON.stringify(this.formstr)
          }
          saveWFRecord({
            formautoid: this.formautoid,
            editstate: editstate,
            pcuserinfo: this.userInfo.username, // 用户名称,
            formstr: savefstr,
            logdetail: this.logdetail,
            isexcel: '0',
            isapp:1
          })
            .then(res => {
              const data = res.data[0]
              if (res.iserror === '0' && !data.info) {
                if (this.singleSel) {
                  if (data.insid) {
                    this.$emit('setMainDataInsid', data.insid)
                    this.new_acceptid = data.insid
                  }
                  if (editstate === 1) {
                    // 这个会延迟执行！
                    if (from == 'child') {
                      // Toast("已为您自动保存必须保存的数据，请继续相关操作！")
                    } else {
                      // Toast("主表数据保存成功！")
                    }
                    // finishedSaving
                  } else {
                    if (from == 'child') {
                      // Toast("已为您自动修改必须保存的数据，请继续相关操作！")
                    } else {
                      // Toast("主表数据修改成功！")
                    }
                  }
                  const d = {
                    mviewname: this.formConfig.mviewname,
                    mkeyname: this.formConfig.mkeyname,
                    formfieldlist: this.formConfig.formfieldlist,
                    autoid: this.new_acceptid
                  }
                  resolve({
                    status: 'success',
                    returnData: {
                      data: d,
                      new_acceptid: this.new_acceptid,
                      bool: false,
                      editstate,
                      formautoid: this.formautoid,
                      formstr: savefstr
                    }
                  })
                }
              } else if (res.iserror === '0' && data.info) {
                if (data.showbox) {
                  Dialog.alert({
                    message: data.info
                  }).then(() => {})
                } else {
                  Notify(data.info)
                }
                this.$emit('cancelLoading')
                resolve({
                  status: 'fail',
                  returnData: null,
                  message:data.info
                })
              } else if (res.iserror === '1') {
                if (data.showbox) {
                  Dialog.alert({
                    message: res.errormsg
                  }).then(() => {})
                } else {
                  Notify(res.errormsg)
                }
                this.$emit('cancelLoading')
                resolve({
                  status: 'fail',
                  returnData: null,
                  message:res.errormsg
                })
              }
            })
            .catch(err => {
              if (data.showbox) {
                Dialog.alert({
                  message: JSON.stringify(err)
                }).then(() => {})
              } else {
                Notify(JSON.stringify(err))
              }
              this.$emit('cancelLoading')
              resolve({
                status: 'fail',
                returnData: null,
                message:err
              })
            })
        }
      })
    },
    // 判断必填字段是否已经填写
    checkmustinput(b4multi) {
      let msg = false
      for (let i = 0; i < this.form.loopData.length; i++) {
        let element = this.form.loopData[i].children
        for (let k = 0; k < element.length; k++) {
          let i = element[k]
          if (i.app_formshow && i.mustinput && (i.formdata === null || i.formdata === '')) {
            // 多语言特殊处理字段
            Notify(i.chnname + ' 必填！')
            msg = true
            return msg
          }
        }
      }
      return msg
    },
    // 判断正则校验是否匹配
    checkReg() {
      let msg = false
      for (let i = 0; i < this.form.loopData.length; i++) {
        let element = this.form.loopData[i].children
        for (let k = 0; k < element.length; k++) {
          let ele = element[k];
          if(ele.app_formshow && ele.formdata){
            if(ele.controltype == 'maskedit'){
              if(ele.maskeditstr == 'Email'){
                var reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                if(!reg.test(ele.formdata)){
                  Notify(ele.chnname+'格式不对！')
                  msg = true
                  return msg
                }
              }else if(ele.maskeditstr == 'Telephone'){
                var reg = /^1\d{10}$/;
                if(!reg.test(ele.formdata)){
                  Notify(ele.chnname+'格式不对！')
                  msg = true
                  return msg
                }
              }else if(ele.maskeditstr == 'IdCardNum'){
                var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if(!reg.test(ele.formdata)){
                  Notify(ele.chnname+'格式不对！')
                  msg = true
                  return msg
                }
              }else if(ele.maskeditstr == 'Int'){
                var reg = /^-?[1-9]\d*$/;
                if(!reg.test(ele.formdata)){
                  Notify(ele.chnname+'格式不对！')
                  msg = true
                  return msg
                }
              }else if(ele.maskeditstr == 'PositiveInt'){
                var reg = /^[1-9]\d*$/;
                if(!reg.test(ele.formdata)){
                  Notify(ele.chnname+'格式不对！')
                  msg = true
                  return msg
                }
              }else if(ele.maskeditstr == 'NegtiveInt'){
                var reg = /^-[1-9]\d*$/;
                if(!reg.test(ele.formdata)){
                  Notify(ele.chnname+'格式不对！')
                  msg = true
                  return msg
                }
              }else if(ele.maskeditstr == 'Float' && ele.maskeditstrNum == '0'){
                var reg = /^(-?\d+)(\.\d+)?$/;
                if(!reg.test(ele.formdata)){
                  Notify(ele.chnname+'格式不对！')
                  msg = true
                  return msg
                }
              }else if(ele.maskeditstr == 'Float' && ele.maskeditstrNum != '0'){
                var reg = list.maskeditstrpattern;
                if(!reg.test(ele.formdata)){
                  Notify(ele.chnname+'格式不对！')
                  msg = true
                  return msg
                }
              }else if(ele.maskeditstr == 'PositiveFloat' && ele.maskeditstrNum == '0'){
                var reg = /^\d+(\.\d+)?$/;
                if(!reg.test(ele.formdata)){
                  Notify(ele.chnname+'格式不对！')
                  msg = true
                  return msg
                }
              }else if(ele.maskeditstr == 'PositiveFloat' && ele.maskeditstrNum != '0'){
                var reg = list.maskeditstrpattern;
                if(!reg.test(ele.formdata)){
                  Notify(ele.chnname+'格式不对！')
                  msg = true
                  return msg
                }
              }else if(ele.maskeditstr == 'NegtiveFloat' && ele.maskeditstrNum == '0'){
                var reg = /^((-\d+(\.\d+)?)|(0+(\.0+)?))$/;
                if(!reg.test(ele.formdata)){
                  Notify(ele.chnname+'格式不对！')
                  msg = true
                  return msg
                }
              }else if(ele.maskeditstr == 'NegtiveFloat' && ele.maskeditstrNum != '0'){
                var reg = list.maskeditstrpattern;
                if(!reg.test(ele.formdata)){
                  Notify(ele.chnname+'格式不对！')
                  msg = true
                  return msg
                }
              }
            }
          }
        }
      }
      return msg
    },
    ask(msg) {
      return new Promise((resolve, reject) => {
        Dialog.confirm({
          title: '系统提示',
          message: msg
        })
          .then(() => {
            this.$emit('cancelLoading')
            resolve(true)
          })
          .catch(() => {
            this.$emit('cancelLoading')
            resolve(false)
          })
      })
    },
    showCuQuery(e, b4multi) {
      alert('暂不支持自定义模态框！')
      return
      this.singleSel = b4multi ? false : true
      this.updateformstr()
      const data = {
        moduleno: this.formautoid,
        username: this.userInfo.username,
        fieldvalue: e.formdata,
        popqueryproc:
          'psys_showcustomquery_wf' + this.formautoid + '_' + e.fieldname,
        formstr: JSON.stringify(this.formstr)
      }
      GetCustomQueryInfo(data).then(res => {
        this.customQueryQueryparaminfo = res.queryparaminfo
        this.customQueryForminfo = res.forminfo[0]
        this.customQueryForminfo.multisel = b4multi
        this.selectFormdata = e
      })
    },
    // 工号自动带出
    changeEmpname (val, list) {
      let listval = list.formdata
      if (
        list.ispoptree && list.poptreename == "emptree" && 
        (list.controltype == "combobox" || list.controltype == "edit")
      ){
        getEmpInfo({
          empcode: listval,
          moduleno: this.$route.query.module || 201,
          username: this.userInfo.username,
        }).then((res) => {
          if (res.data.length === 0){
            //没有查到记录直接使用输入值进行带出
            listval = "";
            this.setValue(list.fieldname, listval);
            this.valuechage(listval, list);
          } else if (res.data.length === 1){
            //只有1笔记录，直接赋值
            listval = res.data[0].empcode;
            this.setValue(list.fieldname, listval);
            this.valuechage(listval, list);
          } else{
            this.selectFormdata = list;
            this.$refs.refEmpDataTable.getEmpData(res.data)
            this.$refs.refEmpDataTable.showEmpDataTablePicker = true;
          }
        })
        .catch((e) => {
          this.valuechage(listval, list);
        });
      } else {
        this.valuechage(listval, list);
      }
    },
    choiceSelect (obj) {
      this.setValue(this.selectFormdata.fieldname, obj[this.selectFormdata.fieldname]);
      this.valuechage(obj[this.selectFormdata.fieldname], this.selectFormdata);
    },
  }
}
</script>
<style lang="less" scoped>
.keep-flow_box_container {
  width: 100%;
  order: 5;
}

.keep-flow_block {
  width: 100%;
  margin-bottom: 25px;
  /deep/.van-radio-group{
    .van-radio{
      margin: 10px 0;
    }
  }
  /deep/.van-checkbox-group{
    .van-checkbox{
      margin: 10px 0;
    }
  }
  .keep-flow_wrapTitBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.42667rem;
    height: 92px;

    background: #fff;

    .h3 {
      color: #333333;
      font-size: 32px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      margin: 0 !important;
      text-align: left;
      font-style: normal;
    }

    .upDownBtn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.keep-flow_container {
  width: 100%;
  background: #fff;

  .contentBox {
    padding: 0;
  }

  .content {
    // border-top: 1px solid #eee;
    // padding: 20px 0;
  }
}

.from-dom-container {
  .from-dom-list {
    border-bottom: 1px solid #f6f6f6;

    /deep/.van-cell {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;

      &.fieldDisabled {
        background: #efefef !important;
        pointer-events: none;
        .van-cell__title,
        .van-cell__value {
          color: #333333 !important;

          .van-field__control {
            color: #333333 !important;
            -webkit-text-fill-color: #333333 !important;
          }
        }
      }
    }

    /deep/.van-cell--required::before {
      font-weight: bold !important;
    }
  }
}

.table_box {
  .table {
    width: 100%;
    border-spacing: 0px;

    tr {
      td:nth-child(1) {
        border-left: 2px solid #dcdfe6;
      }

      th,
      td {
        padding: 6px 10px;
        text-align: center;
        border-bottom: 2px solid #dcdfe6;
        border-right: 2px solid #dcdfe6;

        .comm_box {
          display: flex;
          justify-content: center;

          .boolean_box {
            .van-checkbox {
              justify-content: center;

              /deep/.van-checkbox__icon--checked {
                .van-icon {
                  color: #fff !important;
                  background-color: #1989fa !important;
                  border-color: #1989fa !important;
                }
              }

              /deep/.van-checkbox__icon--disabled .van-icon {
                background-color: #fff;
              }
            }
          }

          .input_box {
            width: 150px;

            .van-cell {
              font-size: 24px;
              border: 2px solid #d2d2d2;
              border-radius: 10px;
              padding: 8px 16px;
            }
          }
        }
      }
    }

    .top {
      position: sticky;
      top: 0;
      font-size: 28px;
      background: #e9f3fd;
      height: 64px;

      th:nth-child(1) {
        border-left: 2px solid #dcdfe6;
      }

      th {
        border-top: 2px solid #dcdfe6;
      }
    }

    .cont {
      height: 64px;
      font-size: 24px;
    }

    .cont:nth-child(2n-1) {
      background: #f5f5f5;
    }

    .nodata {
      td {
        font-size: 28px;
      }
    }
  }

  .isLookupcomboboxActiveBg {
    background: #9cd6ff !important;
  }
}
</style>
