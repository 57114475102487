<template>
  <div style="width: 100%;">
    <div class="table_box" v-if="tableData && tableData.length > 0">
      <div class="contentTable">
        <table ref="table" class="table" v-if="tableData.length > 0">
          <!-- 表头 -->
          <tr class="top">
            <th 
              :class="index_th <= app_fix_col_count?'thTitleIsFixed':''" 
              :style="computedThStyleLeft(item_th, index_th)"
              v-for="(item_th, index_th) in data.tableHeaderData" 
              :key="index_th"
              style="border-right:1px solid #dcdfe6;"
            >
              <div 
                class="title"
                :style="
                  data.tableGridwidth[index_th] ? {
                    width: data.tableGridwidth[index_th] * 2 + 40 + 'px',
                    'font-size':header_fontsize+'px',
                    'color':data.mustinputData[index_th]?'red':'#000'
                  } : 'min-width:90px;font-size:'+header_fontsize+'px'
                "
              >
                {{ item_th.indexOf("UUID99001") != -1?'':item_th.indexOf("UUID99002") != -1?'':item_th }}
              </div>
            </th>
          </tr>
          <!--展示列表数据 -->
          <tr class="cont" v-for="(item_tr, index_tr) in tableData" :key="index_tr"
            @click.stop="changeAuditDataByTd(item_tr)">
            <td 
              :class="item_td.webFixed?'tdTitleIsFixed':''"
              v-for="(item_td, index_td) in item_tr" 
              :key="index_td"
              :style="computedTdStyleLeft(item_td)"
              style="border-right:1px solid #dcdfe6;"
            >
              <div class="comm_box">
                <template v-if="item_td.webFiledNum == 1">
                  <input type="checkbox" v-model="item_td.webContent">
                  <span style="position: relative;top: -1px;margin-left: 1px;">{{ computedTdIndex(index_tr+1) }}</span>
                </template>
                <template v-else-if="item_td.webFiledNum == 2">
                  <div :style="{'font-size':fontsize+'px'}" @click="visitXq(item_tr, index_tr)" class="cellinput" style="color: blue;text-decoration: underline;">{{ item_td.webContent }}</div>
                </template>
                <template v-else-if="typeof item_td.webContent == 'boolean'">
                  <input style="pointer-events: none;" readonly type="checkbox" v-model="item_td.webContent">
                </template>
                <template v-else>
                  <div class="cellinput" :style="{'font-size':fontsize+'px'}">{{ item_td.webContent }}</div>
                </template>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="bottomPage" style="margin-top: 3px;" v-if="b4TableData && b4TableData.length > 0 && pagequery">
      <van-pagination 
        class="empPage" 
        v-model="currentPage" 
        :items-per-page="pageSize"
        :total-items="total"
        :show-page-size="3" 
        force-ellipses 
        @change="changePage" 
      />
    </div>
    <div class="caoZuoAnNiu" style="margin-top: 3px;" v-if="(canCopy || canDelete) && showChilendAdd">
      <van-button 
        @click="copyItem" 
        type="info" 
        size="small" 
        style="margin-right: 5px;" 
        v-if="canCopy"
      >复制</van-button>
      <van-button 
        @click="deleteItem" 
        type="danger" 
        size="small" 
        style="margin-right: 5px;" 
        v-if="
          canDelete
        ">删除</van-button>
    </div>
    <van-dialog 
      class="childInfoDialog" 
      v-model="show" 
      cancel-button-text="关闭" 
      confirm-button-text="保存" 
      show-cancel-button
      :show-confirm-button="data.app_can_save"
      :before-close="beforeClose"
    >
      <childInfo
        v-if="showIt"
        :acceptid="selectObj.acceptid"
        :childIndex="selectIndex"
        :childKey="childKey"
        :macceptid="macceptid"
        :ischeck="ischeck"
        :form="selectObj.form"
        :status="status" 
        :saveormodify="saveormodify" 
        :formautoid="newformautoid"
        :childObj="data"
        :form-config="formConfig"
        :masterformstr="masterformstr"
        :addStatus="addStatus"
        ref="refChildInfo"
        :dt_whencheck_can_delete="dt_whencheck_can_delete"
        :dt_whencheck_can_insert="dt_whencheck_can_insert"
        operatingMode="2"
      >
      </childInfo>
    </van-dialog>
  </div>
</template>
<script>
  import { Toast,Dialog,Notify } from 'vant';
  import childInfo from './childInfo.vue';
  import { paging,createGguid } from '@/utils'
  import {
    getWFFormFieldDetail,
    deleteWorkFlowList
  } from "@api/workFlow";
  export default {
    props: {
      // 基本信息
      formConfig: {
        type: Object,
        default: () => { },
      },
      data: {
        type: Object,
        default: () => { },
      },
      // 当前的子表主索引
      childKey: {
        type: [String, Number],
        default: 0,
      },
      // 主表autoid
      macceptid: {
        type: [Number,String],
        default: 0,
      },
      ischeck: {
        type: [String, Boolean],
        default: false,
      },
      // 表单状态（save:新增,modify:修改,readonly:只读）
      status: {
        type: String,
        default: "save",
      },
      // 记录状态（save:新增,modify:修改,readonly:只读  优先度高于status）
      saveormodify: {
        type: String,
        default: "save",
      },
      masterformstr: {
        type: [String, Number],
        default: "",
      },
      dt_whencheck_can_delete: {
        type: Boolean,
        default: false,
      },
      dt_whencheck_can_insert: {
        type: Boolean,
        default: false,
      },
      // 模块号
      formautoid: {
        type: [String, Number],
        default: "",
      },
      // 点击新增按钮的时候变成true
      addStatus: {
        type: Boolean,
        default: false,
      },
      nodeid: {
        type: [String, Number],
        default: "",
      },
      showChilendAdd: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      childInfo
    },
    data() {
      const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo):this.$route.query.formoutSize=='1'?{userid:1,username:'admin'}:{}
      
      return {
        userInfo,
        selectObj:{},
        selectIndex:0,
        show:false,
        showIt:false,
        total: 0, // 进度条 总数
        currentPage: 1, // 当前页码
        pageSize: 0, // 每页多少条数据
        bigArray:[],
        tableData:[],
        b4TableData:[],
        newformautoid:this.formautoid,
        pagequery:false,
        b4ChildrenData:[],
        childrenData:[],
        childrenArray:[],
        app_fix_col_count:1,
        headerheight:32,
        datarowheight:32,
        header_fontsize:12,
        fontsize:12
      }
    },
    watch: {
      data: {
        handler(n) {
          console.log(n,'watch data')
          if(n && n.tableData && n.tableData.length > 0){
            this.app_fix_col_count = 1 + n.moduleConfig.app_fix_col_count;
            this.pagequery = n.moduleConfig.pagequery;
            this.pageSize = this.pagequery?n.moduleConfig.pagesize:10000;
            this.b4TableData = JSON.parse(JSON.stringify(n.tableData));
            this.bigArray = paging(this.pageSize, this.b4TableData);
            this.tableData = this.bigArray[this.currentPage - 1];
            this.headerheight = n.moduleConfig.headerheight || 32;
            this.datarowheight = n.moduleConfig.datarowheight*3+2 || 32;
            this.header_fontsize = n.moduleConfig.header_fontsize || 12;
            this.fontsize = n.moduleConfig.fontsize || 12;
            this.b4ChildrenData = JSON.parse(JSON.stringify(n.children));
            this.childrenArray = paging(this.pageSize, this.b4ChildrenData);
            this.childrenData = this.childrenArray[this.currentPage - 1];
            this.total = n.tableData.length;
            this.upDateSelectArr()
          }
          this.$forceUpdate();
        },
        deep: true,
        immediate: true,
      },
      formautoid: {
        handler(n) {
          this.newformautoid = n

        },
        deep: true,
        immediate: true,
      },
    },
    computed: {
      canCopy () {
        var bool = this.data && this.data.moduleConfig &&
          (this.status == 'save' || this.status == 'check' || this.status == 'modify') &&
          (this.status == 'check' ? this.data.moduleConfig.qj_dt_whencheck_can_insert && this.data.moduleConfig.caninsert && this.dt_whencheck_can_insert : this.data.moduleConfig.caninsert)
        return bool
      },
      canDelete () {
        var bool = this.data && this.data.moduleConfig &&
          (this.status == 'save' || this.status == 'check' || this.status == 'modify') &&
          (this.status == 'check' ? this.data.moduleConfig.qj_dt_whencheck_can_delete && this.data.moduleConfig.candelete && this.dt_whencheck_can_delete : this.data.moduleConfig.candelete)
        return bool
      },
      computedThStyleLeft () {
        return (value,index) => {
          // 这里会有个报错，但是不用管它 如果要管 套一层try catch 但是一旦移除这个报错 会导致页面重新计算位置
          if(index <= this.app_fix_col_count){
            let width = 0
            for(let i = 0; i < index; i++){
              // 如果客户反馈 在后面 +4-index+1+i
              width=width + (this.data.tableGridwidth[i]*2 + 40)
            }
            if(index == 0){
              return 'left:'+width+'px;'+'height:'+this.headerheight+'px;border-left:1px solid #dcdfe6;';
            }else{
              return 'left:'+width+'px;'+'height:'+this.headerheight+'px;';
            }
            
          }else{
            return 'height:'+this.headerheight+'px';
          }
        };
      },
      computedTdStyleLeft () {
        // 这里会有个报错，但是不用管它 如果要管 套一层try catch 但是一旦移除这个报错 会导致页面重新计算位置
        return (value) => {
          if(value.webFixed && value.fixedIndex <= this.app_fix_col_count){
            let width = 0
            for(let i = 0; i < value.fixedIndex; i++){
              // 如果客户反馈 在后面 +4-value.fixedIndex+1+i
              width=width + (this.data.tableGridwidth[i]*2 + 40)
            }
            if(value.fixedIndex == 0){
              return 'left:'+width+'px;'+'height:'+this.datarowheight+'px;border-left:1px solid #dcdfe6;';
            }else{
              return 'left:'+width+'px;'+'height:'+this.datarowheight+'px;';
            }
            
          }else{
            return 'height:'+this.datarowheight+'px';
          }
        };
      },
      computedTdIndex () {
        return (value) => {
          if(value < 10){
            return `0${value}`;
          }else{
            return value;
          }
        }
      }
    },
    methods:{
      checkMustinput () {
        let check = false;
        for(let i = 0; i < this.b4TableData.length;i++){
          const item = this.b4TableData[i];
          const index = i;
          for(let key in item){
            const data = item[key];
            if(data.mustinput){
              if(data.webContent !== 0 && data.webContent !== false && !data.webContent){
                check = true;
                Notify("子表"+this.data.formname+"的第"+(index+1)+"行的"+data.chnname+"必填")
                return check;
              }
            }
          }
        }
        return check;
      },
      // 点击表格行
      changeAuditDataByTd (val) {
        // this.data.tableData.forEach(element => {
        //   element.radioSelect.select = false
        // });
        // val.radioSelect.select = true;
        // this.$forceUpdate();
        // this.selectObj = val;
      },
      copyItem () {
        let arr = this.tableData.filter((item) => {
          return item.radioObj.webContent == true;
        });
        if(arr.length == 0){
          Notify("请选择1条数据");
          return;
        }else if(arr.length > 1){
          Notify("不支持批量复制");
          return;
        }
        let copyIndex = 0;
        for(let i = 0; i < this.tableData.length; i++){
          if(this.tableData[i].radioObj.webContent == true){
            copyIndex = i;
            break;
          }
        }
        this.visitXq({},copyIndex,'copy');
        // this.newformautoid = 0;
      },
      deleteItem () {
        let arr = this.tableData.filter((item) => {
          return item.radioObj.webContent == true;
        });
        if(arr.length == 0){
          Notify("请选择数据");
          return;
        }
        Dialog.confirm({
          title: '系统提示',
          message: '是否确认删除？',
        }).then(() => {
          const id = []
          for(let i = 0; i < this.tableData.length; i++){
            if(this.tableData[i].radioObj.webContent == true){
              id.push(this.childrenData[i].acceptid+'')
            }
          }
          const params = {
            guid: createGguid(), //
            formautoid: this.data.autoid,
            mkeyname: this.data.moduleConfig.mkeyname,
            mtablename: this.data.moduleConfig.mtablename,
            mviewname: this.data.moduleConfig.mviewname,
            isall: false,
            autoidlist: id.join(","), //
            moduleqyfilter: "",
            specfilter: this.data.moduleConfig.specfilter,
            querysql: "",
            username:this.userInfo.username,
            optionfilter: [],
          };
          deleteWorkFlowList(params).then(res=>{
            this.$emit("getChildTableDataFun",{})
            Toast("删除成功")
          })
        }).catch(() => {
                
        });
      },
      upDateSelectArr () {
        this.tableData.forEach(item=>{
          item.radioObj.webContent = false;
        })
        this.$forceUpdate()
      },
      changePage () {
        this.tableData = this.b4TableData.slice(
          (this.currentPage - 1) * this.pageSize,
          this.currentPage * this.pageSize
        )
        this.childrenData = this.b4ChildrenData.slice(
          (this.currentPage - 1) * this.pageSize,
          this.currentPage * this.pageSize
        )
        this.upDateSelectArr()
        this.$forceUpdate()
      },
      visitXq (val,index,addtype = 'add') {
        const loading = Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '数据读取中...',
        });
        this.selectIndex = index
        this.selectObj = this.childrenData[index]
        const config = this.data.baseinfo.forminfo
        const usefieldpri = config[0].usefieldpri;
        const colcount = config[0].colcount;
        const labelwidth = config[0].labelwidth;
        const controlwidth = config[0].controlwidth;
        const mviewname = config[0].mviewname;
        const mkeyname = config[0].mkeyname;
        const formfieldlist = config[0].formfieldlist;
        getWFFormFieldDetail({
          formautoid: this.data.autoid,
          userid: this.userInfo.userid,
          langid: localStorage.getItem('langid') || 0,
          usefieldpri: usefieldpri,
          colcount: colcount,
          labelwidth: labelwidth,
          controlwidth: controlwidth,
          mviewname: mviewname,
          mkeyname: mkeyname,
          formfieldlist: formfieldlist,
          autoid: this.selectObj.acceptid,
          nodeid: this.nodeid,
          isapp:1
        }).then((res) => {
          const fieldvalue = res.fieldvalue;
          fieldvalue.forEach((item,index)=>{
            for(let i in item){
              this.selectObj.form.loopData.forEach((value) => {
                value.children.forEach((it) => {
                  if(i == it.fieldname){
                    it.formdata = item[i];
                  }
                })
              })
            }
          })
          if(addtype == 'copy'){
            this.selectObj.acceptid = 0
          }
          this.$nextTick(()=>{
            this.show = true;
            this.showIt = true;
            this.$forceUpdate();
          })
          loading.clear();
        })
      },
      beforeClose (action, done) {
        if (action == 'confirm') {
          const check = this.$refs.refChildInfo.checkmustinput()
          const checkReg = this.$refs.refChildInfo.checkReg()
          if(check){
            done(false)
            return;
          }
          if(checkReg){
            done(false)
            return;
          }
          this.$refs.refChildInfo.beforeAction().then(res=>{
            if(res.status == 'success'){
              if(res.returnData){
                this.$emit("getChildTableDataFun",res.returnData)
                this.show = false;
                setTimeout(()=>{
                  this.showIt = false;
                },300)
                done()
              }
            }else if(res.status == 'fail'){
              done(false)
            }
          })
        }else{
          this.show = false;
          setTimeout(()=>{
            this.showIt = false;
          },300)
          done()
        }
      }
    }
  }
</script>
<style lang="less" scoped>
.table_box {

  display: flex;
  width: 100%;
  
  .contentTable{
    width: 100%;
    overflow: auto;
    flex: 1;
  }
  .table {
    width: 100%;
    border-spacing: 0px;
  }

  tr {
    td:nth-child(1) {
      border-left: 2px solid #dcdfe6;
    }

    th,
    td {
      // padding: 6px 10px;
      text-align: center;
      border-bottom: 2px solid #dcdfe6;
      border-right: 2px solid #dcdfe6;
      // padding-top: 6px;
      position: relative;
      .comm_box {
        display: flex;
        justify-content: center;
        align-items: center;
        // line-height: 52px;
        overflow: hidden;
        word-break: break-all;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0px);
        width: 100%;
        .cellinput{
          width: 100%;
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
        }
        .boolean_box {
          .van-checkbox {
            justify-content: center;

            /deep/.van-checkbox__icon--checked {
              .van-icon {
                color: #fff !important;
                background-color: #1989fa !important;
                border-color: #1989fa !important;
              }
            }

            /deep/.van-checkbox__icon--disabled .van-icon {
              background-color: #fff;
            }
          }
        }

        .input_box {
          width: 180px;

          .van-cell {
            font-size: 24px;
            border: 2px solid #d2d2d2;
            border-radius: 10px;
            padding: 8px 16px;
          }
        }
      }
    }
  }

  .top {
    position: sticky;
    top: 0;
    font-size: 28px;
    background: #e9f3fd;
    height: 64px;

    th:nth-child(1) {
      border-left: 2px solid #dcdfe6;
    }

    th {
      border-top: 2px solid #dcdfe6;
    }

    .thTitleIsFixed{
      position: sticky !important;
      z-index: 9;
      background: #e9f3fd !important;
    }
    
  }

  .cont {
    height: 64px;
    font-size: 24px;
    .tdTitleIsFixed{
      position: sticky !important;
      z-index: 9;
      background: #fff !important;
    }
  }

  .cont:nth-child(2n-1) {
    background: #f5f5f5;
    .tdTitleIsFixed{
      position: sticky !important;
      z-index: 9;
      background: #f5f5f5 !important;
    }
  }

  .nodata {
    td {
      font-size: 28px;
    }
  }
}
.childInfoDialog{
  height: 97% !important;
  width: 95% !important;
  top: 50% !important;
  /deep/.van-dialog__header{
    display: none !important;
  }
  /deep/.van-dialog__content{
    height: calc(100% - 1.28rem) !important;
    overflow: auto;
  }
}



</style>