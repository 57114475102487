<template>
    <div class="win-empDataTable">
        <van-popup v-model="showBackOutTablePicker" position="bottom">
            <div style="width: 100%;overflow: hidden;padding: 10px;height: 400px;">
                
                <div class="table_box" style="overflow: auto;background: #fff;height: 350px;">
                    <table ref="table" class="table">
                        <tr class="top">
                            <th>
                                <div
                                    style="margin: 0 auto;width: 50px;" 
                                    class="title"
                                >
                                    序号
                                </div>
                            </th>
                            <th v-for="(value, j) in tableHeader" :key="j">
                                <div 
                                    style="margin: 0 auto;" 
                                    class="title" 
                                    :style="
                                        value.gridwidth ? { width: value.gridwidth + 'px' } : 'min-width:90px'
                                    "
                                >
                                    {{ value.label }}
                                </div>
                            </th>
                        </tr>
                        <tr @click="choiceSelect(choice,i)" :class="{'selectActive':choice.selectActive}" class="cont" v-for="(choice, i) in tableData" :key="i">
                            <td>
                                {{ i+1 }}
                            </td>
                            <td v-for="(k, idx) in choice" :key="idx" v-show="idx != 'selectActive'">
                                <input style="pointer-events: none;" readonly type="checkbox" v-model="k.webContent" v-if="typeof k.webContent == 'boolean'">
                                <span v-else>{{ k.webContent }}</span>
                            </td>
                        </tr>
                    </table>
                </div>
                <div style="height: 30px;display: flex;margin-top: 5px;justify-content: end;">
                    <van-button type="default" size="small" @click="showBackOutTablePicker = false">关闭</van-button>
                    <van-button type="info" size="small" style="margin-left: 10px;" @click="selectObjEvent">确定</van-button>
                </div>
            </div>
        </van-popup>
        <van-dialog 
            :before-close="reasonConfirm" 
            v-model="showReasonDia" 
            title="请填写意见" 
            show-cancel-button
            confirm-button-text="确定"
        >
            <van-field
                v-model="reasonMemo"
                rows="3"
                autosize
                required
                label="回退原因"
                type="textarea"
                placeholder="请输入"
            />
        </van-dialog>
    </div>
</template>
<script>
import {
    getWorkFlowDetail,
    getWorkFlowDetailFieldList,
    backToFlowFixNode
} from "@api/workFlow";
import { paging } from '@/utils'
import { Toast, Dialog, Notify } from 'vant'
export default {
    props: {
        app_close_redirect_url: {
            type: String,
            default: "",
        },
    },
    data() {
        const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
        return {
            userInfo,
            showBackOutTablePicker:false,
            tableHeader: [],
            tableData: [],
            toastLoading:null,
            selectObj:null,
            showReasonDia:false,
            reasonMemo:"",
            workFlowDetailResData:[]
        }
    },
    computed: {
        formautoid () {
            return this.$route.query.formautoid
        },
        mautoid () {
            return this.$route.query.mautoid
        },
        nodeid () {
            return this.$route.query.nodeid
        },
    },
    methods:{
        getFieldList () {
            this.toastLoading = Toast.loading({
                duration: 0,
                forbidClick: true,
                message: '请求中...'
            })
            this.tableHeader = []
            this.tableData = []
            getWorkFlowDetailFieldList({
                formautoid: this.formautoid,
                mautoid: this.mautoid,
                username: this.userInfo.username
            }).then(res => {
                let hdata = res.data;
                for (let i = 0; i < hdata.length; i++)
                {
                    if(hdata[i].app_gridshow){
                        let obj = {
                            value: hdata[i].fieldname,
                            label: hdata[i].chnname,
                            gridwidth: hdata[i].gridwidth * 2 + 50,
                            fielddatatype: hdata[i].fielddatatype,
                            show: hdata[i].gridshow == 1
                        }
                        this.tableHeader.push(obj)
                    }
                    
                }
                this.getDate()
            })
        },
        getDate () {
            getWorkFlowDetail({
                formautoid: this.formautoid,
                mautoid: this.mautoid,
                flag:1
            }).then(res => {
                const resData = res.data;
                this.workFlowDetailResData = resData
                resData.forEach(ele=>{
                    
                    let obj = {
                        'selectActive':false
                    };
                    this.tableHeader.forEach(element => {
                        obj[element.value] = {
                            webContent:ele[element.value]
                        };
                    });
                    this.tableData.push(obj);
                })
                this.toastLoading.clear()
            })
        },
        choiceSelect (choice,i) {
            this.tableData.forEach(item=>{
                item.selectActive = false;
            })
            choice.selectActive = true;
            this.selectObj = this.workFlowDetailResData[i];
            this.$forceUpdate();
        },
        selectObjEvent () {
            if(!this.selectObj.nodeautoid){
                Notify('nodeautoid不存在！')
                return;
            }
            if (this.selectObj.nodeautoid != this.nodeid){
                this.showReasonDia = true
            } else {
                Notify('不允许回退至此节点！')
            }
        },
        reasonConfirm (action, done) {
            if(action == 'confirm'){
                if(!this.reasonMemo){
                    Toast("原因不能为空！")
                    done(false);
                    return;
                }
                Dialog.confirm({
                    title: '系统提示',
                    message: '您确定要回退到该节点吗？',
                }).then(() => {
                    backToFlowFixNode({
                        formautoid: this.formautoid,
                        mautoid: this.mautoid,
                        cur_nodeid: this.nodeid,
                        backto_nodeid: this.selectObj.nodeautoid,
                        username: this.userInfo.username,
                        backto_reason: this.reasonMemo
                    }).then(res => {
                        if (res.data[0].info){
                            Toast(res.data[0].info)
                            done(false);
                        } else{
                            this.showReasonDia = false;
                            done();
                            this.reasonMemo = "";
                            if(this.app_close_redirect_url){
                                var u = navigator.userAgent
                                var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
                                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
                                if (isAndroid){
                                    //android终端
                                    window.open(this.app_close_redirect_url)
                                } else if (isiOS){
                                    //ios终端
                                    window.location.href = this.app_close_redirect_url
                                } else {
                                    window.open(this.app_close_redirect_url, '_blank')
                                }
                            }else{
                                this.$emit("retrunPages")
                            }
                        }
                    })
                })
                .catch(() => {
                    this.showReasonDia = false;
                    done();
                });
            }else{
                this.showReasonDia = false;
                done();
            }
        }
    }
}
</script>
<style lang="less" scoped>
.table_box {
    height: 100%;
    overflow: auto;
    .table {
        width: 100%;
        border-spacing: 0px;
        border-left: 2px solid #ccc;
        tr {
            th,
            td {
                padding: 6px 10px;
                text-align: center;
                border-bottom: 2px solid #dcdfe6;
                border-right: 2px solid #dcdfe6;
                .comm_box {
                    display: flex;
                    justify-content: center;
                    .boolean_box {
                        .van-checkbox {
                            justify-content: center;

                            /deep/.van-checkbox__icon--checked {
                                .van-icon {
                                    color: #fff !important;
                                    background-color: #1989fa !important;
                                    border-color: #1989fa !important;
                                }
                            }

                            /deep/.van-checkbox__icon--disabled .van-icon {
                                background-color: #fff;
                            }
                        }
                    }
                    .input_box {
                        width: 150px;
                        .van-cell {
                            font-size: 24px;
                            border: 2px solid #d2d2d2;
                            border-radius: 10px;
                            padding: 8px 16px;
                        }
                    }
                }
            }
        }
        .top {
            position: sticky;
            top: 0;
            font-size: 28px;
            background: #e9f3fd;
            height: 64px;

            th {
                border-top: 2px solid #dcdfe6;
            }
        }
        .cont {
            height: 64px;
            font-size: 24px;
            &.selectActive{
                background: #1989fa;
            }
        }
        .nodata {
            td {
                font-size: 28px;
            }
        }
    }
}
</style>