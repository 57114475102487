<template>
    <div class="work-flow_content" style="position: relative;">
        <!-- 备注 -->
        <NotesInfo ref="refNotesInfo" v-if="formConfig.remark" :remark="formConfig.remark" :form-config="formConfig">
        </NotesInfo>
        <!-- 主表 -->
        <MainInfo 
            ref="mainFrom" 
            :acceptid="acceptid" 
            :ischeck="ischeck" 
            :form="form" 
            :formautoid="formautoid"
            :status="status" 
            :can_change_empcode_field="can_change_empcode_field"
            :saveormodify="new_saveormodify" 
            :form-config="formConfig"
            
        ></MainInfo>
        <img v-show="cx_flag == 1 && showOtherDom" src="@/assets/img/workFlowYcx.png" style="width: 60px;" class="spztClass">
        <img v-show="cx_flag == 2 && showOtherDom" src="@/assets/img/workFlowYfj.png" style="width: 60px;" class="spztClass">
        <img v-show="listischeck && showOtherDom" src="@/assets/img/workFlowYtg.png" style="width: 60px;" class="spztClass">
        <!-- 子表表格形式 -->
        <template
            v-if="show_gridstyle_when_check && documentType != 0"
        >
            <div 
                class="childTableBigContainer" 
                style="background: #fff;margin-bottom: 12px;"
                v-for="(value,key) in childData" 
                :key="key"
            >
                <div>
                    <div class="childTableTitle">
                        <span class="h3">{{ value.formname }}<span v-if="totalcount > 0" style="color:red;">（共{{ totalcount }}笔）</span></span>
                        <div class="upDownBtn">
                            <van-icon size="20" color="#999999" @click="showWrapFun(value,key)" v-show="value.uptools" name="arrow-up" />
                            <van-icon size="20" color="#999999" @click="showWrapFun(value,key)" v-show="!value.uptools" name="arrow-down" />
                        </div>
                    </div>
                    <div class="childTableBeiZhu" v-if="value.remark && value.uptools" v-html="value.remark"></div>
                    <div class="childTableBody" v-show="value.uptools">
                        <childTable
                            :data="value"
                            :form-config="formConfig"
                            :childKey="key"
                            :macceptid="acceptid"
                            :ischeck="ischeck"
                            :status="status" 
                            :saveormodify="new_saveormodify" 
                            :formautoid="value.autoid"
                            :nodeid="nodeid"
                            :masterformstr="JSON.stringify(formstr)"
                            :dt_whencheck_can_insert="dt_whencheck_can_insert"
                            :dt_whencheck_can_delete="dt_whencheck_can_delete"
                            @getChildTableDataFun="getChildData"
                            :showChilendAdd="showChilendAdd"
                            ref="refChildTable"
                        ></childTable>
                    </div>
                    <div 
                        style="padding: 0 0.26667rem;" 
                        v-if="
                            showChilendAdd && showOtherDom && (status == 'save' || status == 'check' || status == 'modify') && 
                            (
                                status == 'check' ? value.moduleConfig.qj_dt_whencheck_can_insert && value.moduleConfig.caninsert && dt_whencheck_can_insert :value.moduleConfig.caninsert
                            )
                        "
                    >
                        <van-button v-show="value.uptools" @click="addRow(value,key)"  type="primary" size="small" block plain>新增</van-button>
                    </div>
                </div>
            </div>
        </template>
        <!-- 子表表单形式 -->
        <template
            v-else
        >
            <div 
                class="childTableBigContainer" 
                style="background: #fff;margin-bottom: 12px;"
                v-for="(value,key) in childData" 
                :key="key"
            >
                <div>
                    <div class="childTableTitle">
                        <span class="h3">{{ value.formname }}</span>
                        <div class="upDownBtn">
                            <van-icon size="20" color="#999999" @click="showWrapFun(value,key)" v-show="value.uptools" name="arrow-up" />
                            <van-icon size="20" color="#999999" @click="showWrapFun(value,key)" v-show="!value.uptools" name="arrow-down" />
                        </div>
                    </div>
                    <div class="childTableBeiZhu" v-if="value.remark && value.uptools" v-html="value.remark"></div>
                    <div 
                        class="childTableBody" 
                        v-for="(item,index) in value.children" 
                        :key="index"
                        :style="value.remark?'padding-top:0':''" 
                        v-show="value.uptools"
                    >
                        <childInfo
                            :acceptid="item.acceptid"
                            :childIndex="index"
                            :childKey="key"
                            :macceptid="acceptid"
                            :ischeck="ischeck" 
                            :form="item.form"
                            :formautoid="value.autoid"
                            :addStatus="item.addStatus"
                            :status="status" 
                            :saveormodify="new_saveormodify" 
                            :form-config="formConfig"
                            :childObj="value"
                            :masterformstr="JSON.stringify(formstr)"
                            :failStatus="item.failStatus"
                            :ref="item.guidRef"
                            operatingMode="1"
                            :dt_whencheck_can_insert="dt_whencheck_can_insert"
                            :dt_whencheck_can_delete="dt_whencheck_can_delete"
                            @deleteItem="deleteItem"
                            @copyItem="copyItem"
                        ></childInfo> 
                    </div>
                    <div 
                        style="padding: 0 0.26667rem;" 
                        v-if="
                            showOtherDom && (status == 'save' || status == 'check' || status == 'modify') && 
                            (
                                status == 'check' ? value.moduleConfig.qj_dt_whencheck_can_insert && value.moduleConfig.caninsert && dt_whencheck_can_insert :value.moduleConfig.caninsert
                            )
                        "
                    >
                        <van-button v-show="value.uptools" @click="addRow(value,key)"  type="primary" size="small" block plain>新增</van-button>
                    </div>
                </div>
            </div>   
        </template>
        <!-- 附件信息 -->
        <AttaInfo
            ref="refAttaInfo"
            v-show="showOtherDom && !forbid_show_atlist && showChilendAdd" 
            :form-config="formConfig" 
            :atfilenamelist="formConfig.atfilenamelist" 
            :atfileinfo="atfileinfo"
            :attfile_fileext_list="attfile_fileext_list"
            :canedit_file_when_newflow="canedit_file_when_newflow" 
            :forbid_upload_file="forbid_upload_file" 
            :forbid_delete_file="forbid_delete_file" 
            :canedit_file_when_checkflow="canedit_file_when_checkflow" 
            :app_can_upload_allfile="app_can_upload_allfile"
            v-if="documentType == 0?canedit_file_when_newflow:true"
            @getFileLen="getFileLen" 
        ></AttaInfo>
        <!-- 链接信息 -->
        <LinkInfo 
            :form="form" 
            :form-config="formConfig" 
            v-show="hasLinkInfoStatus && showOther && showChilendAdd"
            @hasLinkInfo="hasLinkInfo" 
            ref="linkInfoRef"
        ></LinkInfo>
        <!-- 审批流程 -->
        <CheckInfo
            ref="checkInfoRef"
            @showSpJl="showSpJl"
            @lastFlowDetailData="lastFlowDetailData"
            :form-config="formConfig"
            v-show="hasCheckInfoStatus && showOther && documentType != 5 && showOtherDom && showChilendAdd"
        >
        </CheckInfo>
        <!-- 按钮事件 -->
        <div :class="'buttonIs_save'" v-if="showOtherDom && new_saveormodify == 'save' && showChilendAdd">
            <van-button @click="onMnLc" v-if="documentType == 0 && can_forecast_checkuser" type="info" block style="margin-right: 20px;">模拟流程</van-button>
            <van-button type="info" block @click="submitEvent">{{ post_btn_caption }}</van-button>
        </div>
        <!-- 审批 -->
        <div :class="'buttonIs_check'" v-if="showOtherDom && new_saveormodify == 'check'&&!ischeck && showChilendAdd && !hideCheckDocumentBtn">
            <van-button style="margin-right: 7px;border-radius: 10px;" type="primary"  @click="cuiBanEvent">{{ cb_btn_caption }}</van-button>
            <van-button v-if="usezb && canZhuanBan && lastFlowDetailObj" style="margin-right: 7px;border-radius: 10px;" type="primary"  @click="zhuanBanEvent">{{ zb_btn_caption }}</van-button>
            <van-button v-if="!hide_cancel_btn" style="margin-right: 7px;border-radius: 10px;" type="danger" @click="noBtnEvent">{{ reject_btn_caption }}</van-button>
            <van-button style="border-radius: 10px;margin-right: 7px;" type="info" @click="yesBtnEvent">{{ agree_btn_caption }}</van-button>
            <van-button v-if="nodeid != 1 && can_backto_fix_node" style="border-radius: 10px;" type="warning" @click="backToNode">{{ rollback_btn_caption }}</van-button>
        </div>
        <!-- 未提交 -->
        <div :class="'buttonIs_check'" v-if="showOtherDom && documentType == 5 && showChilendAdd">
            <van-button  style="margin-right: 7px;border-radius: 10px;" type="info"  @click="submitEvent">{{ post_btn_caption }}</van-button>
            <van-button v-if="can_delete_whendrop" style="margin-right: 7px;border-radius: 10px;" type="danger"  @click="deleteEvent">{{ delete_btn_caption }}</van-button>
        </div>
        <!-- 撤销 -->
        <div :class="'buttonIs_check'" v-if="showOtherDom && showChilendAdd && documentType == 4 && (can_post_whendrop || can_delete_whendrop)">
            <van-button v-if="can_post_whendrop" style="margin-right: 7px;border-radius: 10px;" type="info"  @click="submitEvent">{{ post_btn_caption }}</van-button>
            <van-button v-if="can_delete_whendrop" style="margin-right: 7px;border-radius: 10px;" type="danger"  @click="deleteEvent">{{ delete_btn_caption }}</van-button>
        </div>
        <!-- 我发起的 -->
        <div :class="'buttonIs_check'" v-if="showOtherDom && showChilendAdd && documentType == 2 && (!m_ischeck || (m_ischeck && candropfinishlist)) && !ischeck && !hideDocumentType2Btn">
            <van-button v-if="!m_ischeck" style="margin-right: 7px;border-radius: 10px;" type="info"  @click="cuiBanEvent">{{ cb_btn_caption }}</van-button>
            <van-button v-if="(!m_ischeck && candroplist) || (m_ischeck && candropfinishlist) || (!m_ischeck && candrop_for_nocheck)" style="margin-right: 7px;border-radius: 10px;" type="danger" @click="cheXiaoEvent">{{ cx_btn_caption }}</van-button>
        </div>
        <!-- 转办 -->
        <van-dialog 
            :before-close="zhuanBanConfirm" 
            v-model="showZhuanBanDialog" 
            title=" " 
            show-cancel-button
        >
            <van-field
                clickable
                readonly
                v-model="zhuanBanUser"
                label="转办人"
                placeholder="请选择"
                @click="selectZhuanBanRen"
            />
            <van-field
                v-model="zhuanBanMessage"
                rows="3"
                autosize
                required
                label="转办原因"
                type="textarea"
                placeholder="请输入原因"
            />
        </van-dialog>
        <!-- 选择人员树弹窗 -->
        <PopTree 
            ref="popTreeNode"
            :whichTree="whichTreeName"
            :module="formConfig.moduleno || formautoid"
            :multiseltree="isMultiselTree"
            @treeSelectValEvent="treeSelectValEvent"
        ></PopTree>
        <!-- 审批意见 -->
        <van-dialog 
            :before-close="checkmemoConfirm" 
            v-model="showCheckmemo" 
            title=" " 
            show-cancel-button
            :confirm-button-text="confirmBtnTextStr"
        >
            <van-field
                v-model="checkmemo"
                rows="3"
                autosize
                required
                label="请输入"
                type="textarea"
                placeholder="原因"
            />
        </van-dialog>
        <!-- 撤销意见 -->
        <van-dialog 
            :before-close="cheXiaoConfirm" 
            v-model="showCheXiaoStatus" 
            title=" " 
            show-cancel-button
            confirm-button-text="撤销"
        >
            <van-field
                v-model="cheXiaoMemo"
                rows="3"
                autosize
                required
                label="请输入"
                type="textarea"
                placeholder="原因"
            />
        </van-dialog>
        <!-- 签名板 -->
        <van-overlay 
            :show="signaturePadDialog"
            @click="signaturePadDialog = false"
            z-index="9"
        >
            <div 
                style="width:100%;height:100%;"
                class="wrapper"
                @click.stop
            >
            <signaturePad 
                @getSpad="getSpad"
                @closeSpad="closeSpad"
                :show="signaturePadDialog"
                :use_last_sign_pic="use_last_user_pic"
                :forbit_edit_last_sign="true"
                :defaultUrl="signUrl"
                :yxcx="true"
                :isShowWindowsignremark="false"
            ></signaturePad>
            </div>
        </van-overlay>
        <!-- 表格模式下 子表数据新增 -->
        <van-dialog 
            class="childInfoDialog" 
            v-model="addShow" 
            cancel-button-text="关闭" 
            confirm-button-text="新增" 
            show-cancel-button
            :before-close="beforeClose"
        >
            <childInfo
                v-if="showAddIt"
                :macceptid="acceptid"
                :ischeck="false"
                :form="addForm"
                :formautoid="addFormautoid"
                :childObj="addChildObj"
                :form-config="formConfig"
                :masterformstr="JSON.stringify(formstr)"
                :addStatus="true"
                ref="refChildInfo"
                operatingMode="2"
            >
            </childInfo>
        </van-dialog>
        <!-- 回退 -->
        <backOut :app_close_redirect_url="app_close_redirect_url" ref="refBackOut" @retrunPages="retrunPages"></backOut>
        <!-- 模拟流程 -->
        <van-dialog 
            class="mnlcDialog" 
            v-model="mnlcShow" 
            cancel-button-text="关闭" 
            show-cancel-button
            :show-confirm-button="false"
            :before-close="beforeMnLcClose"
        >
            <van-steps 
                direction="vertical" 
                :active="mnlcActive" 
                active-icon="success" 
                inactive-icon="more-o"
            >
                <van-step v-for="(value,key) in mnlcData" :key="key">
                    <template #active-icon>
                        <div class="seupActiveIcon">
                            <div>{{ value.empname?value.empname.slice(0,1):'' }}</div>
                            <van-icon v-if="value.isok" color="green" class="isIcon" name="success" />
                            <van-icon v-else color="red" class="isIcon" name="cross" />
                        </div>
                    </template>
                    <template #inactive-icon>
                        <div class="seupActiveIcon">
                            <div>{{ value.empname?value.empname.slice(0,1):'' }}</div>
                            <van-icon v-if="value.isok" color="green" class="isIcon" name="success" />
                            <van-icon v-else color="red" class="isIcon" name="cross" />
                        </div>
                    </template>
                    <div class="seupActiveContent">
                            <div class="seupActiveContentTop">
                                <h3>{{ value.nodeid == 1?'发起申请':'审批人' }}</h3>
                            </div>
                            <div class="seupActiveContentTop" style="margin-top: 2px;">
                                <h3 style="color: #999;font-size: 12px;">{{ value.empname }}（{{ value.empcode }}）</h3>
                                <span>{{ value.nodetitle }}</span>
                            </div>
                            <div class="seupActiveContentBody" style="margin-top: 2px;">
                                <div class="word">{{ value.info }}</div>
                            </div>
                        </div>
                </van-step>
            </van-steps>
        </van-dialog>
    </div>
</template>
<script>
import { Toast,Dialog,Notify } from 'vant';
import PopTree from '@components/PopTree/index.vue'
import signaturePad from '@components/signaturePad'
import {
  getWFFormBaseInfo,
  getWFFormFieldDetail,
  getWFFormRecordData,
  postWorkFlow,
  returnWorkFlow,
  deleteWorkFlow,
  getBeforeActionList,
  processBeforeAction,
  upLoadUserSignPic,
  getUserLastSignPicUrl,
  deleteWorkFlowList,
  pushwxmsg,
  canChangeCheck,
  changeCheckUser,
  cancelWorkFlowList,
  postWorkFlow_Test
} from "@api/workFlow";
import {
  queryGridData,
} from "@api/wx.js";
import { createGguid } from '@/utils'
import NotesInfo from './notesInfo.vue';
import MainInfo from './mainInfo.vue';
import AttaInfo from './AttaInfo.vue';
import childInfo from './childInfo.vue';
import childTable from './childTable.vue';
import LinkInfo from "./LinkInfo.vue";
import CheckInfo from "./CheckInfo.vue";
import backOut from "./backOut.vue";

export default {
    props: {
        
    },
    components: {
        PopTree,
        signaturePad,
        NotesInfo,
        MainInfo,
        AttaInfo,
        childInfo,
        childTable,
        LinkInfo,
        CheckInfo,
        backOut
    },
    data () {
        const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) 
        :this.$route.query.formoutSize=='1'?{userid:1,username:'admin'}// 外部人员打开单据时用到
        : {}
        return {
            userInfo,
            formConfig: {}, // 流配置信息
            attfile_fileext_list:"", // 格式化附件
            childrenTableHeader: [], //子表表头数据
            isactive: "1",
            ischeck: false, //记录是否被审核
            form: {
                loopData: [],
            },
            usefieldpri: false, // 是否检查字段权限 (baseinfo 启用字段权限控制(类型：boolean))
            colcount: "", // 表单1行显示基本字段个数
            colcountheight: "", // 控件行高
            labelwidth: "", // 字段标题宽度
            controlwidth: "", // 字段控件宽度
            mviewname: "", // 视图名称
            mkeyname: "", // 主键字段名
            formfieldlist: "", // 用于保存请求接口参数end
            collectpagename: [],
            FIRSTREQ: true, // 数据首次加载的时候
            loading: false, // 加载状态
            showOtherDom: false,  // 主要是让加载看起来舒服一些
            cansave: true, // 是否允许保存
            childData:[],  // 子表数据
            formstr:{},    // 主表的formstr
            addNewChildKey:0,  // 新增子表的时候触发
            ToastLoading:null, // loading状态 
            hasLinkInfoStatus: false,   // 是否存在链接信息
            hasCheckInfoStatus:false,   // 是否存在审批记录
            showOther:false,       // 主要是让加载看起来舒服一些
            forbid_show_atlist: false,  // 是否显示附件
            atfileinfo:"",       // 附件备注    
            acceptid:this.$route.query.mautoid,  // 主表的主键ID
            status:this.$route.query.saveormodify,
            fileLen:0,
            guid:"",
            usezb:false, // 是否显示转办按钮
            hide_cancel_btn:false, // 是否显示否决按钮
            canZhuanBan:false, // 是否允许转办
            showZhuanBanDialog:false,  // 选择转办人
            zhuanBanUser:"",   // 转办人
            zhuanBanUserId:"",  // 转办人ID
            zhuanBanMessage:"", // 转办原因
            isMultiselTree:false, // 单选树
            whichTreeName:"", // 忽视
            lastFlowDetailObj:null,  // 最后的审批记录
            showCheckmemo:false,   // 审批意见弹出框
            confirmBtnTextStr:"确定",  // 审批意见确定按钮
            checkmemo:"",  // 审批意见
            isYesOrNo:"",  // 同意或否决
            actype:2,     // 2同意 3否决 其他待定
            mustsign:false,  // 审批的时候 是否需要提交签名
            formConfigIspostinfo: false,   // 审批的时候 是否需要提交意见
            signaturePadDialog: false,     // 签名版插件
            use_last_user_pic:false,    // 是否使用上次的签名
            signUrl:"",  // 签名链接
            ToastAddLoading:null, // 添加数据的时候 产生的loading
            can_delete_whendrop:false, // 是否允许删除
            can_post_whendrop:false, // 是否允许提交
            candroplist:false, // 允许本人撤销审批中的单据
            candrop_for_nocheck:false, // 允许本人撤销未完结单据
            candropfinishlist:false, // 允许本人撤销已完结的单据
            cheXiaoMemo:"",
            showCheXiaoStatus:false,
            dt_whencheck_can_insert:false,
            dt_whencheck_can_delete:false,
            forbid_upload_file:false,
            forbid_delete_file:false,
            canedit_file_when_checkflow:false,
            canedit_file_when_newflow:false,
            post_btn_caption:"提交",
            agree_btn_caption:"同意",
            delete_btn_caption:"删除",
            reject_btn_caption:"否决",
            cb_btn_caption:"催办",
            zb_btn_caption:"转办",
            cx_btn_caption:"撤销",
            rollback_btn_caption:"后退",
            show_gridstyle_when_check:false,   // 子表表格模式
            showChilendAdd:false,  // 子表表格模式 - 增加数据
            addShow:false, // 子表表格模式 - 增加数据
            showAddIt:false, // 子表表格模式 - 增加数据
            addForm:{
                loopData:[]
            },
            addFormautoid:0,
            addChildObj:{},
            totalcount:0,        
            can_change_empcode_field:true,   // 主表是否允许修改工号
            cx_flag:0,                  // 单据完成状态
            listischeck:false,         // 单据是否已审批
            can_backto_fix_node:false, // 是否允许后退
            can_forecast_checkuser:false,
            limitSubmit:false,
            mnlcShow:false,  // 模拟流程
            mnlcData:[],     // 模拟流程数据
            mnlcActive: 999,
            mnAutoId:null,   // 模拟流程ID
            cur_check_nodeid:null, // 已审批单据nodeid
            app_can_upload_allfile:false,
            app_close_redirect_url:"",
        }
    },
    mounted () {
        this.$nextTick(()=>{
            this.getFromInfo();
        })
    },
    computed: {
        formname () {
            return this.$route.query.formname
        },
        m_ischeck () {
            const parmsMIscheck = this.$route.query.m_ischeck
            if(typeof parmsMIscheck != 'undefined'){
                if(typeof parmsMIscheck == 'string'){
                    return parmsMIscheck == 'true'
                }else{
                    return parmsMIscheck;
                }
            }else{
                return false;
            }
        },
        formautoid () {
            return this.$route.query.formautoid
        },
        // 如果是0单据审批中，1代表单据已撤销，2单据已否决，3单据已审批
        queryCxFlag () {
            return this.$route.query.queryCxFlag
        },
        // 此值无法更新，如果想要用动态的，使用this.acceptid
        mautoid () {
            return this.$route.query.mautoid
        },
        nodeid () {
            return this.$route.query.nodeid
        },
        hideDocumentType2Btn () {
            if(this.documentType == 2){
                if(this.queryCxFlag == 0){
                    return false;
                }else{
                    return true;
                }
            }else{
                return false
            }
        },
        // 此属性无法更新，如果想要用动态的，使用this.status
        new_saveormodify () {
            return this.$route.query.saveormodify
        },
        // 0是没意义的，一般是新增
        // 1：待我审批
        // 2：我发起的
        // 3：我已审批
        // 4：被撤销的
        // 5：未提交的
        // 6：抄送
        documentType () {
            return this.$route.query.documentType || 0
        },
        hideCheckDocumentBtn () {
            if(this.new_saveormodify == 'check'){
                if(this.cur_check_nodeid != this.nodeid || this.listischeck){
                    return true;
                }else{
                    return false;
                }
            }else{
                return false
            }
        }
    },
    methods: {
        // 更新文件数量
        getFileLen (n) {
            console.log(n,'getFileLen')
            this.fileLen = n;
            this.$refs.mainFrom.fileLen = n;
            // if(this.onHasChildTable()){
            //     this.childData.forEach((item,index)=>{
            //         item.children.forEach((value,key)=>{
            //             this.$refs[value.guidRef][0].fileLen = n;
            //         })
            //     })
            // }
        },
        // 删除子表的记录
        deleteItem (id,key,index) {
            Dialog.confirm({
                title: '系统提示',
                message: '是否确认删除？',
            }).then(() => {
                if(!id || id == '0'){
                    this.childData[key].children.splice(index,1)
                }else{
                    this.guid = createGguid();
                    const bobj = this.childData[key];
                    const obj = bobj.children[index];
                    const params = {
                        guid: this.guid, //
                        formautoid: bobj.autoid,
                        mkeyname: bobj.moduleConfig.mkeyname,
                        mtablename: bobj.moduleConfig.mtablename,
                        mviewname: bobj.moduleConfig.mviewname,
                        isall: false,
                        autoidlist: id, //
                        moduleqyfilter: "",
                        specfilter: bobj.moduleConfig.specfilter,
                        querysql: "",
                        username:this.userInfo.username,
                        optionfilter: [],
                    };
                    deleteWorkFlowList(params).then(res=>{
                        this.childData[key].children.splice(index,1)
                    })
                }
                this.$forceUpdate();
            }).catch(() => {
               
            });
        },
        // 复制子表的记录
        copyItem (id,key,index,loopData) {
            const bobj = this.childData[key];
            const obj = bobj.children[index];
            const guid = createGguid() // 生成新的guid
            let newObj = JSON.parse(JSON.stringify(obj));
            newObj.acceptid = 0;
            newObj.form.loopData = JSON.parse(JSON.stringify(loopData));
            newObj.guidRef = 'ref_'+guid;
            newObj.failStatus = false;
            newObj.addStatus = false;
            bobj.children.push(newObj)
            this.$forceUpdate();
        },
        async onMnLc () {
            this.limitSubmit = true;
            const loading = Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: '请求中...',
            });
            if(this.mnAutoId){
                postWorkFlow_Test({
                    formautoid: this.formautoid,
                    checkmemo: '',
                    autoid: this.mnAutoId,
                    username: this.userInfo.username,
                    flag:1
                }).then((res) => {
                    loading.clear()
                    this.mnlcData = res.data;
                    this.mnlcShow = true;
                }).catch((err) => {
                    loading.clear()
                })
            }else{
                await this.submitEvent("limit").then(res=>{
                    if(typeof res != 'undefined' && res){
                        const resId = res;
                        this.mnAutoId = resId;
                        postWorkFlow_Test({
                            formautoid: this.formautoid,
                            checkmemo: '',
                            autoid: resId,
                            username: this.userInfo.username,
                            flag:1
                        }).then((res) => {
                            loading.clear()
                            this.mnlcData = res.data;
                            this.mnlcShow = true;
                        }).catch((err) => {
                            loading.clear()
                        })
                    }else{
                        loading.clear()
                    }
                });
            }
        },
        beforeMnLcClose (action, done) {
            this.mnlcShow = false;
            done();
        },
        // 提交
        async submitEvent (str) {
            // 如果不是模拟流程的点击，则不限制提交
            if(str != 'limit'){
                this.limitSubmit = false;
                this.mnAutoId = null;
            }
            const check = this.$refs.mainFrom.checkmustinput()
            const checkReg = this.$refs.mainFrom.checkReg()
            if(check){
                return;
            }
            if(checkReg){
                return;
            }
            //  首先检查是否存在子表   
            if(this.onHasChildTable() && !this.limitSubmit && (!this.show_gridstyle_when_check || this.documentType == 0)){
                let zhongzhi = false;
                this.childData.forEach((item,index)=>{
                    item.children.forEach((value,key)=>{
                        const check2 = this.$refs[value.guidRef][0].checkmustinput()
                        const checkReg2 = this.$refs[value.guidRef][0].checkReg()
                        if(check2){
                            zhongzhi = true;
                        }
                        if(checkReg2){
                            zhongzhi = true;
                        }
                    })
                })
                // 如果检查到子表的必填字段没填，则停止
                if(zhongzhi){
                    return;
                }
            }
            // 执行主表的保存 注意核心参数mainSubmit
            if(!this.limitSubmit){
                this.ToastAddLoading = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: '数据添加中...',
                });
            }
            const res = await this.$refs.mainFrom.beforeAction();
            if(res.status == 'success'){
                if(res.returnData){
                    const obj = res.returnData;
                    this.getFormRecordData(
                        obj.data,
                        obj.new_acceptid,
                        obj.bool,
                        obj.editstate,
                        'mainSubmit',
                        obj
                    )
                    return obj.new_acceptid
                }else{
                    return null;
                }
            }else{
                if(!this.limitSubmit){
                    this.ToastAddLoading.clear();
                }
                return null;
            }
        },
        // 删除
        deleteEvent () {
            Dialog.confirm({
                title: '系统提示',
                message: '您是否确定删除？',
            }).then(() => {
                deleteWorkFlow({
                    formautoid: this.formautoid,
                    mtablename: this.formConfig.mtablename,
                    mkeyname: this.mkeyname,
                    autoid: this.acceptid,
                    username: this.userInfo.username,
                }).then((res) => {
                    this.$emit("returnPage")
                })
            }).catch(() => {
                
            });
        },
        
        // 否决
        noBtnEvent () {
            this.isYesOrNo = "no"
            if(this.show_gridstyle_when_check && this.documentType != 0 && this.onHasChildTable()){
                this.checkApprove(3);
            }else{
                const check = this.$refs.mainFrom.checkmustinput()
                const checkReg = this.$refs.mainFrom.checkReg()
                if(check){
                    return;
                }
                if(checkReg){
                    return;
                }
                //  首先检查是否存在子表   
                if(this.onHasChildTable()){
                    let zhongzhi = false;
                    this.childData.forEach((item,index)=>{
                        item.children.forEach((value,key)=>{
                            const check2 = this.$refs[value.guidRef][0].checkmustinput()
                            const checkReg2 = this.$refs[value.guidRef][0].checkReg()
                            if(check2){
                                zhongzhi = true;
                            }
                            if(checkReg2){
                                zhongzhi = true;
                            }
                        })
                    })
                    // 如果检查到子表的必填字段没填，则停止
                    if(zhongzhi){
                        return;
                    }
                }
                // 执行主表的保存 注意核心参数mainSubmit
                this.ToastAddLoading = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: '保存中...',
                });
                let limitStatus = false;
                this.$refs.mainFrom.beforeAction().then(res=>{
                    if(res.status == 'success'){
                        if(this.onHasChildTable()){
                            let zongcs = 0
                            for(let item of this.childData){
                                item.zxcs = 0;
                                if(item.children.length > 0){
                                    for(let value of item.children){
                                        this.$refs[value.guidRef][0].beforeAction().then(res=>{
                                            zongcs++
                                            if(res.status == 'success'){
                                                if(res.returnData){
                                                    item.zxcs++;
                                                    value.failStatus = false;
                                                    const obj = res.returnData;
                                                    value.acceptid = obj.new_acceptid
                                                }
                                            }else if(res.status == 'fail'){
                                                value.failStatus = true;
                                                limitStatus = true;
                                                this.showCheckmemo = false;
                                                Dialog.alert({
                                                    message: "保存失败的数据，已为您高亮显示！失败原因："+res.message.toString()
                                                }).then(() => {
                                            
                                                })
                                            }
                                        })
                                    }
                                }
                            }
                            let nzcs = 0
                            this.childData.forEach((item,index)=>{
                                nzcs = nzcs + item.zxcs
                            })
                            // 代表子表的数据都没问题了，
                            if(zongcs == nzcs && !limitStatus){
                                this.checkApprove(3);
                            }
                            this.ToastAddLoading.clear();
                        }else{
                            if(!limitStatus){
                                this.checkApprove(3);
                            }
                            this.ToastAddLoading.clear();
                        }
                    }else{
                        this.ToastAddLoading.clear();
                    }
                })
            }
        },
        // 同意
        yesBtnEvent () {
            this.isYesOrNo = "yes"
            if(this.show_gridstyle_when_check && this.documentType != 0 && this.onHasChildTable()){
                for(let i = 0; i < this.childData.length; i++){
                    const bool = this.$refs.refChildTable[i].checkMustinput()
                    if(bool){
                        return;
                    }
                }
                this.checkApprove(2);
            }else{
                if(this.usefieldpri){
                    const check = this.$refs.mainFrom.checkmustinput()
                    const checkReg = this.$refs.mainFrom.checkReg()
                    if(check){
                        return;
                    }
                    if(checkReg){
                        return;
                    }
                    //  首先检查是否存在子表   
                    if(this.onHasChildTable()){
                        let zhongzhi = false;
                        this.childData.forEach((item,index)=>{
                            item.children.forEach((value,key)=>{
                                const check2 = this.$refs[value.guidRef][0].checkmustinput()
                                const checkReg2 = this.$refs[value.guidRef][0].checkReg()
                                if(check2){
                                    zhongzhi = true;
                                }
                                if(checkReg2){
                                    zhongzhi = true;
                                }
                            })
                        })
                        // 如果检查到子表的必填字段没填，则停止
                        if(zhongzhi){
                            return;
                        }
                    }
                    // 执行主表的保存 注意核心参数mainSubmit
                    this.ToastAddLoading = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: '保存中...',
                    });
                    let limitStatus = false;
                    this.$refs.mainFrom.beforeAction().then(res=>{
                        if(res.status == 'success'){
                            if(this.onHasChildTable()){
                                let zongcs = 0
                                for(let item of this.childData){
                                    item.zxcs = 0;
                                    if(item.children.length > 0){
                                        for(let value of item.children){
                                            this.$refs[value.guidRef][0].beforeAction().then(res=>{
                                                zongcs++
                                                if(res.status == 'success'){
                                                    if(res.returnData){
                                                        item.zxcs++;
                                                        value.failStatus = false;
                                                        const obj = res.returnData;
                                                        value.acceptid = obj.new_acceptid
                                                    }
                                                }else if(res.status == 'fail'){
                                                    value.failStatus = true;
                                                    limitStatus = true;
                                                    this.showCheckmemo = false;
                                                    Dialog.alert({
                                                        message: "保存失败的数据，已为您高亮显示！失败原因："+res.message.toString()
                                                    }).then(() => {
                                                
                                                    })
                                                }
                                            })
                                        }
                                    }
                                }
                                let nzcs = 0
                                this.childData.forEach((item,index)=>{
                                    nzcs = nzcs + item.zxcs
                                })
                                
                                // 代表子表的数据都没问题了，
                                if(zongcs == nzcs && !limitStatus){
                                    this.checkApprove(2);
                                }
                                this.ToastAddLoading.clear();
                            }else{
                                if(!limitStatus){
                                    this.checkApprove(2);
                                }
                                this.ToastAddLoading.clear();
                            }
                        }else{
                            this.ToastAddLoading.clear();
                        }
                    })
                }else{
                    this.checkApprove(2);
                }
            }
        },
        async submitCheckWorkFlow (obj) {
            const res2 = await getBeforeActionList({
                // 先请求保存接口1，获得acprocname
                actype: 2,
                formautoid: obj.formautoid,
                username: this.userInfo.username
            })
            if (res2.iserror == 0){
                const arr = res2.data;
                let promptdata1 = [];
                if (arr.length > 0){
                    for (const item of arr) {
                        let savefstr = obj.formstr
                        const result = await processBeforeAction({
                            acproname: item.acprocname,
                            editstate: obj.editstate,
                            formautoid: obj.formautoid,
                            username: this.userInfo.username,
                            autoid: obj.new_acceptid,
                            formstr: savefstr, // 表格填写信息
                            isapp:1
                        })
                        if (result.data.length > 0) {
                            promptdata1.push(result.data[0])
                        } else {
                            promptdata1.push({
                                isinfo: true,
                                info: result.errormsg,
                                showbox: false,
                                cansave: false
                            })
                        }
                        if (promptdata1.length > 0){
                            let haserror = false;
                            for (let i = 0; i < promptdata1.length; i++){
                                if (promptdata1[i].isinfo){
                                    if (promptdata1[i].showbox){
                                        if (promptdata1[i].cansave){
                                            const a = await this.ask(promptdata1[i].info);
                                            if (!a){
                                                haserror = true;
                                            }
                                        } else{
                                            haserror = true;
                                            Dialog.alert({
                                                message: promptdata1[i].info,
                                            }).then(() => {});
                                        }
                                    } else{
                                        haserror = true;
                                        Notify(promptdata1[i].info)
                                    }
                                }
                            }
                            if (!haserror){
                                postWorkFlow({
                                    formautoid: obj.formautoid,
                                    checkmemo: '',
                                    autoid: obj.new_acceptid,
                                    editstate: obj.editstate,
                                    username: this.userInfo.username,
                                    formstr: savefstr,
                                    flag:this.documentType == 4?5:4,
                                    isapp:1
                                }).then((res) => {
                                    this.ToastAddLoading.clear();
                                    if(this.$route.query.saveormodify == 'save' && this.$route.query.fromPage == 'commHrQueryPage'){
                                        let queryObj = sessionStorage.getItem('commHrQueryPageSetRouteData')?JSON.parse(sessionStorage.getItem('commHrQueryPageSetRouteData')):{}
                                        this.$router.push({
                                            path: '/commHrQueryPage',
                                            query: queryObj
                                        })
                                    }else{
                                        this.$emit("returnPage")
                                    }
                                })
                            }else{
                                this.ToastAddLoading.clear();
                            }
                        }
                    }
                }else{
                    postWorkFlow({
                        formautoid: obj.formautoid,
                        checkmemo: '',
                        autoid: obj.new_acceptid,
                        editstate: obj.editstate,
                        username: this.userInfo.username,
                        formstr: obj.formstr,
                        flag:this.documentType == 4?5:4,
                        isapp:1
                    }).then((res) => {
                        this.ToastAddLoading.clear();
                        if(this.$route.query.saveormodify == 'save' && this.$route.query.fromPage == 'commHrQueryPage'){
                            let queryObj = sessionStorage.getItem('commHrQueryPageSetRouteData')?JSON.parse(sessionStorage.getItem('commHrQueryPageSetRouteData')):{}
                            this.$router.push({
                                path: '/commHrQueryPage',
                                query: queryObj
                            })
                        }else{
                            this.$emit("returnPage")
                        }
                    })
                }
            }
        },
        // 审批前检测（否决和同意）
        checkApprove (actype) {
            getBeforeActionList({
                actype: actype,
                formautoid: this.formautoid,
                username: this.userInfo.username,
            }).then(async (res) => {
                if (res.iserror == 0){
                    const arr = res.data;
                    if (arr.length > 0){
                        let promptdata = [];
                        for (const item of arr){
                            const editstate =
                                this.acceptid === "" ||
                                this.acceptid === null ||
                                this.acceptid === "0" ||
                                this.acceptid === 0
                                ? 1
                                : 2;
                            let savefstr = "";
                            if (typeof this.fileLen == 'number'){
                                let obj = {
                                    "#masterfilecount_total": this.fileLen + "",
                                };
                                let formobj = Object.assign(this.formstr, obj);
                                savefstr = JSON.stringify(formobj);
                            } else if (typeof this.fileLen == 'object'){
                                let formobj = Object.assign(this.formstr, this.fileLen);
                                savefstr = JSON.stringify(formobj);
                            } else {
                                savefstr = JSON.stringify(this.formstr);
                            }
                            const result = await processBeforeAction({
                                acproname: item.acprocname,
                                editstate: editstate,
                                formautoid: this.formautoid,
                                username: this.userInfo.username,
                                autoid: this.acceptid,
                                formstr: savefstr, 
                                isapp:1
                            });
                            if (result.data.length > 0)
                            {
                                promptdata.push(result.data[0]);
                            } else
                            {
                                promptdata.push({
                                    isinfo: true,
                                    info: result.errormsg,
                                    showbox: false,
                                    cansave: false,
                                });
                            }
                        }
                        if (promptdata.length > 0){
                            let haserror = false;
                            for (let i = 0; i < promptdata.length; i++){
                                if (promptdata[i].isinfo){
                                    if (promptdata[i].showbox){
                                        if (promptdata[i].cansave){
                                            const a = await this.ask(promptdata[i].info);
                                            if (!a){
                                                haserror = true;
                                                return;
                                            }
                                        } else{
                                            haserror = true;
                                            Dialog.alert({
                                                message: promptdata[i].info,
                                            }).then(() => {});
                                            return;
                                        }
                                    } else{
                                        haserror = true;
                                        Notify(promptdata[i].info)
                                    }
                                }
                            }
                            if (!haserror)
                            {
                                if (actype == 3){
                                    this.actype = 3;
                                    this.checkmemo = this.formConfig.return_memo_default;
                                    this.showCheckmemo = true;
                                    this.confirmBtnTextStr = '否决';
                                } else if (actype == 2)
                                {
                                    this.actype = 2;
                                    this.checkmemo = '同意';
                                    this.showCheckmemo = true;
                                    this.confirmBtnTextStr = '同意';
                                }
                            }
                        } else{
                            if (actype == 3){
                                this.actype = 3;
                                this.checkmemo = this.formConfig.return_memo_default;
                                this.showCheckmemo = true;
                                this.confirmBtnTextStr = '否决';
                            } else if (actype == 2){
                                if (this.status === "modify" || !this.formConfig.ispostinfo){
                                    Dialog.confirm({
                                        title: '系统提示',
                                        message: "您是否同意？"
                                    })
                                    .then(() => {
                                        this.checkmemo = "";
                                        if (this.mustsign){
                                            this.actype = 2;
                                            this.formConfigIspostinfo = true;
                                            this.getUserLastSignPicUrlFun();
                                        } else{
                                            this.actype = 2;
                                            this.checkSubmit();
                                        }
                                    }).catch(() => {
                                        
                                    });
                                } else{
                                    this.actype = 2;
                                    this.checkmemo = '同意';
                                    this.showCheckmemo = true;
                                    this.confirmBtnTextStr = '同意';
                                }
                            }
                        }
                    } else{
                        if (actype == 3){
                            this.actype = 3;
                            this.checkmemo = this.formConfig.return_memo_default;
                            this.showCheckmemo = true;
                            this.confirmBtnTextStr = '否决';
                        } else if (actype == 2){
                            if (this.status === "modify" || !this.formConfig.ispostinfo)
                            {
                                Dialog.confirm({
                                    title: '系统提示',
                                    message: "您是否同意？"
                                }).then(() => {
                                    this.checkmemo = "";
                                    if (this.mustsign){
                                        this.actype = 2;
                                        this.formConfigIspostinfo = true;
                                        this.getUserLastSignPicUrlFun();
                                    } else{
                                        this.actype = 2;
                                        this.checkSubmit();
                                    }
                                }).catch(() => {
                                    
                                });
                            } else
                            {
                                this.actype = 2;
                                this.checkmemo = "同意";
                                this.showCheckmemo = true;
                                this.confirmBtnTextStr = "同意";
                            }
                        }
                    }
                }
            });
        },
        // 同意后提交
        checkSubmit () {
            return new Promise((resolve, reject) => {
                const editstate = this.status === "check" ? 2 : 1;
                postWorkFlow({
                    formautoid: this.formautoid,
                    checkmemo: this.checkmemo,
                    autoid: this.acceptid,
                    editstate: editstate,
                    username: this.userInfo.username,
                    formstr: JSON.stringify(this.formstr),
                    flag:this.documentType == 4?5:4,
                    nodeid:this.nodeid,
                    isapp:1
                }).then((res) => {
                    resolve(res)
                    if(this.app_close_redirect_url){
                        var u = navigator.userAgent
                        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
                        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
                        if (isAndroid){
                            //android终端
                            window.open(this.app_close_redirect_url)
                        } else if (isiOS)
                        {
                            //ios终端
                            window.location.href = this.app_close_redirect_url
                        } else
                        {
                            window.open(this.app_close_redirect_url, '_blank')
                        }
                    }else{
                        this.$emit("returnPage")
                    }
                })
            })
        },
        // 根据是否需要获取上一次的签名，打开签名框
        getUserLastSignPicUrlFun () {
            if(this.use_last_user_pic){
                getUserLastSignPicUrl({
                    formautoid: this.formautoid,
                    mautoid:this.mautoid,
                    nodeid:this.nodeid,
                    userid: this.userInfo.userid,
                    username:this.userInfo.username,
                }).then(res=>{
                    if(res.data && res.data.length > 0){
                        this.signUrl = res.data[0].picurl
                    }
                    this.signaturePadDialog = true;
                })
            }else{
                this.signaturePadDialog = true;
            }
        },
        // 同意和否决意见确定
        checkmemoConfirm (action, done) {
            if(action == 'confirm'){
                if(this.isYesOrNo == 'no'){
                    if(!this.checkmemo){
                        Toast("原因不能为空！")
                        done(false);
                        return;
                    }
                    returnWorkFlow({
                        formautoid: this.formautoid,
                        checkmemo: this.checkmemo,
                        autoid: this.acceptid,
                        editstate: 3,
                        username: this.userInfo.username,
                        formstr: JSON.stringify(this.formstr),
                        flag:4,
                        nodeid:this.nodeid,
                    }).then((res) => {
                        this.showCheckmemo = false;
                        done();
                        if(this.app_close_redirect_url){
                            var u = navigator.userAgent
                            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
                            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
                            if (isAndroid){
                                //android终端
                                window.open(this.app_close_redirect_url)
                            } else if (isiOS)
                            {
                                //ios终端
                                window.location.href = this.app_close_redirect_url
                            } else
                            {
                                window.open(this.app_close_redirect_url, '_blank')
                            }
                        }else{
                            this.$emit("returnPage")
                        }
                    })
                }else{
                    if (this.mustsign)
                    {
                        this.showCheckmemo = false;
                        this.getUserLastSignPicUrlFun();
                        done();
                    }else{
                        this.checkSubmit().then(res=>{
                            this.showCheckmemo = false;
                            done();
                        })
                    }
                    
                }
            }else{
                this.showCheckmemo = false;
                done();
            }
        },
        // 签名版回调
        getSpad (url) {
            upLoadUserSignPic({
                filebase64str: url,
                formautoid: this.formautoid,
                mautoid: this.mautoid,
                nodeid: this.nodeid,
                uploadpath: this.userInfo.formfilepath,
                username: this.userInfo.username,
            }).then(res => {
                // 签名成功，则提交
                this.checkSubmit();
            })
        },
        // 关闭签名板
        closeSpad () {
            this.signaturePadDialog = false
        },
        ask (msg) {
            return new Promise((resolve, reject) => {
                Dialog.confirm({
                    title: '系统提示',
                    message: msg,
                })
                .then(() => {
                    this.$emit("cancelLoading");
                    resolve(true);
                })
                .catch(() => {
                    this.$emit("cancelLoading");
                    resolve(false);
                });
            });
        },
        // 催办
        cuiBanEvent () {
            Dialog.confirm({
                title: '系统提示',
                message: '您确定要催办吗？',
            }).then(() => {
                this.guid = createGguid() // 生成新的guid
                const params = {
                    guid: this.guid,
                    formautoid: this.formautoid,
                    username: this.userInfo.username,
                    isall: false,
                    mviewname: this.formConfig.mviewname,
                    mkeyfield: this.formConfig.mtablename == 'tsys_wf_flowmaster'?'mautoid':this.formConfig.mkeyname,
                    moduleqyfilter: '',
                    specfilter: '',
                    querysql: '',
                    data: [
                        {autoid:this.mautoid}
                    ],
                    optionfilter: []
                }
                pushwxmsg(params).then(res => {
                    // console.log(res.data);
                    if (res.iserror == '0'){
                        Notify({ type: 'success', message: '催办成功！' });
                    }
                })
            })
        },
        // 转办
        zhuanBanEvent () {
            this.zhuanBanUser = "";
            this.zhuanBanUserId = "";
            this.zhuanBanMessage = "";
            this.showZhuanBanDialog = true;
        },
        // 审批记录的最后一条
        lastFlowDetailData (obj) {
            this.lastFlowDetailObj = obj
        },
        // 转办确定
        zhuanBanConfirm (action, done) {
            if(action == 'confirm'){
                if(!this.lastFlowDetailObj){
                    Toast("审批记录不存在！")
                    this.showZhuanBanDialog = false;
                    done();
                    return;
                }
                if(!this.zhuanBanMessage){
                    Toast("转办原因必填！")
                    done(false);
                    return;
                }else{
                    changeCheckUser({
                        dtid: this.lastFlowDetailObj.autoid,
                        empid: this.zhuanBanUserId,
                        username: this.userInfo.username,
                        zb_reason: this.zhuanBanMessage
                    }).then(res => {
                        if (res.data[0].info){
                            Notify(res.data[0].info);
                        } else {
                            Notify({ type: 'success', message: '转办成功！' });
                            this.$emit("returnPage")
                        }
                        this.showZhuanBanDialog = false;
                        done();
                    })
                }
            }else{
                this.showZhuanBanDialog = false;
                done();
            }
        },
        // 转办树
        treeSelectValEvent (data) {
            this.zhuanBanUser = data.empname;
            this.zhuanBanUserId = data.empid;
        },
        // 选择转办人
        selectZhuanBanRen () {
            this.whichTreeName="employee"
            this.$refs.popTreeNode.module = this.formConfig.moduleno || this.formautoid
            this.$refs.popTreeNode.treename = 'emptree'
            this.$refs.popTreeNode.fieldname = ''
            this.$refs.popTreeNode.editstate = 3
            this.$refs.popTreeNode.showEmpTreePop('员工')
        },
        // 是否显示转办
        canChangeCheckFun () {
            canChangeCheck({
                userid: this.userInfo.userid,
                formautoid: this.formautoid,
                mautoid: this.mautoid,
            }).then(res => {
                if (res.data && res.data[0].isadmin == true){
                    this.canZhuanBan = true
                }
            })
        },
        // 撤销单据
        cheXiaoEvent () {
            this.cheXiaoMemo = "撤销"
            this.showCheXiaoStatus = true;
        },
        cheXiaoConfirm (action, done) {
            if(action == 'confirm'){
                this.guid = createGguid() // 生成新的guid
                const params = {
                    guid: this.guid,
                    formautoid: this.formautoid,
                    mkeyname: this.mkeyname,
                    mtablename: this.formConfig.mtablename,
                    mviewname: this.formConfig.mviewname,
                    isall: false,
                    autoidlist:String(this.mautoid),
                    moduleqyfilter: this.formConfig.moduleqyfilter,
                    specfilter: this.formConfig.specfilter,
                    querysql: '',
                    username: this.userInfo.username,
                    checkmemo: this.cheXiaoMemo,
                    optionfilter:[]
                }
                cancelWorkFlowList(params).then(res => {
                    if (res.data.length > 0){
                        Toast(JSON.stringify(res.data))
                    }else{
                        this.showCheXiaoStatus = false;
                        done();
                        this.$emit("returnPage")
                    }
                })
            }else{
                this.showCheXiaoStatus = false;
                done();
            }
        },
        // 显示审批流程
        showSpJl (b){
            this.hasCheckInfoStatus = b;
        },
        // 显示链接信息
        hasLinkInfo (b) {
            this.hasLinkInfoStatus = b;
        },
        showWrapFun (item,key) {
            item.uptools = !item.uptools
            this.$forceUpdate()
        },
        // 获取表单信息
        async getFromInfo () {
            const params = {
                formautoid: this.formautoid,
                mautoid: this.mautoid,
                nodeid: this.nodeid,
                userid: this.userInfo.userid,
                langid: localStorage.getItem('langid') || 0,
                mtag: 0,
                isapp:1
            };
            const res = await getWFFormBaseInfo(params);
            let attfile_fileext_list = res.forminfo[0].attfile_fileext_list
            let wjdhz = "";
            if(attfile_fileext_list.indexOf("jpg") != -1){
                wjdhz+='.jpg,'
            }
            if(attfile_fileext_list.indexOf("png") != -1){
                wjdhz+='.png,'
            }
            if(attfile_fileext_list.indexOf("pdf") != -1){
                wjdhz+='.pdf,'
            }
            if(attfile_fileext_list.indexOf("doc") != -1){
                wjdhz+='.doc,'
            }
            if(attfile_fileext_list.indexOf("docx") != -1){
                wjdhz+='.docx,'
            }
            if(attfile_fileext_list.indexOf("xls") != -1){
                wjdhz+='.xls,'
            }
            if(attfile_fileext_list.indexOf("xlsx") != -1){
                wjdhz+='.xlsx'
            }
            this.attfile_fileext_list = wjdhz;
            this.app_can_upload_allfile = res.forminfo[0].app_can_upload_allfile
            this.$emit("configFromEvent", res,null,null);
            this.initData(res);
            if(this.documentType == 0){
                this.showChilendAdd = true;
            }
        },
        initData (n) {
            this.childrenTableHeader = n.dttableinfo;
            for (let i = 0; i < this.childrenTableHeader.length; i++)
            {
                this.childrenTableHeader[i].active = false;
            }
            
            this.formConfig = n.forminfo[0];
            let remarkReg = /\n/g;
            if (this.formConfig.remark){
                this.formConfig.remark = this.formConfig.remark.replace(
                    remarkReg,
                    "<br/>"
                );
            }
            if (this.formConfig.atfileinfo){
                this.formConfig.atfileinfo = this.formConfig.atfileinfo.replace(
                    remarkReg,
                    "<br/>"
                );
            }
            this.atfileinfo = this.formConfig.atfileinfo
            this.usefieldpri = n.forminfo[0].usefieldpri;
            this.colcount = parseInt(n.forminfo[0].colcount);
            this.labelwidth = parseInt(n.forminfo[0].labelwidth);
            this.controlwidth = parseInt(n.forminfo[0].controlwidth);
            this.collectpagename = n.forminfo[0].collectpagename ? n.forminfo[0].collectpagename.split(',') : [];
            this.mviewname = n.forminfo[0].mviewname;
            this.mkeyname = n.forminfo[0].mkeyname; // 表单键名
            this.formfieldlist = n.forminfo[0].formfieldlist;
            this.forbid_show_atlist = n.forminfo[0].forbid_show_atlist;
            this.candroplist = n.forminfo[0].candroplist;
            this.candropfinishlist = n.forminfo[0].candropfinishlist;
            this.candrop_for_nocheck = n.forminfo[0].candrop_for_nocheck;
            this.forbid_upload_file = this.formConfig.forbid_upload_file;
            this.forbid_delete_file = this.formConfig.forbid_delete_file;
            this.canedit_file_when_checkflow = this.formConfig.canedit_file_when_checkflow;
            this.canedit_file_when_newflow = this.formConfig.canedit_file_when_newflow;
            this.dt_whencheck_can_insert = n.forminfo[0].dt_whencheck_can_insert;
            this.dt_whencheck_can_delete = n.forminfo[0].dt_whencheck_can_delete;
            this.post_btn_caption = n.forminfo[0].post_btn_caption || "提交";
            this.agree_btn_caption = n.forminfo[0].agree_btn_caption || "同意";
            this.delete_btn_caption = n.forminfo[0].delete_btn_caption || "删除";
            this.reject_btn_caption = n.forminfo[0].reject_btn_caption || "否决";
            this.cb_btn_caption = n.forminfo[0].cb_btn_caption || "催办";
            this.zb_btn_caption = n.forminfo[0].zb_btn_caption || "转办";
            this.cx_btn_caption = n.forminfo[0].cx_btn_caption || "撤销";
            this.rollback_btn_caption = n.forminfo[0].rollback_btn_caption || "后退";
            this.show_gridstyle_when_check = this.formConfig.show_gridstyle_when_check;
            this.usezb = this.formConfig.usezb;
            this.hide_cancel_btn = this.formConfig.hide_cancel_btn;
            this.mustsign = this.formConfig.mustsign;
            this.use_last_user_pic = this.formConfig.use_last_user_pic
            this.can_delete_whendrop = this.formConfig.can_delete_whendrop
            this.can_post_whendrop = this.formConfig.can_post_whendrop
            this.can_change_empcode_field = this.formConfig.can_change_empcode_field
            this.cx_flag = this.formConfig.cx_flag
            this.listischeck = this.formConfig.listischeck

            this.can_backto_fix_node = this.formConfig.can_backto_fix_node
            this.can_forecast_checkuser = this.formConfig.can_forecast_checkuser
            this.cur_check_nodeid = this.formConfig.cur_check_nodeid
            this.app_close_redirect_url = this.formConfig.app_close_redirect_url
            this.canChangeCheckFun()
            this.getMainData();
            
        },
        // 根据某个值分组数组对象
        theSameArr (list, str) {
            const map = new Map()
            list.forEach((item, index, arr) => {
                if (!map.has(item[str])) {
                    map.set(
                        item[str],
                        arr.filter(a => a[str] == item[str])
                    )
                }
            })
            return Array.from(map).map(item => [...item[1]])
        },
        // 构造主表
        getMainData () {
            // 请求构造主表
            getWFFormFieldDetail({
                formautoid: this.formautoid,
                userid: this.userInfo.userid,
                langid: localStorage.getItem('langid') || 0,
                usefieldpri: this.usefieldpri,
                colcount: this.colcount,
                labelwidth: this.labelwidth,
                controlwidth: this.controlwidth,
                mviewname: this.mviewname,
                mkeyname: this.mkeyname,
                formfieldlist: this.formfieldlist,
                autoid: this.acceptid,
                nodeid: this.nodeid,
                isapp:1
            }).then((res) => {
                let fieldSettingData = res.fieldlist;
                let newFieldSettingData = [];
                for(let i = 0; i < fieldSettingData.length; i++){
                    fieldSettingData[i].checkgroupData = [];
                    if(fieldSettingData[i].controltype == 'checkgroup' || fieldSettingData[i].controltype == 'radiogroup'){
                        if(fieldSettingData[i].groupdata){
                            fieldSettingData[i].checkgroupData = fieldSettingData[i].groupdata.split(",");
                        }else{
                            fieldSettingData[i].checkgroupData = [];
                        }
                    }else if(fieldSettingData[i].controltype == 'selectcombobox') {
                        if(fieldSettingData[i].groupdata){
                            let arr = fieldSettingData[i].groupdata.split(",");
                            arr.forEach((item) => {
                                fieldSettingData[i].checkgroupData.push({ value: item,key:item });
                            });
                        }else{
                            fieldSettingData[i].checkgroupData = [];
                        }
                    }
                    fieldSettingData[i].formdata = "";
                    if(fieldSettingData[i].fieldname == 'empcode'){
                        fieldSettingData[i].formdata = this.userInfo.empcode;
                    }
                    if(fieldSettingData[i].fieldname == "empname"){
                        fieldSettingData[i].formdata = this.userInfo.empname;
                    }
                    fieldSettingData[i].oldvalue = ""; //记录旧值
                    fieldSettingData[i].chnname = fieldSettingData[i].chnname === null ? "" : fieldSettingData[i].chnname; //保证中文名不是null值
                    fieldSettingData[i].haschange = false;
                    if (fieldSettingData[i].maskeditstr){
                        if (fieldSettingData[i].maskeditstr.indexOf('Float') > -1){
                            const ir = fieldSettingData[i].maskeditstr.split('Float')
                            fieldSettingData[i].maskeditstr = ir[0] + 'Float'
                            fieldSettingData[i].maskeditstrNum = ir[1].split('{')[1].split('}')[0]
                            if (fieldSettingData[i].maskeditstrNum != '0'){
                                // 带位数控制的，要先eval转义正则表达式
                                switch (fieldSettingData[i].maskeditstr){
                                case 'Float':
                                    fieldSettingData[i].maskeditstrpattern = eval('/^\\d+(\\.\\d{1,' + fieldSettingData[i].maskeditstrNum + '})?$/')
                                    break
                                case 'PositiveFloat':
                                    fieldSettingData[i].maskeditstrpattern = eval('/^[1-9]\\d+(\\.\\d{1,' + fieldSettingData[i].maskeditstrNum + '})?$/')
                                    break
                                case 'NegtiveFloat':
                                    fieldSettingData[i].maskeditstrpattern = eval('/^-[1-9]\\d+(\\.\\d{1,' + fieldSettingData[i].maskeditstrNum + '})?$/')
                                    break
                                default:
                                    break
                                }
                            }
                        }
                    } else {
                        fieldSettingData[i].maskeditstr = ''
                    }
                    if(fieldSettingData[i].controltype == 'selectcombobox' || fieldSettingData[i].controltype == 'checkgroup'){
                        fieldSettingData[i].formdata = [];
                    }
                    if(!fieldSettingData[i].app_groupname){
                        // 此名称不要改，这个数值没有意义，视图会隐藏
                        fieldSettingData[i].app_groupname = "UID333";
                    }

                    newFieldSettingData.push(fieldSettingData[i])
                }
                const appFieldGroup = []
                let workFlowGroup = this.theSameArr(newFieldSettingData,"app_groupname");
                for(let i = 0; i < workFlowGroup.length; i++){
                    let workFlowChildGroup = workFlowGroup[i];
                    if(this.$getObject(workFlowChildGroup) == 'Array' && workFlowChildGroup.length > 0){
                        if(!!workFlowChildGroup[0].app_groupname){
                            let obj = {
                                app_groupname:workFlowChildGroup[0].app_groupname,
                                uptools:true,
                                children:workFlowChildGroup,
                            }
                            appFieldGroup.push(obj);
                        }
                    }
                }
                this.form.loopData = JSON.parse(JSON.stringify(appFieldGroup))
                if (this.new_saveormodify != "save" && this.acceptid != 0){
                    // 非新增情况，获取单据的字段信息
                    this.regetformrecorddata();
                }else{
                    this.$nextTick(async () => {
                        // 更新主表的loopData和初始化主表
                        await this.$refs.mainFrom.setcdefaultvalue(this.formautoid);
                        this.FIRSTREQ = true;
                        this.loading = false;
                        this.showOtherDom = true;
                        // 构造子表
                        if(this.childrenTableHeader && this.childrenTableHeader.length > 0){
                            this.getChildData();
                        }
                    });
                    
                }
                this.showOther = true;
            })
        },
        // 构造子表
        async getChildData () {
            for (let obj of this.childrenTableHeader) {
                let autoid = obj.autoid;
                let nodeid = this.nodeid;
                const res = await getWFFormBaseInfo({
                    formautoid: autoid,
                    mautoid: this.acceptid,
                    nodeid: nodeid,
                    userid:this.userInfo.userid,
                    langid: localStorage.getItem('langid') || 0,
                    mtag: 1,
                    isapp:1
                });
                obj.baseinfo = res;
                obj.moduleConfig = res.forminfo[0];
                obj.upDateKeyid = 1;
                obj.uptools = true;
                
                let remarkReg = /\n/g;
                obj.remark = res.forminfo[0].remark.replace(remarkReg, "<br/>");
                let fieldSettingData = res.dtformfieldinfo; 
                let gridFieldData = res.gridfieldinfo; 
                let newFieldSettingData = [];
                for(let i = 0; i < fieldSettingData.length; i++){
                    fieldSettingData[i].checkgroupData = [];
                    if(fieldSettingData[i].controltype == 'checkgroup' || fieldSettingData[i].controltype == 'radiogroup'){
                        if(fieldSettingData[i].groupdata){
                            fieldSettingData[i].checkgroupData = fieldSettingData[i].groupdata.split(",");
                        }else{
                            fieldSettingData[i].checkgroupData = [];
                        }
                    }else if(fieldSettingData[i].controltype == 'selectcombobox') {
                        if(fieldSettingData[i].groupdata){
                            let arr = fieldSettingData[i].groupdata.split(",");
                            arr.forEach((item) => {
                                fieldSettingData[i].checkgroupData.push({ value: item,key:item });
                            });
                        }else{
                            fieldSettingData[i].checkgroupData = [];
                        }
                    }
                    fieldSettingData[i].formdata = "";
                    if(fieldSettingData[i].controltype == 'selectcombobox' || fieldSettingData[i].controltype == 'checkgroup') {
                        fieldSettingData[i].formdata = [];
                    }
                    fieldSettingData[i].oldvalue = ""; //记录旧值
                    fieldSettingData[i].chnname = fieldSettingData[i].chnname === null ? "" : fieldSettingData[i].chnname; //保证中文名不是null值
                    fieldSettingData[i].haschange = false;
                    if (fieldSettingData[i].maskeditstr){
                        if (fieldSettingData[i].maskeditstr.indexOf('Float') > -1){
                            const ir = fieldSettingData[i].maskeditstr.split('Float')
                            fieldSettingData[i].maskeditstr = ir[0] + 'Float'
                            fieldSettingData[i].maskeditstrNum = ir[1].split('{')[1].split('}')[0]
                            if (fieldSettingData[i].maskeditstrNum != '0'){
                                // 带位数控制的，要先eval转义正则表达式
                                switch (fieldSettingData[i].maskeditstr){
                                case 'Float':
                                    fieldSettingData[i].maskeditstrpattern = eval('/^\\d+(\\.\\d{1,' + fieldSettingData[i].maskeditstrNum + '})?$/')
                                    break
                                case 'PositiveFloat':
                                    fieldSettingData[i].maskeditstrpattern = eval('/^[1-9]\\d+(\\.\\d{1,' + fieldSettingData[i].maskeditstrNum + '})?$/')
                                    break
                                case 'NegtiveFloat':
                                    fieldSettingData[i].maskeditstrpattern = eval('/^-[1-9]\\d+(\\.\\d{1,' + fieldSettingData[i].maskeditstrNum + '})?$/')
                                    break
                                default:
                                    break
                                }
                            }
                        }
                    } else {
                        fieldSettingData[i].maskeditstr = ''
                    }
                    if(!fieldSettingData[i].app_groupname){
                        // 此名称不要改，这个数值没有意义，视图会隐藏
                        fieldSettingData[i].app_groupname = "UID333";
                    }
                    newFieldSettingData.push(fieldSettingData[i])
                }
                const appFieldGroup = []
                let workFlowGroup = this.theSameArr(newFieldSettingData,"app_groupname");
                for(let i = 0; i < workFlowGroup.length; i++){
                    let workFlowChildGroup = workFlowGroup[i];
                    if(this.$getObject(workFlowChildGroup) == 'Array' && workFlowChildGroup.length > 0){
                        if(!!workFlowChildGroup[0].app_groupname){
                            let obj = {
                                app_groupname:workFlowChildGroup[0].app_groupname,
                                uptools:true,
                                children:workFlowChildGroup,
                            }
                            appFieldGroup.push(obj);
                        }
                    }
                }
                obj.loopData = appFieldGroup;
                obj.gridFieldData = gridFieldData;
                obj.children = []
            }
            this.childData = JSON.parse(JSON.stringify(this.childrenTableHeader))
            this.$nextTick(()=>{
                if (this.new_saveormodify != "save" && this.acceptid != 0){
                    this.regetChildFormRecordData();
                }else{
                    this.$forceUpdate();
                }
            })
           
        },
        // 增加一行新的空白子表
        async addRow(value,key) {
            this.ToastLoading = Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: '请求中',
            });
            
            this.addNewChildKey = key;
            const check = this.$refs.mainFrom.checkmustinput()
            const checkReg = this.$refs.mainFrom.checkReg()
            if(check){
                this.ToastLoading.clear();
                return;
            }
            if(checkReg){
                this.ToastLoading.clear();
                return;
            }
            await this.$refs.mainFrom.beforeAction("child").then(res=>{
                if(res.status == 'success'){
                    if(res.returnData){
                        const obj = res.returnData;
                        this.getFormRecordData(
                            obj.data,
                            obj.new_acceptid,
                            obj.bool,
                            obj.editstate,
                            'childAdd'
                        )
                    }
                }
                this.ToastLoading.clear();
            });
        },
        // 非新增状态 直接查询主表的记录 就是主表的信息
        regetformrecorddata () {
            // 更新主表
            this.getFormRecordData(
                {
                    mviewname: this.mviewname,
                    mkeyname: this.mkeyname,
                    formfieldlist: this.formfieldlist,
                    autoid: this.acceptid,
                },
                this.acceptid,
                true,
                0,
                'gouzao'
            );
        },
        // 获取子表审批记录
        async regetChildFormRecordData () {
            for (let obj of this.childData) {
                const mformstr = this.formstr;
                const moduleConfig = obj.moduleConfig
                const querysql = moduleConfig.dtkeyfield + " = '" + mformstr[moduleConfig.masterkeyfield] + "'";
                let params = {
                    moduleno: obj.autoid,
                    userid: this.userInfo.userid,
                    username: this.userInfo.username,
                    hasempidfield: moduleConfig.hasempidfield,
                    mviewname: moduleConfig.mviewname,
                    fieldlist: moduleConfig.fieldlist,
                    orderbysql: moduleConfig.openorderby,
                    pagequery: false,
                    pagesize: 15,
                    pageindex: 1,
                    moduleqyfilter: moduleConfig.moduleqyfilter,
                    specfilter: moduleConfig.specfilter,
                    querysql: querysql,
                    fastquerylist: "",
                    iswf:true,
                    optionfilter:[]
                };
                const res = await queryGridData(params);
                const resdata = res.data;
                this.totalcount = res.totalcount || 0
                obj.tableData = [];  // 表格模式下的子表数据
                obj.tableHeaderData = [];  // 表格模式下的子表表头数据
                obj.tableGridwidth = [];  // 表格模式下的子表宽度数据
                obj.mustinputData = [];   // 必填数组 
                let bjcs = 0
                if(this.show_gridstyle_when_check && this.documentType != 0){
                    // 第一列和第二列数据，就是select和详情
                    let arr3 = [
                        "UUID99001",
                        "UUID99002",
                    ];
                    let arr2 = [
                        5,
                        5
                    ];
                    let arr1 = [
                        false,
                        false
                    ];
                    obj.gridFieldData.forEach((it) => {
                        if(it.app_gridshow){
                            arr3.push(it.chnname)
                            arr2.push(it.gridwidth)
                            if(it.mustinput){
                                arr1.push(it.mustinput)
                            }else{
                                arr1.push(false)
                            }
                        }
                    })
                    obj.tableHeaderData = arr3;   // 表格的表头
                    obj.tableGridwidth = arr2;    // 表格的单元格宽度
                    obj.mustinputData = arr1;    // 表格的单元格宽度
                    resdata.forEach((item,index)=>{
                        let obj2 = {
                            radioObj:{
                                webContent:false,
                                webFixed:true,
                                webFiledNum:1,
                                fixedIndex:0,
                                mustinput:false,
                                chnname:""
                            },
                            clickObj:{
                                webContent:"详情",
                                webFixed:true,
                                webFiledNum:2,
                                fixedIndex:1,
                                mustinput:false,
                                chnname:""
                            },
                        };
                        let mautoid = item[moduleConfig.mkeyname];
                        obj.gridFieldData.forEach((it,index2) => {
                            bjcs++
                            if(it.app_gridshow){
                                // webFixed指的是，是否固定列，这里的意思是，判断后台数据是否需要固定列
                                // fixedIndex指的是固定列索引，+2是因为详情和序列占用了2列。
                                // console.log(it,'ititit')
                                obj2[it.fieldname] = {
                                    webContent:item[it.fieldname],
                                    webFixed:(moduleConfig.app_fix_col_count-1)>=index2?true:false,
                                    fixedIndex:(moduleConfig.app_fix_col_count-1)>=index2?(index2+2):null,
                                    mustinput:it.mustinput,
                                    chnname:it.chnname
                                }
                            }
                        })
                        obj.tableData.push(obj2);  //表格数据
                        const guid = createGguid()
                        obj.children.push({
                            form:{
                                loopData:JSON.parse(JSON.stringify(obj.loopData))
                            },
                            guidRef:'ref_'+guid,
                            acceptid:mautoid,  
                            failStatus:false, 
                            addStatus:false
                        })
                    })
                    this.showChilendAdd = true
                }else{
                    resdata.forEach((item,index)=>{
                        let mautoid = 0
                        for(let i in item){
                            if(i == moduleConfig.mkeyname){
                                mautoid = item[i];
                            }
                            obj.loopData.forEach((value) => {
                                value.children.forEach((it) => {
                                    bjcs++
                                    if(i == it.fieldname){
                                        it.formdata = item[i];
                                    }
                                })
                            })   
                        }
                        const guid = createGguid()
                        obj.children.push({
                            form:{
                                loopData:JSON.parse(JSON.stringify(obj.loopData))
                            },
                            guidRef:'ref_'+guid,
                            acceptid:mautoid,  
                            failStatus:false, 
                            addStatus:false
                        })
                    })
                    this.showChilendAdd = true
                }
                console.log('系统遍历次数：',bjcs)
            }
            this.$forceUpdate()
        },
        // 检查子表是否存在
        onHasChildTable () {
            let hasChildTable = false;
            this.childData.forEach((item,index)=>{
                item.children.forEach((value,key)=>{
                    hasChildTable = true;
                })
            })
            return hasChildTable;
        },
        // 回退
        backToNode () {
            this.$refs.refBackOut.getFieldList()
            this.$refs.refBackOut.showBackOutTablePicker = true;
        },
        // 返回上一页
        retrunPages () {
            this.$emit("returnPage")
        },
        // 核心参数laiyuan，获取主表等的记录
        // returnObj是主表的成功对象
        getFormRecordData (data, acceptid, noSaveZb, editstates,laiyuan,returnObj) {
            console.log("getFormRecordData_laiyuan",laiyuan)
            const dataparms = Object.assign(data, { formautoid: this.formautoid })
            getWFFormRecordData(dataparms).then((res) => {
                if(res.data.length == 0){
                    Dialog.alert({
                        message: "该单据对应的业务数据主记录不存在，请确认！",
                    }).then(() => {});
                    return;
                }
                this.showOtherDom = true;
                this.acceptid = this.mkeyname ? res.data[0][this.mkeyname] : res.data[0].autoid;
                const data = res.data[0];
                this.ischeck = data.ischeck;   // 如果返回这个变量是true，所有控件只读
                // 这里改掉了状态
                if (this.new_saveormodify !== "readonly" &&  this.new_saveormodify !== "check")
                {
                    this.status = "modify";
                }
                this.$nextTick(async () => {
                    // 更新主表
                    for (var item in data)
                    {
                        this.$refs.mainFrom.setValue(item, data[item], "old");
                    }
                    this.formstr = this.$refs.mainFrom.updateformstr();
                    // 构造子表
                    if(laiyuan == 'gouzao'){
                        if(this.childrenTableHeader && this.childrenTableHeader.length > 0){
                            this.getChildData();
                        }else{
                            this.showChilendAdd = true
                        }
                    }
                    console.log(this.limitSubmit,'模拟流程，是否限制表单的提交')
                    // 主表提交
                    if(laiyuan == 'mainSubmit' && !this.limitSubmit){
                        // 附件的保存
                        if(this.documentType == 0){
                            if(this.canedit_file_when_newflow && !this.forbid_show_atlist){
                                const text = await this.$refs.refAttaInfo.saveAinfoData(this.acceptid);
                                console.log(text,'texttexttext1')
                                if(text == '失败'){
                                    this.ToastAddLoading.clear();
                                    return;
                                }
                            }
                        }else{
                            if(!this.forbid_show_atlist){
                                const text = await this.$refs.refAttaInfo.saveAinfoData(this.acceptid);
                                console.log(text,'texttexttext2')
                                if(text == '失败'){
                                    this.ToastAddLoading.clear();
                                    return;
                                }
                            }
                        }
                        // 先判断是否存在子表
                        if(!this.onHasChildTable()){
                            // flag：1工作台，2审批单据査询的待处理，3审批单据査询的被撤销，4审批表单的审批中，5审批表单的已撤销
                            this.submitCheckWorkFlow(returnObj)
                        }else{
                            if(this.show_gridstyle_when_check && this.documentType != 0){
                                this.submitCheckWorkFlow(returnObj)
                            }else{
                                let zongcs = 0
                                let limitStatus = false;
                                for(let item of this.childData){
                                    item.zxcs = 0;
                                    if(item.children.length > 0){
                                        for(let value of item.children){
                                            await this.$refs[value.guidRef][0].beforeAction().then(res=>{
                                                zongcs++
                                                if(res.status == 'success'){
                                                    if(res.returnData){
                                                        item.zxcs++;
                                                        value.failStatus = false;
                                                        const obj = res.returnData;
                                                        this.$refs[value.guidRef][0].new_acceptid = obj.new_acceptid
                                                    }
                                                }else if(res.status == 'fail'){
                                                    limitStatus = true;
                                                    value.failStatus = true;
                                                    this.showCheckmemo = false;
                                                    Dialog.alert({
                                                        message: "保存失败的数据，已为您高亮显示！失败原因："+res.message.toString()
                                                    }).then(() => {
                                                
                                                    })
                                                }
                                            })
                                        }
                                    }
                                }
                                let nzcs = 0
                                this.childData.forEach((item,index)=>{
                                    nzcs = nzcs + item.zxcs
                                })
                                // 代表子表的数据都没问题了，
                                if(zongcs == nzcs && !limitStatus){
                                    this.submitCheckWorkFlow(returnObj)
                                }else{
                                    this.ToastAddLoading.clear();
                                    this.$forceUpdate();
                                }
                            }
                        }
                    }
                    // 子表数据添加
                    if(laiyuan == 'childAdd'){
                        if(this.show_gridstyle_when_check && this.documentType != 0){
                            const loopData = JSON.parse(JSON.stringify(this.childData[this.addNewChildKey].loopData))
                            this.addForm.loopData = loopData;
                            this.addFormautoid = this.childData[this.addNewChildKey].autoid
                            this.addChildObj = this.childData[this.addNewChildKey]
                            this.$nextTick(()=>{
                                this.addShow = true;
                                this.showAddIt = true;
                                this.$forceUpdate();
                            })
                        }else{
                            const loopData = JSON.parse(JSON.stringify(this.childData[this.addNewChildKey].loopData))
                            this.childData[this.addNewChildKey].upDateKeyid++
                            const guid = createGguid()
                            this.childData[this.addNewChildKey].children.push({
                                form:{
                                    loopData
                                },
                                guidRef:'ref_'+guid,
                                acceptid:0,  // 新增成功以后，需要更新
                                failStatus:false, // 保存失败后，高亮
                                addStatus:true
                            })
                            this.$nextTick(()=>{
                                this.$forceUpdate();
                                this.ToastLoading.clear();
                            })
                        }
                    }
                    this.cansave = false;
                })
            });
        },
        // 新增
        beforeClose (action, done) {
            if (action == 'confirm') {
                const check = this.$refs.refChildInfo.checkmustinput()
                const checkReg = this.$refs.refChildInfo.checkReg()
                if(check){
                    done(false)
                    return;
                }
                if(checkReg){
                    done(false)
                    return;
                }
                this.$refs.refChildInfo.beforeAction().then(res=>{
                    if(res.status == 'success'){
                    if(res.returnData){
                        this.getChildData();
                        this.addShow = false;
                        setTimeout(()=>{
                            this.showAddIt = false;
                        },300)
                        done()
                    }
                    }else if(res.status == 'fail'){
                        done(false)
                    }
                })
            }else{
                this.addShow = false;
                setTimeout(()=>{
                    this.showAddIt = false;
                },300)
                done()
            }
        }
    }
}
</script>
<style lang="less" scoped>
.childTableTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.42667rem;
    height: 92px;
    // border-left: 6px solid #00b8ff;
    background: #fff;
    
    .h3 {
        color: #333333;
        font-size: 32px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        margin: 0 !important;
        text-align: left;
        font-style: normal;
    }

    .upDownBtn {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.childTableBeiZhu{
    text-align: justify;
    font-size: 0.37333rem;
    color: #374d58;
    border-top: 0.01333rem solid #eee;
    padding: 20px 32px;
}
.childTableBody{
    padding: 20px;
}
.buttonIs_check{
    padding: 0.42667rem;
    display: flex;
    align-items: center;
    background: #fff;
}
.childInfoDialog{
  height: 97% !important;
  width: 95% !important;
  top: 50% !important;
  /deep/.van-dialog__header{
    display: none !important;
  }
  /deep/.van-dialog__content{
    height: calc(100% - 1.28rem) !important;
    overflow: auto;
  }
}
.spztClass{
    position: absolute;
    z-index: 20;
    top: 105px;
    right: 20px;
}
.buttonIs_save{
    display: flex;
    justify-content: space-between;
}
.mnlcDialog{
    height: 90% !important;
    width: 95% !important;
    top: 50% !important;
    /deep/.van-dialog__header{
        display: none !important;
    }
    /deep/.van-dialog__content{
        height: calc(100% - 1.28rem) !important;
        overflow: auto;
        padding: 20px;
    }
    .seupActiveIcon{
        width: 70px;
        height: 70px;
        background: #2B8DF0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        div{
            color: #fff;
            text-align: center;
            font-size: 38px;
        }
        .isIcon{
            position: absolute;
            font-size: 24px;
            right: -0.1rem;
            bottom: -0.1rem;
            border-radius: 24px;
            background: #fff;
        }
    }
    .seupActiveContent{
        padding-left: 25px;
        h3{
            margin: 0;
        }
        .seupActiveContentTop{
            display: flex;
            justify-content: space-between;
            color: #333;
            align-items: center;
            h3{
                font-size: 28px;
                color: #111F2C;
                font-weight: 500;
            }
            span{
                color: #A4A5A6;
                font-size: 24px;
            }
        }
        .seupActiveContentBody{
            .word{
                font-size: 24px;
                color: #333333;
                word-break: break-all;
            }
        }
    }
}
</style>