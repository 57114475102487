<template>
    <div 
        class="keep-flow_block" 
    >
        <div class="keep-flow_wrapTitBox">
            <span class="h3">附件信息</span>
            <div class="upDownBtn">
                <van-icon size="20" color="#999999" @click="showWrapFun" v-if="showWrapStatus" name="arrow-up" />
                <van-icon size="20" color="#999999" @click="showWrapFun" v-else name="arrow-down" />
            </div>
        </div>
        <div class="keep-flow_container" v-show="showWrapStatus">
            <div class="contentBox">
                <div class="content" v-html="atfileinfo"></div>
                <div class="file-list-container">
                    <div class="file-list-1">
                        <div class="file-list-header">
                            <div class="file-list-header-left">
                                <svg-icon style="width: 32px !important; height: 20px !important;" icon-class="spl_pic_fj01" />
                                <span>当前审批流附件</span>
                            </div>
                        </div>
                        <!-- 格式化附件 -->
                        <div v-if="atfilenamelist" style="padding: 8px;">
                            <div class="atFileListBox">
                                <div class="atFileListList" v-for="(value,key) in atFileList" :key="key">
                                    <van-uploader 
                                        v-model="value.list" 
                                        max-count="1" 
                                        :accept="app_can_upload_allfile?'':'image/*,.jpg,.png'" 
                                        :after-read="afterRead" 
                                        :disabled="!canUploadFile"
                                        :deletable="canDelFile"
                                        :before-delete="beforeDelete"
                                        :before-read="beforeRead"
                                        :preview-options="{
                                            className:'windowImagePreviewClassName'
                                        }"
                                    >
                                        <template #preview-cover="{ type,url }">
                                            <div class="preview-cover" v-if="type == 'pdf'">
                                                <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowPdf.png" alt="">
                                            </div>
                                            <div class="preview-cover" v-else-if="type == 'doc'">
                                                <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowDoc.png" alt="">
                                            </div>
                                            <div class="preview-cover" v-else-if="type == 'xls'">
                                                <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowXls.png" alt="">
                                            </div>
                                            <div class="preview-cover" v-else-if="type == 'txt'">
                                                <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowTxt.png" alt="">
                                            </div>
                                            <div class="preview-cover" v-else-if="type == 'default' || type == 'ppt' || type == 'zip' || type == 'video'">
                                                <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowOther.png" alt="">
                                            </div>
                                        </template>
                                    </van-uploader>
                                    <div class="atFileListListName">{{ value.sfilename }}</div>
                                </div>
                            </div>
                        </div>
                        <!-- 非格式化附件 -->
                        <div v-else style="padding: 8px;">
                            <van-uploader 
                                v-model="fileList" 
                                multiple 
                                :accept="app_can_upload_allfile?'':'image/*,.jpg,.png'"
                                :after-read="afterRead" 
                                image-fit="contain"
                                :disabled="!canUploadFile"
                                :before-delete="beforeDelete"
                                :deletable="canDelFile"
                                :before-read="beforeRead"
                                :preview-options="{
                                    className:'windowImagePreviewClassName'
                                }"
                            >
                                <template #preview-cover="{ type,url,sfilename }">
                                    <div class="preview-cover" v-if="type == 'pdf'">
                                        <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowPdf.png" alt="">
                                    </div>
                                    <div class="preview-cover" v-else-if="type == 'doc'">
                                        <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowDoc.png" alt="">
                                    </div>
                                    <div class="preview-cover" v-else-if="type == 'xls'">
                                        <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowXls.png" alt="">
                                    </div>
                                    <div class="preview-cover" v-else-if="type == 'txt'">
                                        <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowTxt.png" alt="">
                                    </div>
                                    <div class="preview-cover" v-else-if="type == 'default' || type == 'ppt' || type == 'zip' || type == 'video'">
                                        <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowOther.png" alt="">
                                    </div>
                                    <div class="preview-cover-footer van-ellipsis" v-if="sfilename && typeof sfilename == 'string'">
                                        <van-notice-bar speed="15" :scrollable="computedScrollable(sfilename)" :text="sfilename" />
                                    </div>
                                </template>
                            </van-uploader>
                        </div>
                    </div>
                    <div class="file-list-1 hideUploadBtn" v-if="fileList2.length > 0">
                        <div class="file-list-header">
                            <div class="file-list-header-left">
                                <svg-icon style="width: 32px !important; height: 20px !important;" icon-class="spl_pic_fj02" />
                                <span>其他审批流关联附件</span>
                            </div>
                        </div>
                        <div style="padding: 8px;">
                            <van-uploader 
                                v-model="fileList2" 
                                multiple 
                                :accept="app_can_upload_allfile?'':'image/*,.jpg,.png'"
                                :after-read="afterRead" 
                                image-fit="contain"
                                :disabled="true"
                                :before-delete="beforeDelete"
                                :deletable="false"
                                :before-read="beforeRead"
                                :preview-options="{
                                    className:'windowImagePreviewClassName'
                                }"
                            >
                                <template #preview-cover="{ type,url,sfilename }">
                                    <div class="preview-cover" v-if="type == 'pdf'">
                                        <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowPdf.png" alt="">
                                    </div>
                                    <div class="preview-cover" v-else-if="type == 'doc'">
                                        <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowDoc.png" alt="">
                                    </div>
                                    <div class="preview-cover" v-else-if="type == 'xls'">
                                        <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowXls.png" alt="">
                                    </div>
                                    <div class="preview-cover" v-else-if="type == 'txt'">
                                        <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowTxt.png" alt="">
                                    </div>
                                    <div class="preview-cover" v-else-if="type == 'default' || type == 'ppt' || type == 'zip' || type == 'video'">
                                        <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowOther.png" alt="">
                                    </div>
                                    <div class="preview-cover-footer van-ellipsis" v-if="sfilename && typeof sfilename == 'string'">
                                        <van-notice-bar speed="15" :scrollable="computedScrollable(sfilename)" :text="sfilename" />
                                    </div>
                                </template>
                            </van-uploader>
                        </div>
                    </div>
                    <div class="file-list-1 hideUploadBtn" v-if="fileList3.length > 0">
                        <div class="file-list-header">
                            <div class="file-list-header-left">
                                <svg-icon style="width: 32px !important; height: 20px !important;" icon-class="spl_pic_fj02" />
                                <span>其他模块关联附件</span>
                            </div>
                        </div>
                        <div style="padding: 8px;">
                            <van-uploader 
                                v-model="fileList3" 
                                multiple 
                                :accept="app_can_upload_allfile?'':'image/*,.jpg,.png'"
                                :after-read="afterRead" 
                                image-fit="contain"
                                :disabled="true"
                                :before-delete="beforeDelete"
                                :deletable="false"
                                :before-read="beforeRead"
                                :preview-options="{
                                    className:'windowImagePreviewClassName'
                                }"
                            >
                                <template #preview-cover="{ type,url,sfilename }">
                                    <div class="preview-cover" v-if="type == 'pdf'">
                                        <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowPdf.png" alt="">
                                    </div>
                                    <div class="preview-cover" v-else-if="type == 'doc'">
                                        <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowDoc.png" alt="">
                                    </div>
                                    <div class="preview-cover" v-else-if="type == 'xls'">
                                        <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowXls.png" alt="">
                                    </div>
                                    <div class="preview-cover" v-else-if="type == 'txt'">
                                        <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowTxt.png" alt="">
                                    </div>
                                    <div class="preview-cover" v-else-if="type == 'default' || type == 'ppt' || type == 'zip' || type == 'video'">
                                        <img @click="visitFile(type,url)" class="iconImg" src="@/assets/img/workFlowOther.png" alt="">
                                    </div>
                                    <div class="preview-cover-footer van-ellipsis" v-if="sfilename && typeof sfilename == 'string'">
                                        <van-notice-bar speed="15" :scrollable="computedScrollable(sfilename)" :text="sfilename" />
                                    </div>
                                </template>
                            </van-uploader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast, Dialog, Notify } from 'vant'

import * as imageConversion from 'image-conversion'
import { upLoadBase64FileFixName,deleteFile } from "@api/wxjx.js";
import { createGguid } from '@/utils'
import {
    getWFAttFileList
} from "@api/workFlow";
export default {
    props: {
        // 基本信息
        formConfig: {
            type: Object,
            default: () => { },
        },
        atfileinfo: {
            type: String,
            default: "",
        },
        atfilenamelist: {
            type: String,
            default: "",
        },
        attfile_fileext_list: {
            type: String,
            default: "",
        },
        canedit_file_when_newflow: {
            type: Boolean,
            default: false
        },
        forbid_upload_file: {
            type: Boolean,
            default: false
        },
        forbid_delete_file: {
            type: Boolean,
            default: false
        },
        canedit_file_when_checkflow: {
            type: Boolean,
            default: false
        },
        app_can_upload_allfile: {
            type: Boolean,
            default: false
        },
        tuPianZhiLiang: {
            type: Number,
            default: 500
        },
    },
    data() {
        const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) 
        :this.$route.query.formoutSize=='1'?{userid:1,username:'admin'}// 外部人员打开单据时用到
        : {}
        return {
            userInfo,
            showWrapStatus: true,
            fileList: [],
            fileList2:[],
            fileList3:[],
            atFileList:[],
            filedLenth:0,
            filedObj:{}
        }
    },
    watch: {
        atfilenamelist (n) {
            this.getappendix();
        }
    },
    created () {
        this.getappendix();
    },
    computed: {
        formname () {
            return this.$route.query.formname
        },
        formautoid () {
            return this.$route.query.formautoid
        },
        mautoid () {
            return this.$route.query.mautoid
        },
        nodeid () {
            return this.$route.query.nodeid
        },
        saveormodify () {
            return this.$route.query.saveormodify
        },
        // 0是没意义的，一般是新增
        // 1：待我审批
        // 2：我发起的
        // 3：我已审批
        // 4：被撤销的
        // 5：未提交的
        // 6：抄送
        documentType () {
            return this.$route.query.documentType || 0
        },
        m_ischeck () {
            const parmsMIscheck = this.$route.query.m_ischeck
            if(typeof parmsMIscheck != 'undefined'){
                if(typeof parmsMIscheck == 'string'){
                    return parmsMIscheck == 'true'
                }else{
                    return parmsMIscheck;
                }
            }else{
                return false;
            }
        },
        canUploadFile () {
            if (this.documentType != 0){
                if(this.canedit_file_when_checkflow){
                    return !this.forbid_upload_file
                }else{
                    return false;
                }
            } else{
                return true;
            }
        },
        canDelFile () {
            if (this.documentType != 0){
                if(this.canedit_file_when_checkflow){
                    return !this.forbid_delete_file;
                }else{
                    return false;
                }
            } else {
                return true;
            }
        },
        computedScrollable () {
            return (str) => {
                if(str && typeof str == 'string'){
                    let strLen = str.length;
                    let count = 0;
                    for (let i = 0; i < str.length; i++) {
                        if (str.charCodeAt(i) >= 0x4e00 && str.charCodeAt(i) <= 0x9fff) {
                            count++;
                        }
                    }
                    let engLen = strLen - count;
                    let allLen = count*2+engLen;
                    if(allLen >= 12){
                        return true;
                    }else{
                        return false;
                    }
                }else{
                    return false;
                }
            }
        }
    },
    methods:{
        async saveAinfoData (autoid) {
            if(!this.atfilenamelist){
                let len = this.fileList.length;
                let lenNum = 0;
                let str = ''
                for(let obj of this.fileList){
                    if(obj.content){
                        let n = obj.file.type.indexOf("/");
                        let fileext = obj.file.type.slice((n + 1));
                        let fileName = obj.file.name;
                        if(fileext=='jpeg') fileext = 'jpg'
                        let params = {
                            filebase64str: obj.content,
                            upflag: 3,
                            moduleno: this.formautoid,
                            autoid: autoid,
                            uploadpath: this.userInfo.formfilepath || 'WorkFlowFile',
                            filename: fileName.substring(0, fileName.lastIndexOf(".")),
                            fileext,
                            username: this.userInfo.username,
                        }
                        const res = await upLoadBase64FileFixName(params)
                        if(res == 'OK'){
                            lenNum++
                        }else{
                            str = str + res
                        }
                    }
                }
                if(len == lenNum){
                    return '成功'
                }else{
                    if (this.documentType != 0){
                        if(str){
                            Dialog.confirm({
                                message: str
                            }).then(() => {})
                        }
                        return '成功'
                    }else{
                        if(str){
                            Dialog.confirm({
                                message: str
                            }).then(() => {})
                        }
                        return '失败'
                    }
                }
            }else{
                let text = '成功'
                let str = ''
                for(let obj of this.atFileList){
                    if(obj.list && obj.list.length > 0){
                        if(obj.list[0].content){
                            let n = obj.list[0].file.type.indexOf("/");
                            let fileext = obj.list[0].file.type.slice((n + 1));
                            if(fileext=='jpeg') fileext = 'jpg'
                            let params = {
                                filebase64str: obj.list[0].content,
                                upflag: 3,
                                moduleno: this.formautoid,
                                autoid: autoid,
                                uploadpath: this.userInfo.formfilepath || 'WorkFlowFile',
                                filename: obj.sfilename,
                                fileext,
                                username: this.userInfo.username,
                            }
                            const res = await upLoadBase64FileFixName(params)
                            if(res != 'OK'){
                                str = str + res
                                text = '失败'
                            }
                        }
                    }
                }
                if(text == '失败'){
                    if (this.documentType != 0){
                        if(str){
                            Dialog.confirm({
                                message: str
                            }).then(() => {})
                        }
                        return "成功"
                    }else{
                        if(str){
                            Dialog.confirm({
                                message: str
                            }).then(() => {})
                        }
                    }
                    
                }
                return text
            }
            
        },
        showWrapFun () {
            this.showWrapStatus = !this.showWrapStatus
        },
        getFileList () {
            if (this.atfilenamelist){
                const newUrl = {}
                if (this.atFileList && this.atFileList.length > 0){
                    for (let i = 0; i < this.atFileList.length; i++)
                    {
                        const obj = this.atFileList[i];
                        if (
                            obj.url && obj.url.indexOf("http") != -1
                        ){
                            newUrl["#masterfilecount_" + obj.sfilename] = 1
                        } else{
                            newUrl["#masterfilecount_" + obj.sfilename] = 0
                        }
                    }
                }
                this.filedObj = newUrl;
                return newUrl;
            } else {
                var index = 0;
                if (this.fileList && this.fileList.length > 0){
                    for (let i = 0; i < this.fileList.length; i++){
                        if ( this.fileList[i].url){
                            index++;
                        }
                    }
                }
                this.filedLenth = index;
                return index;
            }
        },
        beforeRead (file) {
            let _this = this;
            console.log("压缩前",file)
            if(Array.isArray(file)){
                // 一定要异步和for of 否则批量压缩会出错
                return new Promise(async (resolve, reject) => {
                    let arr = [];
                    let arr2 = [];
                    Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: '处理中...'
                    });
                    for(let item of file){
                        if (item.type.indexOf('image') != -1) {
                            await imageConversion.compressAccurately(item, 500).then(res => {
                                res = new File([res], item.name, { type: res.type, lastModified: Date.now() })
                                console.log("多图片上传，压缩后",res)
                                arr.push(res)
                            })
                        }else{
                            arr2.push(item)
                        }
                    }
                    let arr3 = [...arr,...arr2]
                    Toast.clear();
                    resolve(arr3)
                })
            }else{
                if (file.type.indexOf('image') != -1) {
                    Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: '处理中...'
                    });
                    return new Promise((resolve, reject) => {
                        imageConversion.compressAccurately(file, 500).then(res => {
                            res = new File([res], file.name, { type: res.type, lastModified: Date.now() })
                            console.log("单图片上传，压缩后",res)
                            Toast.clear();
                            resolve(res)
                        })
                    })
                }else{
                    return new Promise((resolve, reject) => {
                        resolve(file)
                    })
                }
            }
        },
        
        async afterRead (file) {
            console.log(this.fileList,this.atFileList,'wfwffff')
            if(this.saveormodify != 'save'){
                setTimeout(async ()=>{
                    await this.saveAinfoData(this.mautoid)
                    this.getappendix();
                },100)
            }else{
                this.filedLenth = 0
                if(!this.atfilenamelist){
                    for(let obj of this.fileList){
                        if(obj.content){
                            this.filedLenth++
                        }
                    }
                    this.$emit("getFileLen", this.filedLenth);
                }else{
                    this.filedObj = {};
                    for(let obj of this.atFileList){
                        if(obj.list && obj.list.length > 0){
                            if(obj.list[0].content){ 
                                this.filedObj["#masterfilecount_" + obj.sfilename] = 1
                            }
                        }
                    }
                    this.$emit("getFileLen", this.filedObj);
                }
            }
        },
        beforeDelete (a,b) {
            if(this.saveormodify != 'save'){
                if(a.autoid){
                    Dialog.confirm({
                        message: '您是否确定删除？'
                    }).then(() => {
                        return new Promise((resolve, reject) => {
                            deleteFile({
                                dflag: 3,
                                moduleno: this.formautoid,
                                autoid: this.mautoid,
                                filename: a.sfilename,
                                fileext: a.fileext,
                                downloadpath: this.userInfo.formfilepath || 'WorkFlowFile',
                            }).then((res) => {
                                this.getappendix();
                                resolve(true);
                            }).catch(() => {
                                resolve(false);
                            });
                        });
                    })
                }else{
                    return true 
                }
            }else{
                return true;
            }
            
            
        },
        visitFile (type,url) {
            var u = navigator.userAgent
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
            if(type == 'pdf'){
                let link = decodeURIComponent(url);
                let host = link.slice(0, parseInt(link.indexOf('v8filesvr')))
                let url2 = host + 'pdf/web/viewer.html?file=' + decodeURIComponent(url);
                
                if (isAndroid) {
                  //android终端
                  window.open(url2)
                } else if (isiOS) {
                  //ios终端
                  window.location.href = url2
                } else {
                  window.open(url2,'_blank')
                }
            }else {
                if (isAndroid){
                    window.open(url)
                } else if (isiOS){
                    window.location.href = url
                } else{
                    window.open(url)
                }
            }
        },
        getappendix () {
            if (this.mautoid && this.mautoid != "0"){
                getWFAttFileList({
                    formautoid: this.formautoid,
                    mautoid: this.mautoid,
                    username: this.userInfo.username,
                }).then((res) => {
                    
                    let arr = res.data;
                    let arr1 = [];
                    let arr2 = [];
                    let arr3 = [];
                    if (arr.length > 0){
                        for (let i = 0; i < arr.length; i++)
                        {
                            if (arr[i].sflag == 0){
                                arr1.push(arr[i]);
                            } else if (arr[i].sflag == 1){
                                arr2.push(arr[i]);
                            } else if (arr[i].sflag == 2){
                                arr3.push(arr[i]);
                            }
                        }
                    }
                    // 假如有格式化附件
                    if (this.atfilenamelist){
                        // 第一步先构造格式化附件列表
                        let finallyFile = [];
                        let namelists = this.atfilenamelist.split(",");
                        namelists.forEach((item, id) => {
                            let obj = {
                                idx: id,
                                moduleno: this.formautoid,
                                sfilename: item,
                                list:[]
                            };
                            finallyFile.push(obj);
                        });
                        this.atFileList = finallyFile;

                        let filedlist = arr1;
                        filedlist.forEach((item) => {
                            item.name = item.sfilename + item.fileext;
                            let type = "";
                            const ext = item.fileext
                                ? item.fileext.split(".")[1]
                                : item.fileext.slice(1).toString().toLowerCase(); // 后缀   
                            if ("doc|docx".includes(ext.toLowerCase())){
                                type = "doc";
                            } else if ("xlsx|xls".includes(ext.toLowerCase())){
                                type = "xls";
                            } else if ("ppt|pptx".includes(ext.toLowerCase())){
                                type = "ppt";
                            } else if ("pdf".includes(ext.toLowerCase())){
                                type = "pdf";
                            } else if ("png|jpg|jpeg|emp".includes(ext.toLowerCase())) {
                                type = "pic";
                            } else if ("zip|rar".includes(ext.toLowerCase())) {
                                type = "zip";
                            } else if ("wmv|asf|asx|rm|rmvb|mp4|3gp|mov|m4v".includes(ext.toLowerCase())){
                                type = "video";
                            } else{
                                type = "default";
                            }
                            item.type = type;
                            item.url = item.url + "?params=" + createGguid();
                            item.status = "success"
                        })
                        filedlist.forEach((item) => {
                            this.atFileList.forEach((value,index)=>{
                                if(value.sfilename == item.sfilename){
                                    value.list.push(item)
                                }
                            })
                        })
                    }else{
                        
                        this.fileList = arr1;
                        this.fileList.forEach((item) => {
                            item.name = item.sfilename + item.fileext;
                            let type = "";
                            const ext = item.fileext
                                ? item.fileext.split(".")[1]
                                : item.fileext.slice(1).toString().toLowerCase(); // 后缀
                            if ("doc|docx".includes(ext.toLowerCase())){
                                type = "doc";
                            } else if ("xlsx|xls".includes(ext.toLowerCase())){
                                type = "xls";
                            } else if ("ppt|pptx".includes(ext.toLowerCase())){
                                type = "ppt";
                            } else if ("pdf".includes(ext.toLowerCase())){
                                type = "pdf";
                            } else if ("png|jpg|jpeg|emp".includes(ext.toLowerCase())) {
                                type = "pic";
                            } else if ("zip|rar".includes(ext.toLowerCase())) {
                                type = "zip";
                            } else if ("txt".includes(ext.toLowerCase())) {
                                type = "txt";
                            } else if ("wmv|asf|asx|rm|rmvb|mp4|3gp|mov|m4v".includes(ext.toLowerCase())){
                                type = "video";
                            } else{
                                type = "default";
                            }
                            item.type = type;
                            item.url = item.url + "?params=" + createGguid();
                            item.status = "success"
                        });
                        if (arr2.length > 0){
                            for (let i = 0; i < arr2.length; i++){
                                arr2[i].name = arr2[i].sfilename + arr2[i].fileext;
                            }
                            this.fileList2 = arr2;
                            this.fileList2.forEach((item) => {
                                item.name = item.sfilename + item.fileext;
                                let type = "";
                                const ext = item.fileext
                                    ? item.fileext.split(".")[1]
                                    : item.fileext.slice(1).toString().toLowerCase(); // 后缀
                                if ("doc|docx".includes(ext.toLowerCase())){
                                    type = "doc";
                                } else if ("xlsx|xls".includes(ext.toLowerCase())){
                                    type = "xls";
                                } else if ("ppt|pptx".includes(ext.toLowerCase())){
                                    type = "ppt";
                                } else if ("pdf".includes(ext.toLowerCase())){
                                    type = "pdf";
                                } else if ("png|jpg|jpeg|emp".includes(ext.toLowerCase())) {
                                    type = "pic";
                                } else if ("zip|rar".includes(ext.toLowerCase())) {
                                    type = "zip";
                                } else if ("txt".includes(ext.toLowerCase())) {
                                    type = "txt";
                                } else if ("wmv|asf|asx|rm|rmvb|mp4|3gp|mov|m4v".includes(ext.toLowerCase())){
                                    type = "video";
                                } else{
                                    type = "default";
                                }
                                item.type = type;
                                item.url = item.url + "?params=" + createGguid();
                                item.status = "success"
                            });
                        }
                        if (arr3.length > 0){
                            for (let i = 0; i < arr3.length; i++){
                                arr3[i].name = arr3[i].sfilename + arr3[i].fileext;
                            }
                            this.fileList3 = arr3;
                            this.fileList3.forEach((item) => {
                                item.name = item.sfilename + item.fileext;
                                let type = "";
                                const ext = item.fileext
                                    ? item.fileext.split(".")[1]
                                    : item.fileext.slice(1).toString().toLowerCase(); // 后缀
                                if ("doc|docx".includes(ext.toLowerCase())){
                                    type = "doc";
                                } else if ("xlsx|xls".includes(ext.toLowerCase())){
                                    type = "xls";
                                } else if ("ppt|pptx".includes(ext.toLowerCase())){
                                    type = "ppt";
                                } else if ("pdf".includes(ext.toLowerCase())){
                                    type = "pdf";
                                } else if ("png|jpg|jpeg|emp".includes(ext.toLowerCase())) {
                                    type = "pic";
                                } else if ("zip|rar".includes(ext.toLowerCase())) {
                                    type = "zip";
                                } else if ("txt".includes(ext.toLowerCase())) {
                                    type = "txt";
                                } else if ("wmv|asf|asx|rm|rmvb|mp4|3gp|mov|m4v".includes(ext.toLowerCase())){
                                    type = "video";
                                } else{
                                    type = "default";
                                }
                                item.type = type;
                                item.url = item.url + "?params=" + createGguid();
                                item.status = "success"
                            });
                        }

                    }
                    this.$emit("getFileLen", this.getFileList());
                });
            }else{
                // 假如有格式化附件
                
                if (this.atfilenamelist){
                    let finallyFile = [];
                    // 无返回则手动格式化
                    let namelists = this.atfilenamelist.split(",");
                    namelists.forEach((item, id) => {
                        let obj = {
                            idx: id,
                            moduleno: this.formautoid,
                            sfilename: item,
                            list:[]
                        };
                        finallyFile.push(obj);
                    });
                    
                    this.atFileList = finallyFile;
                }
            }
        }
    }
}
</script>
<style lang="less" scoped>
.keep-flow_block{
    width: 100%;
    margin-bottom: 25px;
    order: 5;
    .file-list-container{
        /deep/.van-uploader__wrapper--disabled{
            opacity: 1 !important;
        }
        .file-list-1 {
            background: #f1f1f1;
        }
        .file-list-header {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #e8edf2;
            span{
                font-size: 24px;
            }
        }

        .file-list-header-left {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
    .keep-flow_wrapTitBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.42667rem;
        height: 92px;
        
        background: #fff;
        .h3 {
            color: #333333;
            font-size: 32px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            margin: 0 !important;
            text-align: left;
            font-style: normal;
        } 
        .upDownBtn{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.keep-flow_container{
    width: 100%;
    background: #fff;
    .contentBox{
        padding: 0 0.42667rem;
        padding-bottom: 20px;
        /deep/.van-uploader__preview{
            background: #f7f8fa !important;
        }
        /deep/.van-uploader__file-name{
            display: none !important;
        }
    }
    .content{
        text-align: justify;
        font-size: 28px;
        color: #374d58;
        border-top: 1px solid #eee;
        padding: 20px 0;
    }
}
.atFileListBox{
    display: flex;
    flex-wrap: wrap;
    .atFileListList{
        margin-right: 10px;
    }
    .atFileListListName{
        width: 100%;
        text-align: center;
        font-size: 28px;
    }
}
.preview-cover{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .iconImg{
        height: 90% !important;
        display: block !important;
    }
}
.preview-cover-footer{
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    color: #fff;
    font-size: 24px;
    background: rgba(0, 0, 0, 0.3);
    text-align: center;
    /deep/.van-notice-bar{
        background: rgba(0, 0, 0, 0.3) !important;
        padding: 0 !important;
        color: #fff !important;
        height: 36px !important;
        line-height: 36px !important;
        font-size: 24px;
    }
    /deep/.van-notice-bar__wrap{
        justify-content: center;
    }
}
.hideUploadBtn{
    /deep/.van-uploader__upload{
        display: none !important;
    }
}
</style>