<template>
    <div>
        <van-popup v-model="showHqPicker" position="bottom">
            <div style="width: 100%;overflow: hidden;padding: 10px;height: 400px;">
                <div class="table_box" style="overflow: auto;background: #fff;height: 350px;">
                    <table ref="table" class="table">
                        <tr class="top">
                            <th class="thTitleIsFixed" style="left: 0;">
                                <div
                                    style="margin: 0 auto;width: 50px;" 
                                    class="title"
                                >
                                    操作
                                </div>
                            </th>
                            <th class="thTitleIsFixed" style="left: 50px;">
                                <div
                                    style="margin: 0 auto;width: 50px;" 
                                    class="title"
                                >
                                    序号
                                </div>
                            </th>
                            <th v-for="(value, j) in tableHeader" :key="j">
                                <div 
                                    style="margin: 0 auto;" 
                                    class="title" 
                                    :style="
                                        value.gridwidth ? { width: value.gridwidth + 'px' } : 'min-width:90px'
                                    "
                                >
                                    {{ value.label }}
                                </div>
                            </th>
                        </tr>
                        <tr :class="{'selectActive':choice.selectActive}" class="cont" v-for="(choice, i) in mcTableData" :key="i">
                            <td class="thTitleIsFixed" style="left: 0;color: blue;background: #fff !important;">
                                <span style="color: blue;text-decoration: underline;cursor: pointer;" @click="zhuanBan(choice)">转办</span>
                            </td>
                            <td class="thTitleIsFixed" style="left: 50px;background: #fff !important;">
                                {{ i+1 }}
                            </td>
                            <td v-for="(k, idx) in choice" :key="idx" v-show="idx != 'selectActive'">
                                <input style="pointer-events: none;" readonly type="checkbox" v-model="k.webContent" v-if="typeof k.webContent == 'boolean'">
                                <span v-else>{{ k.webContent }}</span>
                            </td>
                        </tr>
                    </table>
                </div>
                <div style="height: 30px;display: flex;margin-top: 5px;justify-content: end;">
                    <van-button type="default" size="small" @click="showHqPicker = false">关闭</van-button>
                </div>
            </div>
        </van-popup>
        <van-dialog 
            :before-close="zhuanBanConfirm" 
            v-model="showZhuanBanDialog" 
            title=" " 
            show-cancel-button
        >
            <van-field
                clickable
                readonly
                v-model="zhuanBanUser"
                label="转办人"
                placeholder="请选择"
                @click="selectZhuanBanRen"
            />
            <van-field
                v-model="zhuanBanMessage"
                rows="3"
                autosize
                required
                label="转办原因"
                type="textarea"
                placeholder="请输入原因"
            />
        </van-dialog>
        <!-- 选择人员树弹窗 -->
        <PopTree 
            ref="popTreeNode"
            whichTree="employee"
            :module="formConfig.moduleno || formautoid"
            :multiseltree="false"
            @treeSelectValEvent="treeSelectValEvent"
        ></PopTree>
    </div>
   
</template>
<script>
import { Toast,Notify } from 'vant';
import {
    getMcDetail,
    changeCheckUser
} from '@api/workFlow'
import PopTree from '@components/PopTree/index.vue'
export default {
    props: {
        // 基本信息
        formConfig: {
            type: Object,
            default: () => { },
        }
    },
    components: {
        PopTree
    },
    data() {
        const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) 
        :this.$route.query.formoutSize=='1'?{userid:1,username:'admin'}// 外部人员打开单据时用到
        : {}
        return {
            userInfo,
            tableHeader: [
                {
                    value: 'ischeck',
                    label: '审核',
                    gridwidth: '80',
                    fielddatatype: 'bit',
                    show: true
                },{
                    value: 'isreturn',
                    label: '否决/撤销',
                    gridwidth: '100',
                    fielddatatype: 'bit',
                    show: true
                },{
                    value: 'username',
                    label: '用户名',
                    gridwidth: '100',
                    fielddatatype: 'string',
                    show: true
                },{
                    value: 'empcode',
                    label: '工号',
                    gridwidth: '100',
                    fielddatatype: 'string',
                    show: true
                },{
                    value: 'empname',
                    label: '姓名',
                    gridwidth: '100',
                    fielddatatype: 'string',
                    show: true
                },{
                    value: 'deptname',
                    label: '部门',
                    gridwidth: '200',
                    fielddatatype: 'string',
                    show: true
                },{
                    value: 'staname',
                    label: '岗位',
                    gridwidth: '200',
                    fielddatatype: 'string',
                    show: true
                },{
                    value: 'checkdate',
                    label: '审核日期',
                    gridwidth: '100',
                    fielddatatype: 'string',
                    show: true
                },{
                    value: 'checkmemo',
                    label: '审核意见',
                    gridwidth: '200',
                    fielddatatype: 'string',
                    show: true
                },{
                    value: 'checkstatus',
                    label: '审批',
                    gridwidth: '100',
                    fielddatatype: 'string',
                    show: true
                }
            ],
            mcTableData:[],
            showHqPicker:false,
            showZhuanBanDialog:false,  // 选择转办人
            zhuanBanUser:"",   // 转办人
            zhuanBanUserId:"",  // 转办人ID
            zhuanBanMessage:"", // 转办原因
            lastFlowDetailObj:{}
        }
    },
    computed: {
        formautoid () {
            return this.$route.query.formautoid
        },
        // 0是没意义的，一般是新增
        // 1：待我审批
        // 2：我发起的
        // 3：我已审批
        // 4：被撤销的
        // 5：未提交的
        // 6：抄送
        documentType () {
            return this.$route.query.documentType || 0
        },
    },
    methods:{
        getMcDetailFun (node) {
            this.lastFlowDetailObj = node
            this.showHqPicker = true;
            const loading = Toast.loading({
                duration: 0,
                forbidClick: true,
                message: '请求中...'
            })
            this.mcTableData = []
            getMcDetail({
                mautoid:node.autoid
            }).then(res=>{
                // this.mcTableData = res.data;
                const resData = res.data;
                resData.forEach(ele=>{
                    let obj = {
                        'selectActive':false
                    };
                    this.tableHeader.forEach(element => {
                        obj[element.value] = {
                            webContent:ele[element.value]
                        };
                    });
                    this.mcTableData.push(obj);
                })
                loading.clear();
            })
        },
        zhuanBan (choice) {
            this.zhuanBanUser = "";
            this.zhuanBanUserId = "";
            this.zhuanBanMessage = "";
            this.showZhuanBanDialog = true;
        },
        // 转办确定
        zhuanBanConfirm (action, done) {
            if(action == 'confirm'){
                if(!this.lastFlowDetailObj){
                    Toast("审批记录不存在！")
                    this.showZhuanBanDialog = false;
                    done();
                    return;
                }
                if(!this.zhuanBanMessage){
                    Toast("转办原因必填！")
                    done(false);
                    return;
                }else{
                    changeCheckUser({
                        dtid: this.lastFlowDetailObj.autoid,
                        empid: this.zhuanBanUserId,
                        username: this.userInfo.username,
                        zb_reason: this.zhuanBanMessage
                    }).then(res => {
                        if (res.data[0].info){
                            Notify(res.data[0].info);
                        } else {
                            Notify({ type: 'success', message: '转办成功！' });
                            this.getMcDetailFun(this.lastFlowDetailObj)
                        }
                        this.showZhuanBanDialog = false;
                        done();
                    })
                }
            }else{
                this.showZhuanBanDialog = false;
                done();
            }
        },
        // 转办树
        treeSelectValEvent (data) {
            this.zhuanBanUser = data.empname;
            this.zhuanBanUserId = data.empid;
        },
        // 选择转办人
        selectZhuanBanRen () {
            this.$refs.popTreeNode.module = this.formConfig.moduleno || this.formautoid
            this.$refs.popTreeNode.treename = 'emptree'
            this.$refs.popTreeNode.fieldname = ''
            this.$refs.popTreeNode.editstate = 3
            this.$refs.popTreeNode.showEmpTreePop('员工')
        },
    }
}
</script>
<style lang="less" scoped>
.table_box {
    height: 100%;
    overflow: auto;
    .table {
        width: 100%;
        border-spacing: 0px;
        border-left: 2px solid #ccc;
        tr {
            th,
            td {
                padding: 6px 10px;
                text-align: center;
                border-bottom: 2px solid #dcdfe6;
                border-right: 2px solid #dcdfe6;
                .comm_box {
                    display: flex;
                    justify-content: center;
                    .boolean_box {
                        .van-checkbox {
                            justify-content: center;

                            /deep/.van-checkbox__icon--checked {
                                .van-icon {
                                    color: #fff !important;
                                    background-color: #1989fa !important;
                                    border-color: #1989fa !important;
                                }
                            }

                            /deep/.van-checkbox__icon--disabled .van-icon {
                                background-color: #fff;
                            }
                        }
                    }
                    .input_box {
                        width: 150px;
                        .van-cell {
                            font-size: 24px;
                            border: 2px solid #d2d2d2;
                            border-radius: 10px;
                            padding: 8px 16px;
                        }
                    }
                }
            }
        }
        .top {
            position: sticky;
            top: 0;
            font-size: 28px;
            background: #e9f3fd;
            height: 64px;

            th {
                border-top: 2px solid #dcdfe6;
            }
        }
        .cont {
            height: 64px;
            font-size: 24px;
            &.selectActive{
                background: #1989fa;
            }
        }
        .nodata {
            td {
                font-size: 28px;
            }
        }
    }
}
.thTitleIsFixed{
    position: sticky !important;
    z-index: 9;
    background: #e9f3fd !important;
}
</style>