<template>
  <div class="workFlowContainer">
    <van-nav-bar
      :title="formname"
      left-text
      :left-arrow="!($route.query.formoutSize == '1')"
      @click-left="onClickLeft"
      :fixed="true"
    >
    </van-nav-bar>
    <div class="workFlowIndexBox">
      <BasicInfo @returnPage="onClickLeft"></BasicInfo>
    </div>
  </div>
</template>

<script>
// 主表信息
import BasicInfo from './BasicInfo.vue'
export default {
  name: 'ApprovalFlow',
  // data函数返回一个对象，这个对象的属性会被绑定到模板上
  data() {
    return {}
  },
  components: {
    BasicInfo
  },
  //  methods函数返回一个对象，这个对象的属性和方法会被绑定到模板上
  methods: {
    onClickLeft() {
      const fromPage = this.$route.query.fromPage;
      // commHrQueryPageSetRouteData
      if (fromPage == 'home') {
        this.$router.push('/home').catch(err => {
          console.log(err)
        })
      } else if (fromPage == 'commHrQueryPage') {
        let queryObj = sessionStorage.getItem('commHrQueryPageSetRouteData')
          ? JSON.parse(sessionStorage.getItem('commHrQueryPageSetRouteData'))
          : {}
        this.$router.push({
          path: '/commHrQueryPage',
          query: queryObj
        })
      }else if (this.new_saveormodify == 'save') {
        this.$router.push({
          path: '/apply'
        })
      } else {
        let queryObj = localStorage.getItem('RouterQueryObj')
          ? JSON.parse(localStorage.getItem('RouterQueryObj'))
          : {}
        this.$router.push({
          path: '/wfList',
          query: queryObj
        })
      }
    }
  },
  // computed函数返回一个对象，这个对象的属性会被绑定到模板上
  computed: {
    formname() {
      return this.$route.query.formname
    },
    formautoid() {
      return this.$route.query.formautoid
    },
    mautoid: {
      get: function() {
        return this.$route.query.mautoid
      },
      set: function(newValue) {
        return newValue
      }
    },
    nodeid() {
      return this.$route.query.nodeid
    },
    new_saveormodify() {
      return this.$route.query.saveormodify
    }
  },
  // watch函数返回一个对象，这个对象的属性和方法会被绑定到模板上
  watch: {
    formautoid: {
      handler(n) {
        if (n) {
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    
  }
}
</script>

<style lang="less" scoped>
.workFlowContainer {
  .van-nav-bar {
    background: #2b8df0;
    z-index: 99;
    /deep/.van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .workFlowIndexBox {
    margin-top: 1.22667rem;
  }
}
</style>
