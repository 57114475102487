<template>
    <div 
        class="keep-flow_block" 
        
    >
        <!-- 基本信息 -->
        <div class="keep-flow_wrapTitBox">
            <span class="h3">审批记录</span>
            <div class="upDownBtn">
                <van-icon size="20" color="#999999" @click="showWrapFun" v-if="showWrapStatus" name="arrow-up" />
                <van-icon size="20" color="#999999" @click="showWrapFun" v-else name="arrow-down" />
            </div>
        </div>
        <div class="keep-flow_container" v-show="showWrapStatus" style="padding-top: 10px;">
            <div class="contentBox">
                <van-steps 
                    direction="vertical" 
                    :active="active" 
                    active-icon="passed" 
                    inactive-icon="more-o"
                >
                    <van-step v-for="(value,key) in tableData" :key="key">
                        <template #active-icon>
                            <div class="seupActiveIcon">
                                <div>{{ value.empname?value.empname.slice(0,1):'' }}</div>
                                <van-icon v-if="value.isreturn" color="red" class="isIcon" name="cross" />
                                <van-icon v-else-if="value.ischeck && !value.isreturn" color="green" class="isIcon" name="passed" />
                                <van-icon v-else-if="!value.ischeck && !value.isreturn" color="#ff8d2b" class="isIcon" name="more-o" />
                            </div>
                        </template>
                        <template #inactive-icon>
                            <div class="seupActiveIcon">
                                <div>{{ value.empname?value.empname.slice(0,1):'' }}</div>
                                <van-icon v-if="value.isreturn" color="red" class="isIcon" name="cross" />
                                <van-icon v-else-if="value.ischeck && !value.isreturn" color="green" class="isIcon" name="passed" />
                                <van-icon v-else-if="!value.ischeck && !value.isreturn" color="#ff8d2b" class="isIcon" name="more-o" />
                            </div>
                        </template>
                        <div class="seupActiveContent">
                            <div class="seupActiveContentTop">
                                <h3>{{ value.nodeautoid == 1?'发起':value.nodeautoid == 2?'完结':'审批人' }}<van-button v-if="value.ismc" @click="ckNodeHq(value)" style="margin-left: 5px;border-radius: 5px;" size="mini" type="info">查看</van-button></h3>
                                <span>{{ value.crdate }}</span>
                            </div>
                            <div class="seupActiveContentTop" style="margin-top: 2px;">
                                <h3 style="color: #999;font-size: 12px;">{{ value.empname }}（{{ value.ismc?'会签':value.staname }}）</h3>
                                <span>{{ value.checkdate }}</span>
                            </div>
                            <div class="seupActiveContentBody" style="margin-top: 2px;" v-if="value.nodeautoid != 1">
                                <div class="word">{{ value.checkstatus }}<span :class="value.isreturn?'ycxclass':value.ischeck?'yspclass':'spzclass'">（{{ value.isreturn?'已撤销':value.ischeck?'已审批':'审批中' }}）</span></div>
                            </div>
                            <div class="seupActiveContentBody" v-show="value.checkmemo" style="margin-top: 2px;" v-if="value.nodeautoid != 1">
                                <div class="word">审批意见：{{ value.checkmemo }}</div>
                            </div>
                        </div>
                    </van-step>
                </van-steps>
            </div>
            <div class="huise" style="width: 100%;height: 0.42667rem;background: #f6f6f6;"></div>
            <div 
                class="commentRecordContainer" 
                style="padding-bottom: 10px;margin: 0.42667rem;margin-top: 0;padding-top: 10px;"
            >
                <van-cell title="评论">
                    <template #right-icon>
                        <van-icon @click="addCommentFun" name="edit" />
                    </template>
                </van-cell>
                <div 
                    class="commentRecordList" 
                    v-for="(value,key) in commentTableData" 
                    :key="key"
                >   
                    <div class="commentRecordListTop">
                        <span style="color: #666;">{{ value.empname }}</span>
                        <span style="color: #999;">{{ value.crdate }}</span>
                    </div>
                    <div class="commentRecordListContent">
                        <span>{{ value.comment_msg }}</span>
                    </div>
                    <div class="commentRecordListImages">
                        <van-image
                            v-if="value.pic1_url"
                            width="100"
                            height="100"
                            lazy-load
                            :src="value.pic1_url"
                            fit="contain"
                            @click="onImagePreview(value.pic1_url)"
                        />
                        <van-image
                            v-if="value.pic2_url"
                            width="100"
                            height="100"
                            lazy-load
                            fit="contain"
                            :src="value.pic2_url"
                            @click="onImagePreview(value.pic2_url)"
                        />
                        <van-image
                            v-if="value.pic3_url"
                            width="100"
                            height="100"
                            lazy-load
                            fit="contain"
                            :src="value.pic3_url"
                            @click="onImagePreview(value.pic3_url)"
                        />
                        <van-image
                            v-if="value.pic4_url"
                            width="100"
                            height="100"
                            lazy-load
                            fit="contain"
                            :src="value.pic4_url"
                            @click="onImagePreview(value.pic4_url)"
                        />
                        <van-image
                            v-if="value.pic5_url"
                            width="100"
                            height="100"
                            lazy-load
                            fit="contain"
                            :src="value.pic5_url"
                            @click="onImagePreview(value.pic5_url)"
                        />
                        <van-image
                            v-if="value.pic6_url"
                            width="100"
                            height="100"
                            lazy-load
                            fit="contain"
                            :src="value.pic6_url"
                            @click="onImagePreview(value.pic6_url)"
                        />
                        <van-image
                            v-if="value.pic7_url"
                            width="100"
                            height="100"
                            lazy-load
                            fit="contain"
                            :src="value.pic7_url"
                            @click="onImagePreview(value.pic7_url)"
                        />
                        <van-image
                            v-if="value.pic8_url"
                            width="100"
                            height="100"
                            lazy-load
                            fit="contain"
                            :src="value.pic8_url"
                            @click="onImagePreview(value.pic8_url)"
                        />
                        <van-image
                            v-if="value.pic9_url"
                            width="100"
                            height="100"
                            lazy-load
                            fit="contain"
                            :src="value.pic9_url"
                            @click="onImagePreview(value.pic9_url)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <van-popup v-model="addCommentStatus" position="bottom" closeable close-icon-position="top-left">
            <div class="addCommentTitle" style="width: 100%;height: 53px;text-align: center;line-height: 53px;font-size: 18px;font-weight: bold;">添加评论</div>
            <div class="addCommentContent">
                <textarea v-model="textarea" placeholder="请输入评论（必填）" id="comment_msg"></textarea>
            </div>
            <div class="addCommentContent2">
                <!-- 推荐回复 -->
                <div style="color: #999;font-size: 15px;margin-top: 10px;">推荐回复</div>
                <div class="tjhflist">
                    <div class="tjhflistspan" @click="addWord('已阅')">已阅</div>
                    <div class="tjhflistspan" @click="addWord('收到')">收到</div>
                    <div class="tjhflistspan" @click="addWord('已核对')">已核对</div>
                    <div class="tjhflistspan" @click="addWord('合格')">合格</div>
                    <div class="tjhflistspan" @click="addWord('情况属实')">情况属实</div>
                    <div class="tjhflistspan" @click="addWord('确认')">确认</div>
                    <div class="tjhflistspan" @click="addWord('已复核')">已复核</div>
                    <div class="tjhflistspan" @click="addWord('知悉')">知悉</div>
                    <div class="tjhflistspan" @click="addWord('辛苦了')">辛苦了</div>
                    <div class="tjhflistspan" @click="addWord('已安全')">已安全</div>
                </div>
            </div>
            <div class="addCommentContent2">
                <div style="color: #999;font-size: 15px;margin-top: 10px;">附件上传</div>
                <div class="fjsclist" style="margin-top: 10px;">
                    <van-uploader v-model="fileList" multiple max-count="9" accept="image/png" :after-read="afterRead" />
                </div>
            </div>
            <div class="addCommentContent2" style="margin-bottom: 12px;">
                <van-button type="info" size="small" block style="border-radius: 5px;" @click="sendComment">发送</van-button>
            </div>
        </van-popup>
        <viewMc ref="refViewMc" :formConfig="formConfig"></viewMc>
    </div>
</template>
<script>
import { Toast,ImagePreview } from 'vant';

import viewMc from "./viewMc.vue";

import {
    getWorkFlowDetail,
    getFlowCommentRecord,
    postFlowComment
} from '@api/workFlow'

export default {
    components: {
        viewMc
    },
    props: {
        // 基本信息
        formConfig: {
            type: Object,
            default: () => { },
        }
    },
    data() {
        const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) 
        :this.$route.query.formoutSize=='1'?{userid:1,username:'admin'}// 外部人员打开单据时用到
        : {}
        return {
            userInfo,
            showWrapStatus: true,
            active: 999,
            tableData:[],
            commentTableData:[],
            addCommentStatus:false,
            fileList:[],
            textarea:""
        }
    },
    created () {
       this.getDate();
    },
    computed: {
        formname () {
            return this.$route.query.formname
        },
        formautoid () {
            return this.$route.query.formautoid
        },
        mautoid () {
            return this.$route.query.mautoid
        },
        nodeid () {
            return this.$route.query.nodeid
        },
        saveormodify () {
            return this.$route.query.saveormodify
        },
        // 0是没意义的，一般是新增
        // 1：待我审批
        // 2：我发起的
        // 3：我已审批
        // 4：被撤销的
        // 5：未提交的
        // 6：抄送
        documentType () {
            return this.$route.query.documentType || 0
        },
    },
    methods:{
        addWord (str) {
            this.textarea = this.textarea + str
        },
        onImagePreview (val) {
            ImagePreview({
                images:[val],
                className:"windowImagePreviewClassName"
            });
        },
        getDate () {
            if(this.saveormodify != 'save' && this.documentType != 5){
                getWorkFlowDetail({
                    formautoid: this.formautoid,
                    mautoid: this.mautoid
                }).then(res => {
                    this.tableData = res.data
                    if(this.tableData && this.tableData.length > 0){
                        this.$emit("showSpJl",true)
                        this.$emit("lastFlowDetailData",this.tableData[this.tableData.length-1])
                        this.getFlowCommentRecordData()
                    }
                })
            }
        },
        getFlowCommentRecordData() {
            
            getFlowCommentRecord({
                formautoid: this.formautoid,
                mautoid: this.mautoid,
                username: this.userInfo.username,
                nodeid: this.nodeid,
            }).then(res => {
                this.commentTableData = res.data;
                // console.log(this.formConfig,'use_flow_comment')
            })
            
        },
        addCommentFun () {
            this.fileList = [];
            this.textarea = "";
            this.addCommentStatus = true;
        },
        showWrapFun () {
            this.showWrapStatus = !this.showWrapStatus
        },
        afterRead(file) {
            
        },
        ckNodeHq (node) {
            this.$refs.refViewMc.getMcDetailFun(node);
        },
        sendComment () {
            if(!this.textarea){
                Toast("评论内容不能为空！")
                return;
            }
            const baseUrlArr = []
            if(this.fileList.length > 0){
                for(let i = 0; i < this.fileList.length; i++){
                    baseUrlArr.push({
                        pic:this.fileList[i].content
                    })
                }
            }
            postFlowComment({
                nodeid: Number(this.nodeid),
                formautoid: Number(this.formautoid),
                mautoid: this.mautoid,
                username: this.userInfo.username,
                comment_msg:this.textarea,
                data:baseUrlArr
            }).then(res=>{
                Toast('评论添加成功！');
                this.addCommentStatus = false;
                this.getFlowCommentRecordData();
            })
        },
    }
}
</script>
<style lang="less" scoped>
.keep-flow_block{
    width: 100%;
    margin-bottom: 25px;
    order: 5;
    .keep-flow_wrapTitBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.42667rem;
        height: 92px;
        
        background: #fff;
        .h3 {
            color: #333333;
            font-size: 32px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            margin: 0 !important;
            text-align: left;
            font-style: normal;
        } 
        .upDownBtn{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.keep-flow_container{
   
    width: 100%;
    background: #fff;

    .contentBox{
        padding: 0 0.42667rem;
        .seupActiveIcon{
            width: 70px;
            height: 70px;
            background: #2B8DF0;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            position: relative;
            div{
                color: #fff;
                text-align: center;
                font-size: 38px;
            }
            .isIcon{
                position: absolute;
                font-size: 24px;
                right: -0.1rem;
                bottom: -0.1rem;
                border-radius: 24px;
                background: #fff;
            }
        }
        .seupActiveContent{
            padding-left: 25px;
            h3{
                margin: 0;
            }
            .seupActiveContentTop{
                display: flex;
                justify-content: space-between;
                color: #333;
                align-items: center;
                h3{
                    font-size: 28px;
                    color: #111F2C;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                }
                span{
                    color: #A4A5A6;
                    font-size: 24px;
                }
                /deep/.van-button__text{
                    color: #fff !important;
                }
            }
            .seupActiveContentBody{
                .word{
                    font-size: 24px;
                    color: #333333;
                }
            }
        }
    }
}
.yspclass{
    color: green;
}
.spzclass{
    color: #bf550a;
}
.ycxclass{
    color: red;
}
.commentRecordContainer{
    /deep/.van-cell{
        padding: 0 !important;
        display: flex;
        align-items: center;
        .van-cell__title{
            span{
                font-weight: bold;
            }
        }
    }
    
}
.addCommentContent{
    padding: 0 0.29334rem;
    #comment_msg{
        width: 100%;
        border: none;
        height: 300px;
        padding: 0.13333rem;
        resize: none;
        font-size: 28px;
    }
    
}  
.addCommentContent2{
    padding: 0 0.42667rem;
    margin-top: 20px;
} 
.tjhflist{
    display: flex;
    
    flex-wrap: wrap;
    .tjhflistspan{
        padding: 6px 16px;
        background: #eee;
        color: #333;
        border-radius: 5px;
        margin-right: 22px;
        cursor: pointer;
        font-size: 32px;
        margin-top: 16px;
    }
}
.commentRecordList{
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 1px solid #eee;
    margin-top: 20px;
    .commentRecordListTop{
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 28px;
    }
    .commentRecordListContent{
        width: 100%;
        text-align: justify;
        line-height: 1;
        span{
            font-size: 28px;
        }
    }
    
}
.commentRecordListImages{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin-top: 15px;
    /deep/.van-image{
        margin-bottom: 15px;
        margin-right: 22px;
    }
}
</style>